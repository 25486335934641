import React, { useEffect, useState } from "react";
import {
  MdModeEditOutline,
  MdOutlineDeleteForever,
  MdSettingsSuggest,
} from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { VscOpenPreview } from "react-icons/vsc";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, Modal } from "antd";
import { Select } from "antd";
// import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Col, Row } from "react-bootstrap";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import {
  BsArrowDownCircle,
  BsFillEmojiWinkFill,
  BsStopwatch,
} from "react-icons/bs";
import { DatePicker } from "antd";
import Select1 from "react-select";
import Form from "react-bootstrap/Form";
import { Country, State, City } from "country-state-city";
import moment from "moment";
import Password from "antd/es/input/Password";
import { AiOutlineLock } from "react-icons/ai";
import { saveAs } from "file-saver";
function Profile() {
  const saveFile = (item, name) => {
    saveAs(`https://univ.mobi/user/${item}`, `${name}Resume.pdf`);
  };

  const [changepwd, setchnagepwd] = useState(true);

  const [endingDate, setEnding] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  // console.log("amit date =======>",endingDate?.$y,startDate?.$y
  const [profile, setprofile] = useState(true);
  const [job, setjob] = useState(false);
  const [savejob, setsavejob] = useState(false);
  let users = JSON.parse(sessionStorage.getItem("user"));
  if (!users) {
    alert("Please Login!");
    window.location.assign("/employee-login");
  }
  const [user, setUser] = useState({});
  // //console.log(users);
  const formdata = new FormData();
  const [backgroud, setBackground] = useState("");
  const [resume, setResumes] = useState("");
  const [profileImage, setProimage] = useState("");

  const [name, setname] = useState(users.name);
  const [Email, setEmail] = useState(users.email);
  const [mobile, setmobile] = useState(users.mobile);
  const [bio, setbio] = useState(users.bio);
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setCity] = useState("");
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState(users.pincode);
  const [int, setint] = useState("");
  const [int1, setint1] = useState("");
  const [int2, setint2] = useState("");
  const [int3, setint3] = useState("");
  const [educa, setEduca] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [skillset, setSkillset] = useState([]);
  const [editBtn, setEditbtn] = useState(false);
  const [show, setShow] = useState(false);
  const [joblist, setJobList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [iner1, setIner1] = useState("");
  const [iner2, setIner2] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [industry, setIndustry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(users.country);
  const [selectedState, setSelectedState] = useState(users.state);
  const [selectedCity, setSelectedCity] = useState(users.city);
  const [alldata, setAlldata] = useState([]);
  useEffect(() => {
    getAllcategory();
  }, [selectedCountry]);
  const getAllcategory = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/admin/getAllCategory");
      if (res.status === 200) {
        setAlldata(res.data.success);
      }
    } catch (error) {}
  };
  useEffect(() => {
    loadUsers();
    getAllcategory();
    getJob();
  }, []);

  const history = useNavigate();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Are you sure delete?
    </Tooltip>
  );
  const options = [];

  alldata.forEach(function(item) {
    options.push({
      label: item.category,
      value: item.category,
    });
  });
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setIndustry(value);
  };
  const loadUsers = async () => {
    const config = {
      url: "/user/getUserById/" + users._id,
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setUser(result.data.success);
        setEduca(result.data.success.education);
        setCompanyData(result.data.success.workAndExperience);
        setSkillset(result.data.success.skillSet);
        setIner1(result.data.success.interest.int);
        setIner2(result.data.success.interest.int1);
      } else {
        alert("Something went worng");
      }
    } catch (err) {}
  };
  let split1 = user.industry?.join(",");
  console.log(split1);
  const getJob = async () => {
    const config = {
      url: "/user/getAllJobs",
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setJobList(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {}
  };

  const [reasion1, setReasion1] = useState("");

  function onChangeValue(event) {
    setReasion1(event.target.value);
  }

  const deleteUser = async () => {
    const config = {
      url: "/user/deleteProfile",
      method: "post",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
      data: { userId: users._id, reasion: reasion1 },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert("Successfully deleted");
        sessionStorage.removeItem("user");
        history("/");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      alert("Something went worng");
      //console.log(err.message);
    }
  };

  const onSubmit = async () => {
    formdata.append("userId", users._id);
    formdata.append("backgroundImage", backgroud);
    formdata.append("profile", profileImage);

    try {
      const config = {
        url: "/editProfile",
        method: "put",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("successfully Updated");

        window.location.reload("");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  if (backgroud) {
    onSubmit();
  }
  if (profileImage) {
    onSubmit();
  }

  const editUser = async () => {
    let obj = {
      userId: users._id,
      name: name,
      mobile: mobile,
      email: Email,
      bio: bio,
      address: address,
      pincode: pincode,
      int: int,
      int1: int1,
      int2: int2,
      int3: int3,
      resume: resume,
      password: password,
      cpassword: cpassword,
      industry: industry,
    };
    if (country?.name !== "undefinde") {
      obj["country"] = country.name;
    }
    if (state?.name !== "undefinde") {
      obj["state"] = state.name;
    }
    if (city?.name !== "undefinde") {
      obj["city"] = city.name;
    }
    if (password) {
      try {
        const config = {
          url: "/editProfile",
          method: "put",
          baseURL: "https://univ.mobi/api/user",
          headers: { "content-type": "multipart/form-data" },
          data: obj,
        };
        let res = await axios(config);
        if (res.status === 200) {
          history("/employee-login");
          sessionStorage.removeItem("user");

          alert("Successfully changed password");
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    } else {
      try {
        const config = {
          url: "/editProfile",
          method: "put",
          baseURL: "https://univ.mobi/api/user",
          headers: { "content-type": "multipart/form-data" },
          data: obj,
        };
        let res = await axios(config);
        if (res.status === 200) {
          sessionStorage.setItem("user", JSON.stringify(user));
          alert("successfully Updated");

          window.location.reload(true);
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };
console.log(industry,"industry")
  const [institute, setInstitute] = useState("");
  const [course, setcourse] = useState("");
  const [branch, setbranch] = useState("");
  //console.log(user,"user");
  const addEducation = async () => {
    let obj = {
      userId: users._id,
      Institue: institute,
      Course: course,
      field: branch,
      starting: startDate?.$y,
      passOut: endingDate?.$y,
    };
    try {
      const config = {
        url: "/AddEducation",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
        loadUsers();

        alert("successfully added");
        setInstitute("");
        setcourse("");
        setbranch("");
        setStartDate(0);
        setEnding(0);
        // window.location.reload();
        setEditbtn(false);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [skil, setSkil] = useState("");
  const [skillex, setEskilex] = useState("");
  const addskill = async () => {
    let obj = {
      userId: users._id,
      skill: skil,
      Experience: skillex,
    };
    try {
      const config = {
        url: "/addsKill",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
        loadUsers();
        alert("successfully added");
        setEskilex("");
        setSkil("");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const applyJob = async (id) => {
    try {
      if (user?.resume === "") {
        alert("Please upload your resume");
      } else if (user.industry === "") {
        alert("Please update your profile to apply");
      } else if (user?.skillSet.length === 0 || user?.education.length === 0) {
        alert("Please update your profile to apply");
      } else {
        const config = {
          url: "/user/applyForJob",
          method: "post",
          baseURL: "https://univ.mobi/api",
          headers: { "content-type": "application/json" },
          data: { companyId: id, userId: user._id },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert(result.data.success);
        } else {
          alert("Something went worng");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [company, setCompany] = useState("");
  const [period, setPerioed] = useState("");
  const [workskill, setWorkskill] = useState("");
  const [workex, setWorkex] = useState("");

  const addWorkAndExperince = async () => {
    let obj = {
      userId: users._id,
      Company: company,
      Period: period,
      Skill: workskill,
      Experience: workex,
    };
    try {
      const config = {
        url: "/addWorkExperience",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
        loadUsers();
        alert("successfully added");
        setWorkskill("");
        setCompany("");
        setPerioed("");
        setWorkex("");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const removeED = async (id) => {
    const config = {
      url: `/user/removeEducation/${users._id}/${id}`,
      method: "delete",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        loadUsers();
        alert("Successfully deleted");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      alert("Something went worng");
      //console.log(err.message);
    }
  };

  const removeWDE = async (id) => {
    const config = {
      url: `/user/removeWorkExperience/${users._id}/${id}`,
      method: "delete",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        loadUsers();
        alert("Successfully deleted");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      alert("Something went worng");
      //console.log(err.message);
    }
  };

  const removeSkill = async (id) => {
    const config = {
      url: `/user/removeSkill/${users._id}/${id}`,
      method: "delete",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        loadUsers();
        alert("Successfully deleted");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      alert("Something went worng");
      //console.log(err.message);
    }
  };

  const [getAoolied, setApplied] = useState([]);

  const appliedJob = async () => {
    const config = {
      url: `/user/getlistOfaplly/${users._id}`,
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setApplied(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      alert("Something went worng");
      //console.log(err.message);
    }
  };
  console.log(getAoolied, "=======>getAoolied");
  const COURSEdATA = [
    { title: "B.tech" },
    { title: "BE" },
    { title: "B.Arch" },
    { title: "BCA" },
    { title: "B.Sc" },
    { title: "BBA" },
    { title: "BMS" },
    { title: "MBA" },
    { title: "PHD" },
    { title: "M.Tech" },
    { title: "ME" },
    { title: "MCA" },
    { title: "MBBS" },
    { title: "M.sc" },
    { title: "CA" },
    { title: "LLB" },
    {
      title: "BFA",
    },
    { title: "BEM" },
    { title: "BA + LL.B" },
    { title: "BJMC" },
    { title: "BFD" },
    { title: "BBS" },
    { title: "BTTM" },
    { title: "BPharma" },
    { title: "BDS" },
    { title: "B.Com" },
    { title: "ITI" },
    { title: "Polytechnic" },
    { title: "High School" },
    { title: "Intermediate" },
    { title: "Hotel Management" },
  ];

  const BranchdATA = [
    { title: "CSE" },
    { title: "ME" },
    { title: "Civil" },
    { title: "ETC" },
    { title: "EEE" },
    { title: "EE" },
    { title: "IT" },
    {
      title: "ADCA",
    },
    { title: "HR" },
    { title: "MM" },
    { title: "BA" },
    { title: "BM" },
    { title: "HHM" },
    { title: "HM" },
    { title: "OM" },
    { title: "LSCM" },
    { title: "RM" },
    { title: "EM" },
    { title: "Finace" },
    { title: "Biotechnology" },
    { title: "Aeronautical Engineering" },
    { title: "Automobile Engineering" },
  ];

  // ant
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (!reasion1) {
      alert("Please select reason why delete your account!");
    } else {
      setIsModalOpen(false);
      deleteUser();
    }
  };
  const handlekkk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="profile">
        <section class="section-box-2">
          <div class="container">
            {/* <div class="banner-hero banner-image-single">
              {user.backgroundImage ? (
                <>
                  {" "}
                  <img
                    src={`https://univ.mobi/user/${user?.backgroundImage}`}
                    alt="jobbox"
                    style={{ width: "100%", height: "400px" }}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <img
                    src={"/Images/img.png"}
                    alt="jobbox"
                    style={{ width: "100%" }}
                  />
                </>
              )}
              <a class="btn-editor" href="#" htmlFor="upload1">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    type="file"
                    name="file"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setBackground(e.target.files[0]);
                    }}
                  />
                  <PhotoCamera />
                </IconButton>
              </a>
            </div> */}
            <div class="box-company-profile">
              <div class="image-compay">
                {user.profile ? (
                  <>
                    {" "}
                    <img
                      src={`https://univ.mobi/user/${user?.profile}`}
                      alt="jobbox"
                      style={{
                        width: "89%",
                        borderRadius: "50%",
                        height: "81px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src="/Images/profileIcon.png"
                      alt="jobbox"
                      style={{
                        width: "89%",
                        borderRadius: "50%",
                        height: "81px",
                      }}
                    />
                  </>
                )}

                <a className="photo-camera-0">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    htmlFor="upload2"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      name="file"
                      id="upload2"
                      onChange={(e) => {
                        setProimage(e.target.files[0]);
                      }}
                    />
                    <PhotoCamera style={{ color: "white" }} />
                  </IconButton>
                </a>
              </div>
              <div class="row mt-10">
                <div class="col-lg-8 col-md-12">
                  <h5 class="f-18">
                    {user?.name}{" "}
                    <span class="card-location font-regular ml-20">
                      {user?.city} {user?.country}
                    </span>
                  </h5>
                  <p>
                    {user?.interest?.int ? (
                      <span
                        style={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          padding: "2px ",
                        }}
                      >
                        {user?.interest?.int}
                      </span>
                    ) : (
                      <></>
                    )}{" "}
                    {user?.interest?.int1 ? (
                      <span
                        style={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          padding: "2px ",
                        }}
                      >
                        {user?.interest.int1}
                      </span>
                    ) : (
                      <></>
                    )}{" "}
                    {user?.interest?.int2 ? (
                      <span
                        style={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          padding: "2px ",
                        }}
                      >
                        {user?.interest.int2}
                      </span>
                    ) : (
                      <></>
                    )}{" "}
                    {user?.interest?.int3 ? (
                      <span
                        style={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          padding: "2px ",
                        }}
                      >
                        {user?.interest.int3}
                      </span>
                    ) : (
                      <></>
                    )}{" "}
                    {/* {user.interest.int} {user.interest.int2}
                    {"  "}
                    {user.interest.int3} */}
                  </p>
                </div>
                {/* <div class="col-lg-4 col-md-12 text-lg-end">
                  <a
                    class="btn btn-preview-icon btn-apply btn-apply-big"
                    href="#"
                  >
                    Preview
                  </a>
                </div> */}
              </div>
            </div>
            <div class="border-bottom--0 pt-10 pb-10"></div>
          </div>
        </section>

        {/* /////////////////// */}

        <div className="section-box mt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div class="box-nav-tabs nav-tavs-profile mb-5">
                  <ul role="tablist" style={{ listStyle: "none" }}>
                    <li
                      onClick={() => {
                        setprofile(true);
                        setjob(false);
                        setsavejob(false);
                      }}
                    >
                      <a
                        class="btn btn-border aboutus-icon mb-20 "
                        style={{ border: profile ? "1px solid black" : "none" }}
                      >
                        <span className="px-2">
                          <FaUser />
                        </span>{" "}
                        My Profile
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setprofile(false);
                        setjob(true);
                        setsavejob(false);
                      }}
                    >
                      <a
                        class="btn btn-border recruitment-icon mb-20"
                        style={{ border: job ? "1px solid black" : "none" }}
                      >
                        <span className="px-2">
                          <MdSettingsSuggest />
                        </span>{" "}
                        Suggested Jobs &nbsp; {joblist
                            ?.filter(
                              (ele) =>
                                user?.industry[0] === ele?.category ||
                                user?.industry[1] === ele?.category ||
                                user?.industry[2] === ele?.category ||
                                user?.industry[3] === ele?.category ||
                                user?.industry[4] === ele?.category
                            )
                            .filter((data) => data.isBlock === false).length}
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setprofile(false);
                        setjob(false);
                        setsavejob(true);
                      }}
                    >
                      <a
                        class="btn btn-border people-icon mb-20"
                        style={{ border: savejob ? "1px solid black" : "none" }}
                        onClick={appliedJob}
                      >
                        <span className="px-2">
                          <VscOpenPreview />
                        </span>{" "}
                        Applied Jobs &nbsp; {getAoolied
                                ?.filter(
                                  (data) => data.companyId?.isBlock === false
                                ).length}
                      </a>
                    </li>
                  </ul>
                  <div class="border-bottom pt-10 pb-10"></div>
                  <div
                    class="mod_hero mt-20 mb-20"
                    style={{ marginTop: "20px", textAlign: "center" }}
                  >
                    {/* <a
                      class="link-red"
                      href="#"
                      onClick={() => {
                        // deleteUser();
                      }}
                    >
                      Delete Account
                    </a> */}
                    <Button type="primary" onClick={showModal}>
                      Delete
                    </Button>
                    <Modal
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handlekkk}
                    >
                      <h6>Select reason why your deleting ?</h6>
                      <div className="checkbox" onChange={onChangeValue}>
                        <input
                          type="radio"
                          value="I got jobs"
                          name="reasion1"
                          checked={reasion1 === "I got jobs"}
                        />
                        <label className="px-2">I got jobs</label>
                        <br />
                        <input
                          type="radio"
                          value="Not getting any response from HR"
                          name="reasion1"
                          checked={
                            reasion1 === "Not getting any response from HR"
                          }
                        />
                        <label className="px-2">
                          Not getting any response from HR
                        </label>
                        <br />

                        <input
                          type="radio"
                          value="No found dream job"
                          name="reasion1"
                          checked={reasion1 === "No found dream job"}
                        />
                        <label className="px-2">No found dream job </label>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-12">
                {profile ? (
                  <>
                    {editBtn ? (
                      <>
                        <div className="content-single">
                          <div className="tab-content">
                            <div className="tab-pane fade show active">
                              <h3>My Account</h3>
                              <button
                                type="button"
                                class="btn btn-dark"
                                style={{
                                  float: "right",
                                  margin: "10px",
                                }}
                                onClick={() => {
                                  setEditbtn(!editBtn);
                                }}
                              >
                                Edit
                              </button>
                              <a>
                                <strong>Update your profile</strong>
                              </a>

                              <div className="row form-contact">
                                <div className="col-lg-6 col-md-12">
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Full Name *
                                    </label>
                                    <input
                                      class="form-control"
                                      type="text"
                                      name="name"
                                      placeholder={user.name}
                                      value={name}
                                      onChange={(e) => {
                                        setname(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Email *
                                    </label>
                                    <input
                                      class="form-control"
                                      type="text"
                                      name="email"
                                      placeholder={user.email}
                                      value={Email}
                                      onChange={(e) => {
                                        setEmail(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Contact Number *
                                    </label>
                                    <input
                                      class="form-control"
                                      type="text"
                                      name="mobile"
                                      placeholder={user.mobile}
                                      value={mobile}
                                      onChange={(e) => {
                                        setmobile(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Category *
                                    </label>
                                    {/* <Form.Select
                                      aria-label="Default select example"
                                      onChange={(e) => {
                                        setIndustry(e.target.value);
                                      }}
                                    >
                                      <option>{user.industry
}</option>
                                      {alldata?.map((items) => {
                                        return (
                                          <option value={items?.category}>
                                            {items?.category}
                                          </option>
                                        );
                                      })}
                                    </Form.Select> */}
                                    <Select
                                      mode="multiple"
                                      // className="form-control"
                                      allowClear
                                      style={{
                                        width: "100%",
                                      }}
                                      placeholder="Please select category"
                                      onChange={handleChange}
                                      options={options}
                                    />
                                    {/* <input
                                      class="form-control"
                                      type="text"
                                      name="mobile"
                                      placeholder={user.industry}
                                      value={industry}
                                      onChange={(e) => {
                                        setIndustry(e.target.value);
                                      }}
                                    /> */}
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Career Objective *
                                    </label>
                                    <textarea
                                      class="form-control"
                                      placeholder={
                                        user?.bio
                                          ? user.bio
                                          : "Looking for a challenging role in an organisation, which provides opportunities to enhance my skills and expand my knowledge for the growth of the company."
                                      }
                                      value={bio}
                                      onChange={(e) => {
                                        setbio(e.target.value);
                                      }}
                                      rows="4"
                                    ></textarea>
                                  </div>
                                  {/* <label class="font-sm color-text-mutted mb-10">
                                    Interested Job Position :-
                                  </label>
                                  <div
                                    class="row"
                                    style={{ marginLeft: "3px" }}
                                  >
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Int1
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          placeholder={
                                            user.interest
                                              ? user.interest.int
                                              : "Please enter your int1"
                                          }
                                          value={int}
                                          onChange={(e) => {
                                            setint(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Int2
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          placeholder={
                                            user.interest
                                              ? user.interest.int1
                                              : "Please enter your int2"
                                          }
                                          value={int1}
                                          onChange={(e) => {
                                            setint1(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Int3
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          placeholder={
                                            user.interest
                                              ? user.interest.int2
                                              : "Please enter your int3"
                                          }
                                          value={int2}
                                          onChange={(e) => {
                                            setint2(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Int4
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          placeholder={
                                            user.interest
                                              ? user.interest.int3
                                              : "Please enter your int4"
                                          }
                                          value={int3}
                                          onChange={(e) => {
                                            setint3(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                  <label class="font-sm color-text-mutted mb-10">
                                    Address * :-
                                  </label>
                                  <textarea
                                    class="form-control"
                                    style={{
                                      height: "41px",
                                      marginBottom: "20px",
                                    }}
                                    type="text"
                                    placeholder={
                                      user.address
                                        ? user.address
                                        : "Please enter your address"
                                    }
                                    value={address}
                                    onChange={(e) => {
                                      setaddress(e.target.value);
                                    }}
                                  />
                                  <div
                                    class="row"
                                    style={{ marginLeft: "3px" }}
                                  >
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Country *
                                        </label>

                                        <Select1
                                          options={Country.getAllCountries()}
                                          getOptionLabel={(options) => {
                                            return options["name"];
                                          }}
                                          getOptionValue={(options) => {
                                            return options["name"];
                                          }}
                                          value={selectedCountry}
                                          onChange={(item) => {
                                            setSelectedCountry(item);
                                            setcountry(item);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          State *
                                        </label>
                                        <Select1
                                          options={State?.getStatesOfCountry(
                                            selectedCountry?.isoCode
                                          )}
                                          getOptionLabel={(options) => {
                                            return options["name"];
                                          }}
                                          getOptionValue={(options) => {
                                            return options["name"];
                                          }}
                                          value={selectedState}
                                          onChange={(item) => {
                                            setSelectedState(item);
                                            setstate(item);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          City *
                                        </label>
                                        <Select1
                                          options={City.getCitiesOfState(
                                            selectedState?.countryCode,
                                            selectedState?.isoCode
                                          )}
                                          getOptionLabel={(options) => {
                                            return options["name"];
                                          }}
                                          getOptionValue={(options) => {
                                            return options["name"];
                                          }}
                                          value={selectedCity}
                                          onChange={(item) => {
                                            setSelectedCity(item);
                                            setCity(item);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Zip code *
                                        </label>
                                        <input
                                          class="form-control"
                                          style={{ height: "41px" }}
                                          type="text"
                                          placeholder={user.pincode}
                                          value={pincode}
                                          onChange={(e) => {
                                            setpincode(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label
                                      class="font-sm color-text-mutted mb-10"
                                      htmlFor="upload3"
                                    >
                                      Upload Resume *
                                    </label>
                                    <input
                                      class="form-control"
                                      type="file"
                                      name="file"
                                      id="upload3"
                                      accept="application/pdf,application/vnd.ms-excel"
                                      onChange={(e) => {
                                        setResumes(e.target.files[0]);
                                      }}
                                    />
                                  </div>
                                  <div class="form-group">
                                    <h6
                                      class="color-orange mb-20"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setchnagepwd(!changepwd);
                                      }}
                                    >
                                      Change your password{" "}
                                      <span>
                                        <AiOutlineLock />
                                      </span>
                                    </h6>
                                    {changepwd ? (
                                      <></>
                                    ) : (
                                      <>
                                        <div class="row">
                                          <div class="col-lg-6">
                                            <div class="form-group">
                                              <label class="font-sm color-text-mutted mb-10">
                                                Password
                                              </label>
                                              <input
                                                class="form-control"
                                                type="password"
                                                value={password}
                                                onChange={(e) =>
                                                  setpassword(e.target.value)
                                                }
                                                placeholder="Enter your new password"
                                              />
                                            </div>
                                          </div>
                                          <div class="col-lg-6">
                                            <div class="form-group">
                                              <label class="font-sm color-text-mutted mb-10">
                                                Confirm Password *
                                              </label>
                                              <input
                                                class="form-control"
                                                type="confirm password"
                                                value={cpassword}
                                                onChange={(e) =>
                                                  setCpassword(e.target.value)
                                                }
                                                placeholder="Enter confirm password"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div
                                    class="box-button mt-15"
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <button
                                      class="btn-apply-big font-md font-bold"
                                      onClick={() => editUser()}
                                    >
                                      Save
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-dark"
                                      style={{
                                        float: "right",
                                        margin: "10px",
                                      }}
                                      onClick={() => {
                                        setEditbtn(!editBtn);
                                      }}
                                    >
                                      Back
                                    </button>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div
                                    class="border-bottom  mb-30"
                                    style={{
                                      borderBottom: "none",
                                      marginTop: "0px",
                                    }}
                                  >
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Educations :-
                                      </label>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Institute Name *
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="name"
                                          placeholder={
                                            "Please enter institute name"
                                          }
                                          value={institute}
                                          onChange={(e) => {
                                            setInstitute(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div className="row">
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Course *
                                            </label>
                                            {/* <Stack>
                                              <Autocomplete
                                                freeSolo
                                                id="free-solo-2-demo"
                                                disableClearable
                                                onChange={(event, value) =>
                                                  setcourse(value)
                                                }
                                                options={COURSEdATA.map(
                                                  (option) => option.title
                                                )}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Course"
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      type: "search",
                                                    }}
                                                  />
                                                )}
                                              />
                                            </Stack> */}
                                            <input
                                              class="form-control"
                                              type="text"
                                              name="name"
                                              placeholder={"Course"}
                                              value={course}
                                              onChange={(e) => {
                                                setcourse(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Branch *
                                            </label>
                                            {/* <Stack>
                                              <Autocomplete
                                                freeSolo
                                                id="free-solo-2-demo"
                                                disableClearable
                                                options={BranchdATA.map(
                                                  (option) => option.title
                                                )}
                                                onChange={(event, value) =>
                                                  setbranch(value)
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Branch"
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      type: "search",
                                                    }}
                                                  />
                                                )}
                                              />
                                            </Stack> */}
                                            <input
                                              class="form-control"
                                              type="text"
                                              name="name"
                                              placeholder={"Branch"}
                                              value={branch}
                                              onChange={(e) => {
                                                setbranch(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Starting *
                                            </label>
                                            <br />
                                            <DatePicker
                                              style={{
                                                width: "100%",
                                                border: "2px solid #e0e6f6",
                                                borderRadius: "4px",
                                                fontSize: "17px",
                                                lineHeight: "22px",
                                                padding: "12px",
                                              }}
                                              selected={startDate}
                                              onChange={(date) =>
                                                setStartDate(date)
                                              }
                                              picker="year"
                                            />
                                            {/* <DatePicker  */}
                                            {/* <ReactDatePicker
                                              
                                              showYearPicker
                                              dateFormat="yyyy"
                                              yearItemNumber={9}
                                              class="form-control"
                                            /> */}
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Ending *
                                            </label>
                                            <br />
                                            <DatePicker
                                              style={{
                                                width: "100%",
                                                border: "2px solid #e0e6f6",
                                                borderRadius: "4px",
                                                fontSize: "17px",
                                                lineHeight: "22px",
                                                padding: "12px",
                                              }}
                                              selected={endingDate}
                                              onChange={(date) =>
                                                setEnding(date)
                                              }
                                              picker="year"
                                            />

                                            <button
                                              type="button"
                                              class="btn btn-success"
                                              style={{
                                                float: "right",
                                                "margin-top": "10px",
                                                height: "30px",
                                                "text-align": "center",
                                                "padding-block": "unset",
                                              }}
                                              onClick={() => {
                                                addEducation();
                                              }}
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                        <span
                                          class="card-info font-sm color-text-paragraph-2"
                                          style={{ marginTop: "-30px" }}
                                        >
                                          You can add more education.
                                        </span>
                                      </div>
                                    </div>
                                    {educa.map((data) => {
                                      return (
                                        <div className="row">
                                          <p>
                                            <span className="amit-0">
                                              Institue*&nbsp;:{" "}
                                            </span>
                                            <span>{data.Institue}</span>
                                          </p>
                                          <p>
                                            <span className="amit-0">
                                              Course*:{" "}
                                            </span>
                                            <span>
                                              {data.Course} ({data.field})
                                            </span>
                                          </p>
                                          <p>
                                            <span className="amit-0">
                                              Duration*:{" "}
                                            </span>
                                            <span>
                                              {data.starting}-{data.passOut}
                                            </span>
                                            <span
                                              className="rahul"
                                              onClick={() => {
                                                removeED(data._id);
                                              }}
                                            >
                                              <OverlayTrigger
                                                placement="right"
                                                delay={{
                                                  show: 250,
                                                  hide: 400,
                                                }}
                                                overlay={renderTooltip}
                                              >
                                                <div>
                                                  <MdOutlineDeleteForever />
                                                </div>
                                              </OverlayTrigger>
                                            </span>
                                          </p>
                                        </div>
                                      );
                                    })}
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Experience :-
                                      </label>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Company Name *
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="name"
                                          placeholder={
                                            "Please enter company name"
                                          }
                                          value={company}
                                          onChange={(e) => {
                                            setCompany(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Designation *
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="name"
                                          placeholder={
                                            "Please enter your designation"
                                          }
                                          value={workskill}
                                          onChange={(e) => {
                                            setWorkskill(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div class="row">
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Duration *
                                            </label>
                                            <input
                                              class="form-control"
                                              type="text"
                                              placeholder="2018-2022"
                                              value={period}
                                              onChange={(e) => {
                                                setPerioed(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Total work experience *
                                            </label>
                                            <input
                                              class="form-control"
                                              type="number"
                                              placeholder="Enter experience in company"
                                              value={workex}
                                              onChange={(e) => {
                                                setWorkex(e.target.value);
                                              }}
                                            />
                                            <button
                                              type="button"
                                              class="btn btn-success"
                                              style={{
                                                float: "right",
                                                "margin-top": "10px",
                                                height: "30px",
                                                "text-align": "center",
                                                "padding-block": "unset",
                                              }}
                                              onClick={() => {
                                                addWorkAndExperince();
                                              }}
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                        <span
                                          class="card-info font-sm color-text-paragraph-2"
                                          style={{ marginTop: "-30px" }}
                                        >
                                          You can add all your experience.
                                        </span>
                                      </div>
                                    </div>
                                    {companyData.map((data) => {
                                      return (
                                        <div className="row">
                                          <p>
                                            <span className="amit-0">
                                              Company Name*&nbsp;:{" "}
                                            </span>
                                            <span>{data.Company}</span>
                                          </p>
                                          <p>
                                            <span className="amit-0">
                                              Designation *:{" "}
                                            </span>
                                            <span>{data.Skill}</span>
                                          </p>
                                          <p>
                                            <span className="amit-0">
                                              Duration*:{" "}
                                            </span>
                                            <span>{data.Period}</span>
                                            <span
                                              className="rahul"
                                              onClick={() => {
                                                removeWDE(data._id);
                                              }}
                                            >
                                              <OverlayTrigger
                                                placement="right"
                                                delay={{
                                                  show: 250,
                                                  hide: 400,
                                                }}
                                                overlay={renderTooltip}
                                              >
                                                <div>
                                                  <MdOutlineDeleteForever />
                                                </div>
                                              </OverlayTrigger>
                                            </span>

                                            <span
                                              style={{
                                                float: "right",
                                                marginRight: "17px ",
                                              }}
                                            >
                                              {" "}
                                              <span className="amit-0">
                                                Experience*:{" "}
                                              </span>
                                              {data.Experience} Year
                                            </span>
                                          </p>
                                        </div>
                                      );
                                    })}
                                    <div class="box-skills">
                                      <h5>Skill / Technical skill</h5>

                                      <div className="row">
                                        <div className="">
                                          <div
                                            class="form-contact"
                                            style={{
                                              display: "flex",
                                              gap: "20px",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div class="form-group">
                                              {/* <label class="font-sm color-text-mutted mb-10">
                                                Skill*
                                              </label> */}
                                              <input
                                                class="form-control"
                                                type="text"
                                                placeholder="Enter skill one by one "
                                                value={skil}
                                                onChange={(e) => {
                                                  setSkil(e.target.value);
                                                }}
                                              />
                                            </div>
                                            <button
                                              type="button"
                                              class="btn btn-success"
                                              // className="col-md-6"
                                              style={{
                                                // float: "right",
                                                "margin-top": "-7px",
                                                height: "30px",
                                                "text-align": "center",
                                                "padding-block": "unset",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              onClick={() => {
                                                addskill();
                                              }}
                                            >
                                              Add
                                            </button>
                                          </div>
                                          <div class="box-tags mt-30">
                                            {skillset.map((data) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div className="vishawjit">
                                                    <a class="btn btn-grey-small mr-10">
                                                      {data.skill}
                                                      <span class="close-icon"></span>
                                                    </a>
                                                  </div>
                                                  {/* <div className="vishawjit">
                                                {data.Experience} year
                                              </div> */}
                                                  <span
                                                    className="rahul"
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                    onClick={() => {
                                                      removeSkill(data._id);
                                                    }}
                                                  >
                                                    <OverlayTrigger
                                                      placement="right"
                                                      delay={{
                                                        show: 250,
                                                        hide: 400,
                                                      }}
                                                      overlay={renderTooltip}
                                                    >
                                                      <div>
                                                        <MdOutlineDeleteForever />
                                                      </div>
                                                    </OverlayTrigger>
                                                  </span>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>

                                        {/* <div className="col-md-6">
                                          <div class="form-contact">
                                            <div class="form-group">
                                              <label class="font-sm color-text-mutted mb-10">
                                                Experience *
                                              </label>
                                              <input
                                                class="form-control"
                                                type="number"
                                                placeholder="Enter experience in company"
                                                value={skillex}
                                                onChange={(e) => {
                                                  setEskilex(e.target.value);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>

                                      <div class="mt-40">
                                        {" "}
                                        <span class="card-info font-sm color-text-paragraph-2">
                                          You can add up to 15 skills
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="content-single">
                          <div className="tab-content">
                            <div className="tab-pane fade show active">
                              <h3>My Account</h3>
                              <button
                                type="button"
                                class="btn btn-dark"
                                style={{
                                  float: "right",
                                  margin: "10px",
                                }}
                                onClick={() => {
                                  setEditbtn(!editBtn);
                                }}
                              >
                                Edit
                              </button>
                              <a
                              // class="font-md color-text-paragraph-2"
                              // href="#"
                              >
                                <strong>Your profile</strong>
                              </a>
                              {/* <div class="mt-35 mb-40 box-info-profie">
                            <div class="image-profile">
                              <img
                                src="/Images/candidate-profile.png"
                                alt="jobbox"
                              />
                            </div>
                            <a>
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                              >
                                <input hidden accept="image/*" type="file" />
                                <PhotoCamera />
                              </IconButton>
                            </a>
                            <a class="btn btn-link">Delete</a>
                          </div> */}

                              <div className="row form-contact">
                                <div className="col-lg-6 col-md-12">
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Full Name *
                                    </label>
                                    <br />
                                    <span>{user.name}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Email *
                                    </label>
                                    <br />
                                    <span>{user.email}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Contact Number *
                                    </label>
                                    <br />
                                    <span>{user.mobile}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Category *
                                    </label>
                                    <br />
                                    <span>{split1}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Career Objective *
                                    </label>
                                    <br />
                                    <span>{user.bio}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Address *
                                    </label>
                                    <br />
                                    <span>{user?.address}</span>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Country *
                                        </label>
                                        <br />
                                        <span>{user.country}</span>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          State *
                                        </label>
                                        <br />
                                        <span>{user.state}</span>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          City *
                                        </label>
                                        <br />
                                        <span>{user.city}</span>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Zip code *
                                        </label>
                                        <br />
                                        <span>{user.pincode}</span>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Resume *
                                      </label>
                                      {user?.resume ? (
                                        <spam
                                          style={{
                                            fontSize: "20px",
                                            color: "rgb(5, 38, 78)",
                                          }}
                                        >
                                          {" "}
                                          <BsArrowDownCircle
                                            onClick={() =>
                                              saveFile(user?.resume, user?.name)
                                            }
                                          >
                                            download
                                          </BsArrowDownCircle>
                                        </spam>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div
                                    class="border-bottom  mb-30"
                                    style={{
                                      borderBottom: "none",
                                      marginTop: "0px",
                                    }}
                                  >
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Educations :-
                                      </label>
                                      <br />
                                      {educa.map((data) => {
                                        return (
                                          <div className="row">
                                            <p>
                                              <span className="amit-0">
                                                Institue*&nbsp;:{" "}
                                              </span>
                                              <span>{data.Institue}</span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                                Course*:{" "}
                                              </span>
                                              <span>
                                                {data.Course} ({data.field})
                                              </span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                                Duration*:{" "}
                                              </span>
                                              <span>
                                                {data.starting}-{data.passOut}
                                              </span>
                                              {/* <span
                                                className="rahul"
                                                onClick={() => {
                                                  removeED(data._id);
                                                }}
                                              >
                                                <OverlayTrigger
                                                  placement="right"
                                                  delay={{
                                                    show: 250,
                                                    hide: 400,
                                                  }}
                                                  overlay={renderTooltip}
                                                >
                                                  <div>
                                                    <MdOutlineDeleteForever />
                                                  </div>
                                                </OverlayTrigger>
                                              </span> */}
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Experience :-
                                      </label>
                                      {companyData.map((data) => {
                                        return (
                                          <div className="row">
                                            <p>
                                              <span className="amit-0">
                                                Company Name*&nbsp;:{" "}
                                              </span>
                                              <span>{data.Company}</span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                                Designation *:{" "}
                                              </span>
                                              <span>{data.Skill}</span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                                Duration*:{" "}
                                              </span>
                                              <span>{data.Period}</span>
                                              {/* <span
                                                className="rahul"
                                                onClick={() => {
                                                  removeWDE(data._id);
                                                }}
                                              >
                                                <OverlayTrigger
                                                  placement="right"
                                                  delay={{
                                                    show: 250,
                                                    hide: 400,
                                                  }}
                                                  overlay={renderTooltip}
                                                >
                                                  <div>
                                                    <MdOutlineDeleteForever />
                                                  </div>
                                                </OverlayTrigger>
                                              </span> */}
                                              <span
                                                style={{
                                                  float: "right",
                                                  marginRight: "17px ",
                                                }}
                                              >
                                                {data.Experience} Year
                                              </span>
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <div class="box-skills">
                                      <h5>Skills</h5>
                                      <div class="form-contact">
                                        <div class="form-group"></div>
                                      </div>
                                      <div class="box-tags mt-30">
                                        <Row>
                                          {skillset.map((data) => {
                                            return (
                                              <Col
                                                md=""
                                                style={{ padding: "2px" }}
                                              >
                                                <a class="btn btn-grey-small mr-10">
                                                  {" "}
                                                  {data.skill}
                                                </a>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </div>
                                      {/* <div class="mt-40">
                                        {" "}
                                        <span class="card-info font-sm color-text-paragraph-2">
                                          You can add up to 15 skills
                                        </span>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>

                                {/* <h6 class="color-orange mb-20">
                              Change your password
                            </h6>
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <label class="font-sm color-text-mutted mb-10">
                                    Password
                                  </label>
                                  <input
                                    class="form-control"
                                    type="password"
                                    value={users.password}
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <label class="font-sm color-text-mutted mb-10">
                                    Confirm Password *
                                  </label>
                                  <input
                                    class="form-control"
                                    type="confirm password"
                                    value={users.password}
                                  />
                                </div>
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {job ? (
                      <>
                        <div className="tab-jobs">
                          <h3 class="mt-0 color-brand-1 mb-50">My Jobs</h3>
                        </div>

                        <div className="row">
                          {joblist
                            ?.filter(
                              (ele) =>
                                user?.industry[0] === ele?.category ||
                                user?.industry[1] === ele?.category ||
                                user?.industry[2] === ele?.category ||
                                user?.industry[3] === ele?.category ||
                                user?.industry[4] === ele?.category
                            )
                            .filter((data) => data.isBlock === false)
                            ?.map((items) => {
                              return (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                                  <a href={`/job-profile-details/${items._id}`}>
                                    <div class="card-grid-2 hover-up">
                                      <div class="card-grid-2-image-left">
                                        <div class="right-info">
                                          <a class="name-job" href="#">
                                            {items?.companyName.slice(0, 17)}
                                          </a>
                                          <span class="location-small">
                                            {" "}
                                            <span
                                              style={{ paddingRight: "8px" }}
                                            >
                                              <CiLocationOn />
                                            </span>
                                            {items?.location}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="card-block-info">
                                        <h6>
                                          <a
                                            href={`/job-profile-details/${items?._id}`}
                                          >
                                            {items?.jobProfile.slice(0, 18)}
                                          </a>
                                        </h6>
                                        <div class="mt-3">
                                          <span class="card-briefcase">
                                            <span
                                              style={{ paddingRight: "4px" }}
                                            >
                                              <HiOutlineBriefcase />
                                            </span>
                                            {items?.typeofjob}
                                          </span>
                                          <span class="card-time">
                                            {" "}
                                            <span
                                              style={{ paddingRight: "4px" }}
                                            >
                                              <BsStopwatch />
                                            </span>
                                            {moment(items?.updatedAt)
                                              .startOf("hour")
                                              .fromNow()}
                                            {/* <span> minutes ago</span> */}
                                          </span>
                                          <p>
                                            <HiOutlineBriefcase />{" "}
                                            {items.experiencerequired ===
                                              "Fresher" ||
                                            items.experiencerequired ===
                                              "Any" ? (
                                              <span>
                                                {items.experiencerequired}
                                              </span>
                                            ) : (
                                              <span>
                                                {items?.experience
                                                  ? items?.experience
                                                  : items.experiencerequired}
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        {/* <p class="font-sm color-text-paragraph mt-3 mb-3">
                                          {parse(
                                            items.description.slice(0, 20)
                                          )}
                                        </p> */}
                                        {/* <div class="mt-30 mb-3">
                                        
                                          <a
                                            class="btn btn-grey-small mr-5"
                                            href={`/job-profile-details/${items._id}`}
                                          >
                                            {items.skill.slice(0, 40)}
                                          </a>
                                        </div> */}
                                        <div class="card-2-bottom mt-30">
                                          <div class="row">
                                            <div class="col-lg-6 col-6">
                                              <span class="card-text-price">
                                                {"₹ "}
                                                {items?.minSalary
                                                  ? items?.minSalary
                                                  : 0}
                                                -
                                                {items?.maxSalary
                                                  ? items?.maxSalary
                                                  : 0}{" "}
                                                {/* {items?.averageIncentive ? "+" : ""}{" "}
                                          {items?.averageIncentive}{" "} */}
                                                {items.period ? (
                                                  <span class="text-muted">
                                                    /{items.period}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                                {/*
                     <span class="text-muted">{items.salarytype}</span>) */}
                                              </span>
                                            </div>
                                            <div class="col-lg-5 col-5 text-end">
                                              <div
                                                class="btn btn-apply-now"
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModalApplyJobForm"
                                                onClick={() => {
                                                  applyJob(items?._id);
                                                }}
                                              >
                                                Apply now
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <>
                        {savejob ? (
                          <>
                            <div className="tab-jobs">
                              <h3
                                class="mt-0 color-brand-1 mb-50"
                                style={{ fontWeight: "bold" }}
                              >
                                Applied Jobs
                              </h3>
                            </div>
                            <div className="row">
                              {getAoolied
                                ?.filter(
                                  (data) => data.companyId?.isBlock === false
                                )
                                .map((items) => {
                                  return (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                                      <a
                                        href={`/Ajob-profile-details/${items?.companyId?._id}`}
                                      >
                                        <div class="card-grid-2 hover-up">
                                          <div class="card-grid-2-image-left">
                                            <div class="right-info">
                                              <a class="name-job" href="#">
                                                {items?.companyId?.companyName?.slice(
                                                  0,
                                                  17
                                                )}
                                              </a>
                                              <span class="location-small">
                                                {" "}
                                                <span
                                                  style={{
                                                    paddingRight: "4px",
                                                  }}
                                                >
                                                  <CiLocationOn />
                                                </span>
                                                {items?.companyId?.location}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="card-block-info">
                                            <h6>
                                              <a href="#">
                                                {items?.companyId?.jobProfile}
                                              </a>
                                            </h6>
                                            <div class="mt-3">
                                              <span class="card-briefcase">
                                                <span
                                                  style={{
                                                    paddingRight: "4px",
                                                  }}
                                                >
                                                  <HiOutlineBriefcase />
                                                </span>
                                                {items?.companyId?.typeofjob}
                                              </span>
                                              <span class="card-time">
                                                {" "}
                                                <span
                                                  style={{
                                                    paddingRight: "4px",
                                                  }}
                                                >
                                                  <BsStopwatch />
                                                </span>
                                                {/* {items?.companyId?.updatedAt} */}
                                                {moment(
                                                  items?.companyId?.updatedAt
                                                )
                                                  .startOf("hour")
                                                  .fromNow()}
                                                {/* <span> minutes ago</span> */}
                                              </span>
                                              <p>
                                                <HiOutlineBriefcase />{" "}
                                                {items.companyId
                                                  ?.experiencerequired ===
                                                  "Fresher" ||
                                                items.companyId
                                                  ?.experiencerequired ===
                                                  "Any" ? (
                                                  <span>
                                                    {
                                                      items.companyId
                                                        ?.experiencerequired
                                                    }
                                                  </span>
                                                ) : (
                                                  <span>
                                                    {items.companyId?.experience
                                                      ? items.companyId
                                                          ?.experience
                                                      : items.companyId
                                                          ?.experiencerequired}
                                                  </span>
                                                )}
                                              </p>
                                            </div>
                                            {/* <p class="font-sm color-text-paragraph mt-3 mb-3">
                                              {parse(
                                                items?.companyId?.description?.slice(
                                                  0,
                                                  20
                                                )
                                              )}
                                            </p> */}
                                            {/* <div class="mt-30 mb-3">
                                              
                                              <a
                                                class="btn btn-grey-small mr-5"
                                                href="#"
                                              >
                                                {items?.companyId?.skill.slice(
                                                  0,
                                                  40
                                                )}
                                              </a>
                                            </div> */}
                                            <div class="card-2-bottom mt-30">
                                              <div class="row">
                                                <div class="col-lg-9 col-9">
                                                  <span class="card-text-price">
                                                    {"₹ "}
                                                    {items?.companyId?.minSalary
                                                      ? items?.companyId
                                                          ?.minSalary
                                                      : 0}
                                                    -
                                                    {items?.companyId?.maxSalary
                                                      ? items?.companyId
                                                          ?.maxSalary
                                                      : 0}{" "}
                                                    {items?.companyId
                                                      ?.period ? (
                                                      <span class="text-muted">
                                                        /
                                                        {
                                                          items?.companyId
                                                            ?.period
                                                        }
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {/*
                     <span class="text-muted">{items.salarytype}</span>) */}
                                                  </span>
                                                </div>
                                                <div class="col-lg-4 col-4 text-end">
                                                  <a>
                                                    <div
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#ModalApplyJobForm"
                                                    >
                                                      {items?.status}
                                                    </div>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
