import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Mp.css";
import Wcs from "./Wcs";

// back-e
import axios from "axios";
import { useState, useEffect } from "react";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { waitForElementToBeDeleted } from "@testing-library/react";
import { RxCross2 } from "react-icons/rx";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import moment from "moment";
import exportFromJSON from "export-from-json";
function Intrested() {

  const saveFile = (item, name) => {
    saveAs(`https://univ.mobi/user/${item}`, `${name}Resume.pdf`);
  };
  const [users, setusers] = useState([]);

  const employer = JSON.parse(sessionStorage.getItem("employer"));
  
  const getJobList = async () => {
    if (!employer) {
      alert("Please login");
    }
    let res = await axios.get(
      "https://univ.mobi/api/user/getInterestedUser/" + employer._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setusers(res.data.success);
    }
  };
  const deleteUser = async (id) => {
    let res = await axios.delete(
      "https://univ.mobi/api/user/deleteIntrestById/" + id
    );
    if (res.status === 200) {
      alert(res.data.success);
      getJobList();
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  //console.log("users===>", users);

  const data=[]
  if(users?.length!==0){
    for(let i=0;i<users.length;i++){

      data.push({EmployeeName:users[i]?.userId?.name,EmployeeNumber:users[i]?.userId?.mobile,EmployeeEmail:users[i]?.userId?.email,JobLocking:users[i]?.userId?.interest?.int,IntrestedOn:moment(users[i]?.updatedAt).fromNow()})
      
    }
  }

  const exportType = "xls";
  const [fileName, setfileName] = useState("IntreatedDetails");

  const ExportToExcel = () => {
        exportFromJSON({ data, fileName, exportType });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              borderRadius: "10px",
              marginBottom: "10px",
              boxShadow:" rgb(0 0 0 / 16%) 0px 1px 4px",
              border:" 1px solid rgb(36 73 106 / 21%)",
              width: "100%",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Interested Employees</b>
                </h1>
                <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button>
              </div>
            </div>
            <br />
            <div className="tble">
              <table style={{ width: "100%", marginBottom: "20px" ,zIndex:"auto"}}>
                <tr>
                  {/* <th>Logo</th> */}
                  {/* <th>Company Name</th> */}
                  <th>Employee Name</th>
                  <th>Contact</th>
                  <th>Email ID</th>
                  <th>Job Looking</th>
                  <th>Resume</th>
                  <th>Intrested on</th>
                  <th>Delete</th>
                </tr>
                {users?.map((items) => {
                  return (
                    <tr>
                     
                      <td>
                      {items?.userId?.profile ? (
                        <img
                          src={`https://univ.mobi/user/${items?.userId?.profile}`}
                          width="30"
                          height="30"
                          className="rounded-circle"
                          alt="React Bootstrap logo"
                          style={{ marginRight: "10px" }}
                        />
                      ) : (
                        <BiUserCircle
                          style={{
                            margin: "0px 10px",
                            fontSize: "22px",
                            color: "#24496a",
                          }}
                        />
                      )}
                        <Link to={`/user-profile-view/${items?.userId?._id}`}>{items?.userId?.name}</Link>
                      </td>
                      <td><Link to={`/user-profile-view/${items?.userId?._id}`}>{items?.userId?.mobile}</Link></td>

                      <td><Link to={`/user-profile-view/${items?.userId?._id}`}>{items?.userId?.email}</Link></td>

                      <td><Link to={`/user-profile-view/${items?.userId?._id}`}>{items?.userId?.interest?.int ? (items?.userId?.interest?.int):(<RxCross2 style={{color:"red",fontSize:"20px"}}></RxCross2>)}</Link></td>
                      <td>
                        {items?.userId?.resume ? (<a  onClick={()=>saveFile(items?.userId?.resume,items?.userId?.name)}>
                          {" "}
                          <img
                            src="./pngwing.com.png"
                            width="30"
                            height="30"
                            className="s"
                            style={{ marginRight: "8px" ,cursor:"pointer"}}
                            alt="React Bootstrap logo"
                          />
                        </a>):(<RxCross2 style={{color:"red",fontSize:"20px"}}></RxCross2>)}
                        
                      </td>
                      <td> {moment(items?.createdAt).fromNow()}</td>
                      <td>
                        {" "}
                        <Button variant="danger" style={{ color: "white" }} onClick={()=>deleteUser(items?._id)}>
                          Delete
                        </Button>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intrested;
