import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import exportFromJSON from "export-from-json";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { AiOutlineLock } from "react-icons/ai";

function Password() {
    let check = JSON.parse(sessionStorage.getItem("admin"));
    const history = useNavigate();
    const [password, setpassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const changepwd = async () => {
        let obj = {  userId:check._id,  password: password,
          cpassword: cpassword,
        };  try {
            const config = {
              url: "/changepassword",
              method: "put",
              baseURL: "https://univ.mobi/api/admin",
              headers: { "content-type": "application/json" },
              data: obj,
            };
            let res = await axios(config);
            if (res.status === 200) {
              history("/admindashboard");
              sessionStorage.removeItem("admin");
    
              alert("Successfully changed your password");
            } else {
              alert("Something Wrong");
            }
          } catch (error) {
            if (error.response) {
              alert(error.response.data.error);
            }
          }
        
        }
        // }
    
  return (
    <>
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}
            <div
              className="container"
              style={{
                border: "1px solid rgba(36, 73, 106, 0.21)",
                borderRadius: " 10px",
                marginBottom: "10px",
                boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                marginBottom: "10px",
              }}
            >
              <div class="container">
                <div class="loginform">
                  <h1
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                      textAlign: "center",
                    }}
                  >
                    <b>Change Password</b>
                  </h1>
                </div>
              </div>
              <h6
                                        class="color-orange mb-20"
                                        style={{ cursor: "pointer" }}
                                        
                                      >
                                        Change your password{" "}
                                        <span>
                                          <AiOutlineLock />
                                        </span>
                                      </h6>
                                    
                                          <div class="row">
                                            <div class="col-lg-6">
                                              <div class="form-group">
                                                <label class="font-sm color-text-mutted mb-10">
                                                  Password
                                                </label>
                                                <input
                                                  class="form-control"
                                                  type="password"
                                                  value={password}
                                                  onChange={(e) =>
                                                    setpassword(e.target.value)
                                                  }
                                                  placeholder="Enter your new password"
                                                />
                                              </div>
                                            </div>
                                            <div class="col-lg-6">
                                              <div class="form-group">
                                                <label class="font-sm color-text-mutted mb-10">
                                                  Confirm Password *
                                                </label>
                                                <input
                                                  class="form-control"
                                                  type="confirm password"
                                                  value={cpassword}
                                                  onChange={(e) =>
                                                    setCpassword(e.target.value)
                                                  }
                                                  placeholder="Enter confirm password"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                        class="box-button mt-15"
                                        style={{
                                          marginTop: "20px",
                                          marginBottom: "20px",
                                          // textAlign:"center"
                                          // marginLeft:"20px"
                                        }}
                                      >
                                        <button
                                          class="btn-apply-big font-md font-bold"
                                          onClick={() => changepwd()}
                                        >
                                          Save
                                        </button>
                                      </div>
            </div>
          
                                    
          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
