import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Wcs from "./Wcs";
import "./Mp.css";
// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

function Show1() {
  const [companyid, setCompanyid] = useState({});
  const [userid, setUserid] = useState("");
  const [status, setStatus] = useState("");

  const formdata = new FormData();
  formdata.set("companyId", companyid);
  formdata.set("userId", userid);
  formdata.set("status", status);

  const Verified = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/registerCompany",
        method: "post",
        baseURL: "https://univ.mobi/api/admin",
        headers: { "content-type": "multipart/data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };

  return (
    <>
      {" "}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
         

          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              marginTop: "30px",
              borderRadius: "10px",
            
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Details</b>
                </h1>
              </div>
            </div>
            <br />
            <div className="tble">
            <div className="row">
              <div className="col-md-6">
              <h4>Company Name: <span> Ibrar</span></h4><br/>
            <h4>CEO: <span> Ibrar</span></h4><br/>
            <h4>Job Profile: <span> it</span></h4><br/>
            <h4>Job Status: <span> maneger</span></h4><br/>
            <h4>Email: <span> Ibrar@123</span></h4><br/>
            <h4>Skill: <span> Node</span></h4><br/>
            <h4>Qulification: <span> B.E</span></h4><br/>
            <h4>Experience: <span> 3 years</span></h4><br/>
            <h4>Location: <span> electronic</span></h4><br/>
            <h4>City: <span> bengaluru</span></h4><br/>
              </div>
              <div className="col-md-6">
              <h4>Contact: <span> 987563417</span></h4><br/>
            <h4>Profile Logo: <span> pic</span></h4><br/>
            <h4>Category: <span> 1b</span></h4><br/>
            <h4>Subcategory: <span> 2b</span></h4><br/>
            <h4>maxSalary: <span> 50000</span></h4><br/>
            <h4>minSalary: <span> 7000000</span></h4><br/>
            <h4>Salary Type: <span> 20000</span></h4><br/>
            <h4>webSiteLink: <span> Ibrariuhwi8yxdihfu</span></h4>
              </div>
            </div>
            
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Show1;
