import React from "react";
import Wcs from "./WcsS";
import Button from "react-bootstrap/Button";
import ProfileView from "./ProfileViewS";
// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

function ProfileDetails1() {
  const [register, setRegister] = useState([]);

  const getProfileList = async (item) => {
    let res = await axios.put("https://univ.mobi/api/admin/editAdmin");
    if (res.status === 200) {
      //console.log(res.data);
      setRegister(res.data.success);
    }
  };
  useEffect(() => {
    getProfileList();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border:" 1px solid rgba(36, 73, 106, 0.21)",
              borderRadius: "10px",
              marginBottom: "10px",
              boxShadow:" rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Profile Details</b>
                </h1>
                {/* <p align="right">
                  <Button
                    type="button"
                    href="/View"
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                  >
                    +Edit
                  </Button>
                </p> */}
              </div>
            </div>
            {/*  */}
            <div className="row">
              <div className="col-md-4">
                <ProfileView />
              </div>
              <div className="col-md-2">
                <div>
                  <div class="profileLable" id="profileDetailsWrap">
                    <ul
                      style={{
                        textAlign: "left",
                        marginTop: "50px",
                        color: "black",
                      }}
                    >
                      <h5>Name:</h5>
                      <br />
                      <h5>Username:</h5>
                      <br />
                      <h5>Gender:</h5>
                      <br />
                      <h5>Email:</h5>
                      <br />
                      <h5>Mobile:</h5>
                      <br />
                      <h5>Password:</h5>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div class="profileDetailBox">
                {register.map((item) => (
                  <ul
                    style={{
                      fontSize: "12px",
                      marginTop: "50px",
                      color: "black",
                    }}
                  >
                    <h5>Bishwajit Adityadev &nbsp;{item?.name}</h5>
                    <br />
                    <h5>Bishwajit_Adityadev123 &nbsp;{item?.userName}</h5>
                    <br />
                    <h5>
                      <span id="currentEmail">Mail{item?.email}</span>
                    </h5>
                    <br />
                    <h5>
                      <span id="currentEmail">bishuadityadev33@gmail.com{item?.email}</span>
                    </h5>
                    <br />
                    <h5>
                      <span
                        id="currentAlternateEmail"
                        style={{ display: "none" }}
                      ></span>
                      <span
                        class="verifiedCNo"
                        id="alternateEmailVerify"
                        style={{ display: "none" }}
                      >
                        Verified
                      </span>
                    </h5>
                    <h5>
                      <span id="currentMobile">7541086135{item?.mobile}</span>
                      {/* <span class="verifiedCNo">Verified</span>
                      <a href="" class="link-click link-click__update">
                        Change Mobile
                      </a> */}
                    </h5>
                    <br />
                    <h5>
                      Not shown due to security reasons
                      <Link to="/changepassword">
                        <a
                          class="link-click link-click__update"
                          style={{ color: "red", marginLeft: "8px" }}
                        >
                          Change Password
                        </a>
                      </Link>
                    </h5>
                    <br />
                    <h5 id="deactivationLink">
                      <a
                        href="javascript:userprofilePopup('deactivate-Account')"
                        class="link-click link-click__deactivate"
                      >
                        Deactivate account
                      </a>
                    </h5>
                  </ul>
                  ))}
                </div>
              </div>
              {/* <div className="col-md-2" style={{ marginTop: "70px", textAlign:"right" }}>
                <h5>Name:</h5>
                <h5>Company Name:</h5>
                <h5>Email:</h5>
                <h5>Contact:</h5>
                <h4>Password:</h4>
              </div>
              <div className="col-md-2" style={{ marginTop: "70px", }}>
                <h5>Bishwajit Adityadev &nbsp;</h5>
                <h5>infosy</h5>
                <h5>bishuadityadev33@gmail.com</h5>
                <h5>9863564321</h5>
                <h4>Not shown due to security reasons <a href="">Change Password</a></h4>
              </div> */}
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileDetails1;
