import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import "./MajorProduct.scoped.scss";
import Wcs from "./Wcs1";
// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Table } from "react-bootstrap";
// import { Modal } from 'antd';
import Modal from "react-bootstrap/Modal";
import { AiOutlineDelete } from "react-icons/ai";
import { RiDeleteBin2Fill } from "react-icons/ri";

function Notification() {

  const [alldata1, setAlldata1] = useState([]);

  const getAllcategory1 = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/admin/getnotification");
      if (res.status === 200) {
        setAlldata1(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
  
    getAllcategory1();
  }, []);


  const [link, setlink] = useState("");

  const [text1, settext1] = useState("");
 

  const addhome1 = async (e) => {
    e.preventDefault();
    if (!text1) {
      alert("Enter the text");
    } else {
      try {
        const config = {
          url: "/addnotification",
          method: "post",
          baseURL: "https://univ.mobi/api/admin",
          headers: { "content-type": "application/json" },
          data: { text: text1 },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert("success");
            settext1("");
            setIsModalOpen1(false);
            getAllcategory1();
          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
    }
  };
 
  const deletetext = async (data) => {
    try {
      axios
        .delete(`https://univ.mobi/api/admin/deletenotification/` + data._id)
        .then(function(res) {
          if (res.status === 200) {
            //console.log(res.data);
            alert("Notification Deleted");
            getAllcategory1();
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };
  return (
    <>
      {" "}
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            <div className="container img">
              <div
                class="container"
                style={{
                  border: "1px solid rgba(36, 73, 106, 0.21)",
                  borderRadius: " 10px",
                  marginBottom: "10px",
                  boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                  width: "100%",
                }}
              >
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Notification</b>
                  </h1>
                  <br />
                </div>
                <div className="">
                  <p align="right">
                   
                    <Button
                      variant="primary"
                      type="button"
                      value="ShortList"
                      style={{
                        backgroundColor: "rgb(5, 38, 78)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      <b style={{ color: "white" }} onClick={showModal1}>
                        Add Notification
                      </b>
                    </Button>
                  </p>
                </div>

               

                <div className="slide_hero mb-3">
                  {alldata1?.map((data, index) => {
                    return (
                      <div class="item">
                        <div className="esw">
                          {index + 1}){data?.text}
                        </div>
                        <RiDeleteBin2Fill
                          style={{
                            marginTop: "-2%",
                            // marginLeft: "0%",
                            color: "red",
                            fontSize: "20px",
                          }}
                          onClick={() => deletetext(data)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Modal show={isModalOpen1} onHide={handleCancel1}>
        <Modal.Header closeButton style={{ backgroundColor: "#165c49" }}>
          <Modal.Title style={{ color: "white" }}>Add Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#165c49" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Enter notification"
              value={text1}
              onChange={(e) => settext1(e.target.value)}
            />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#165c49" }}>
          <Button variant="secondary" onClick={handleCancel1}>
            Close
          </Button>
          <Button variant="primary" onClick={addhome1}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Notification;
