// -----------------------------------
import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./Mp.css";
import Wcs from "./WcsS";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import parse from "html-react-parser";
import {
  FaBuilding,
  FaPhoneAlt,
  FaMailBulk,
  FaBookReader,
  FaCity,
  FaLocationArrow,
  FaMoneyBillAlt,
  FaMoneyCheckAlt,
  FaMoneyBillWaveAlt,
  FaGlobeAsia,
  FaSuitcase,
} from "react-icons/fa";
import {
  BsFillPersonCheckFill,
  BsFillPersonPlusFill,
  BsLink45Deg,
  BsPinMapFill,
  BsCalendarDate,
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsLinkedin,
  BsFillChatSquareTextFill,
} from "react-icons/bs";
import { FcBusinessman } from "react-icons/fc";
import { TbBrandJavascript } from "react-icons/tb";
import { GiSkills, GiMatterStates, GiStreetLight } from "react-icons/gi";
import { BiCategoryAlt } from "react-icons/bi";

// back-e
import axios from "axios";
import { useState, useEffect } from "react";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { waitForElementToBeRemoved } from "@testing-library/react";
import { Link, useParams } from "react-router-dom";

function CompanyD1() {
  const [companyDetails, setCompanyDetails] = useState([]);

  const getVerifiedList = async () => {
    let res = await axios.get("https://univ.mobi/api/user/getAllJobs");
    if (res.status === 200) {
      //console.log(res.data);
      setCompanyDetails(res.data.success);
    }
  };

  useEffect(() => {
    getVerifiedList();
  },[]);

  return (
    <>
      {/* img */}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border:" 1px solid rgba(36, 73, 106, 0.21)",
              borderRadius: "10px",
              marginBottom: "10px",
              boxShadow:" rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Job Details</b>
                </h1>
              </div>
              <p align="right">
                <Link to={`/companyDetailsEdit/:id`}>
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                  >
                    +Edit
                  </Button>
                </Link>
              </p>
            </div>
            <div
              className="table"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              <table striped>
                <thead>
                  <tr>
                    <th>Logo</th>
                    <th>Name</th>
                    <th> :</th>
                    <th>Details</th>
                    {/* <th>Contact</th> */}
                    {/* <th>Verify</th>
                  <th>Remove</th> */}
                  </tr>
                </thead>
                {/* {UnvarifiedList.map((item) => (
                  <tr>
                    <td>
                      {" "}
                      <img
                        src={`https://univ.mobi/company/${item.profileLogo}`}
                        width="30"
                        height="30"
                        className="rounded-circle"
                        alt="React Bootstrap logo"
                      />
                    </td>
                    <td>
                      <a href="" name="name" id="name">
                        {item.companyName}
                      
                    </td>
                    <td>
                     {item.jobProfile}
                    </td>
                    <td>
                     {item.contact}
                    </td>
                    
                      {" "}
                      <Button
                        variant="danger"
                        style={{ color: "white" }}
                        onClick={() => removeJob(item?._id)}
                      >
                        Remove
                      </Button>{" "}
                  </tr>
                ))} */}
                {companyDetails.map((item) => {
                  return (
                    <div className="details">
                      <tbody>
                        <tr>
                          <td>
                            <FaBuilding />
                          </td>
                          <td>Company Name</td>
                          <td>:</td>
                          <td>{item?.companyName}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaPhoneAlt />
                          </td>
                          <td>Contact :</td>
                          <td>:</td>
                          <td>{item?.contact}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsFillPersonCheckFill />
                          </td>
                          <td>CEO Name</td>
                          <td>:</td>
                          <td>{item?.CEO}</td>
                        </tr>
                        <tr>
                          <td>
                            <TbBrandJavascript />
                          </td>
                          <td>Job Profile</td>
                          <td>:</td>
                          <td>{item?.jobProfile}</td>
                        </tr>
                        <tr>
                    {/* <td>2</td> */}
                    <td>
                      <FaSuitcase />
                    </td>
                    <td>Category</td>
                    <td>:</td>
                    <td>{item?.category}</td>
                  </tr>
                        <tr>
                          <td>
                            <FcBusinessman />
                          </td>
                          <td>Job Status</td>
                          <td>:</td>
                          <td>{item?.jobType}</td>
                        </tr>

                        <tr>
                          <td>
                            <FaMailBulk />
                          </td>
                          <td>Email</td>
                          <td>:</td>
                          <td>{item?.email}</td>
                        </tr>
                        <tr>
                          <td>
                            <GiSkills />
                          </td>
                          <td>Skill</td>
                          <td>:</td>
                          <td>{item?.skill}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaBookReader />
                          </td>
                          <td>Qulification</td>
                          <td>:</td>
                          <td>{item?.qulification}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaBookReader />
                          </td>
                          <td>Exprience</td>
                          <td>:</td>
                          <td>{item?.experience ? item.experience :"Freshers"}</td>
                        </tr>

                        <tr>
                          <td>
                            <FaBookReader />
                          </td>
                          <td>Position</td>
                          <td>:</td>
                          <td>{item?.position}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaLocationArrow />
                          </td>
                          <td>Location</td>
                          <td>:</td>
                          <td>{item?.location}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaGlobeAsia />
                          </td>
                          <td>Country</td>
                          <td>:</td>
                          <td>{item?.contrary}</td>
                        </tr>
                        <tr>
                          <td>
                            <GiMatterStates />
                          </td>
                          <td>State</td>
                          <td>:</td>
                          <td>{item?.state}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaCity />
                          </td>
                          <td>City</td>
                          <td>:</td>
                          <td>{item?.city}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>Street</td>
                          <td>:</td>
                          <td>{item?.street}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsPinMapFill />
                          </td>
                          <td>Pincode</td>
                          <td>:</td>
                          <td>{item?.pincode}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsFillPersonPlusFill />
                          </td>
                          <td>profileLogo</td>
                          <td>:</td>
                          <td>
                            <img
                              src={`https://univ.mobi/company/${item?.profileLogo}`}
                              width="30"
                              height="30"
                              className="square"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <BiCategoryAlt />
                          </td>
                          <td>Category</td>
                          <td>:</td>
                          <td>{item?.category}</td>
                        </tr>
                       
                        <tr>
                          <td>
                            <FaMoneyBillWaveAlt />
                          </td>
                          <td>Minimum Salary Lpa</td>
                          <td>:</td>
                          <td>{item?.minSalary}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaMoneyCheckAlt />
                          </td>
                          <td>Maximum Salary  Lpa</td>
                          <td>:</td>
                          <td>{item?.maxSalary}</td>
                        </tr>
                        <tr>
                          <td>
                            <FaMoneyBillWaveAlt />
                          </td>
                          <td>Salary Type</td>
                          <td>:</td>
                          <td>{item?.salaryType}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsLink45Deg />
                          </td>
                          <td>WebSiteLink</td>
                          <td>:</td>
                          <td>{item?.webSiteLink}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsCalendarDate />
                          </td>
                          <td>Deadline</td>
                          <td>:</td>
                          <td>{item?.Deadline}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsFacebook />
                          </td>
                          <td>Facebook</td>
                          <td>:</td>
                          <td>{item?.facebook}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsInstagram />
                          </td>
                          <td>Instagram</td>
                          <td>:</td>
                          <td>{item?.instragram}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsTwitter />
                          </td>
                          <td>Twitter</td>
                          <td>:</td>
                          <td>{item?.twiter}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsLinkedin />
                          </td>
                          <td>Linkedin</td>
                          <td>:</td>
                          <td>{item?.linkedin}</td>
                        </tr>
                        <tr>
                          <td>
                            <BsFillChatSquareTextFill />
                          </td>
                          <td>Description</td>
                          <td>:</td>
                          <td>{item?.description ? parse(item?.description) : "" }</td>
                        </tr>
                      </tbody>
                    </div>
                  );
                })}
              </table>
            </div>
            {/* table */}
            {/* <Button
          variant="primary"
          type="submit"
          style={{ margin: "20px 470px" }}
        >
          submit
        </Button> */}
            {/* img */}
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyD1;
