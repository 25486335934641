import React from "react";
import Wcs from "./Wcs";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import {
    FaBuilding,
    FaPhoneAlt,
    FaMailBulk,
    FaBookReader,
    FaCity,
    FaLocationArrow,
    FaMoneyBillAlt,
    FaMoneyCheckAlt,
    FaMoneyBillWaveAlt,
    FaGlobeAsia,
  } from "react-icons/fa";
  import {
    BsFillPersonCheckFill,
    BsFillPersonPlusFill,
    BsLink45Deg,
    BsPinMapFill,
    BsCalendarDate,
    BsFacebook,
    BsInstagram,
    BsTwitter,
    BsLinkedin,
    BsFillChatSquareTextFill,
  } from "react-icons/bs";
  import { FcBusinessman } from "react-icons/fc";
  import { TbBrandJavascript } from "react-icons/tb";
  import { GiSkills, GiMatterStates, GiStreetLight } from "react-icons/gi";
  import { BiCategoryAlt } from "react-icons/bi";

function EmpUserDetails() {
    const [item, setCompanyDetails] = useState({});
    const { id } = useParams();
  
    const getVerifiedList = async () => {
      let res = await axios.get(
        "https://univ.mobi/api/admin/getJobById/" + id
      );
      if (res.status === 200) {
        //console.log(res.data);
        setCompanyDetails(res.data.success);
      }
    };
  


  
    useEffect(() => {
      getVerifiedList();
    }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          <Wcs />

          <div
            className="container img"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              marginTop: "30px",
              borderRadius: "10px",
              height: "500px",
              overflow: "scroll",
              width: "100%",
            }}
          >
          <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Employee Details</b>
                </h1>
              </div>
              {/* <p align="right">
                <Link to={`/companyDetailsEdit/${id}`}>
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                  >
                    +Edit
                  </Button>
                </Link>
              </p> */}
            </div>
            <div className="t">
              <Table striped bordered hover style={{ width: "100%",zIndex:"auto" }}>
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Logo</th>
                    <th>Name</th>
                    <th></th>
                    <th>Details</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {/* <td>1</td> */}

                    <td>
                      <BsFillPersonCheckFill />
                    </td>
                    <td> Name</td>
                    <td>:</td>
                    <td>{item?.name}</td>
                  </tr>
                  <tr>
                    {/* <td>2</td> */}
                    <td>
                      <FaPhoneAlt />
                    </td>
                    <td>Mobile </td>
                    <td>:</td>
                    <td>{item?.mobile}</td>
                  </tr>
                  <tr>
                    {/* <td>3</td> */}
                    <td>
                      <BsFillPersonCheckFill />
                    </td>
                    <td>User Name</td>
                    <td>:</td>
                    <td>{item?.userName}</td>
                  </tr>
                  <tr>
                    <td>
                    <tr>
                    <td>
                      <BsFillPersonPlusFill />
                    </td>
                    <td>User Id</td>
                    <td>:</td>
                    <td>{item?.profileViews?.userId}</td>
                  </tr>
                      <FaMailBulk />
                    </td>
                    <td>email</td>
                    <td>:</td>
                    <td>{item?.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiSkills />
                    </td>
                    <td>Password</td>
                    <td>:</td>
                    <td>{item?.password}</td>
                  </tr>
                  <tr>
                    <td>
                      <FcBusinessman />
                    </td>
                    <td>Age</td>
                    <td>:</td>
                    <td>{item?.age}</td>
                  </tr>

                  <tr>
                    <td>
                      <BsFillPersonPlusFill />
                    </td>
                    <td>Profile</td>
                    <td>:</td>
                    <td>{item?.profile}</td>
                  </tr>
                
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Gender</td>
                    <td>:</td>
                    <td>{item?.gender}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Institue</td>
                    <td>:</td>
                    <td>{item?.education?.Institue}</td>
                  </tr>

                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Course</td>
                    <td>:</td>
                    <td>{item?.education?.Course}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaLocationArrow />
                    </td>
                    <td>Location</td>
                    <td>:</td>
                    <td>{item?.education?.Location}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaGlobeAsia />
                    </td>
                    <td>Starting</td>
                    <td>:</td>
                    <td>{item?.education?.starting}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiMatterStates />
                    </td>
                    <td>PassOut</td>
                    <td>:</td>
                    <td>{item?.education?.passOut}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiSkills />
                    </td>
                    <td>SkillSet</td>
                    <td>:</td>
                    <td>{item?.skillSet}</td>
                  </tr>
                  <tr>
                    <td><FaBuilding/></td>
                    <td>Company</td>
                    <td>:</td>
                    <td>{item?.workAndExperience?.Company}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsPinMapFill />
                    </td>
                    <td>Period</td>
                    <td>:</td>
                    <td>{item?.workAndExperience?.Period}</td>
                  </tr>
                
                  <tr>
                    <td>
                      <GiSkills />
                    </td>
                    <td>Skill</td>
                    <td>:</td>
                    <td>{item?.workAndExperience?.Skill}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Experience</td>
                    <td>:</td>
                    <td>{item?.workAndExperience?.Experience}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiStreetLight />
                    </td>
                    <td>Street</td>
                    <td>:</td>
                    <td>{item?.street}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaCity />
                    </td>
                    <td>City</td>
                    <td>:</td>
                    <td>{item?.city}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiMatterStates />
                    </td>
                    <td>State</td>
                    <td>:</td>
                    <td>{item?.state}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsPinMapFill />
                    </td>
                    <td>Pincode</td>
                    <td>:</td>
                    <td>{item?.pincode}</td>
                  </tr>
                 
                 
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmpUserDetails;
