import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Mp.css";

import { Link } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";

// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import exportFromJSON from "export-from-json";
import { Modal } from "react-bootstrap";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { waitForElementToBeRemoved } from "@testing-library/react";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { MdDelete } from "react-icons/md";

function User1() {

  const [UnvarifiedList, setUnvarifiedList] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  

  const [singleselected, setsingleselect] = useState([]);
  // const formdata = new FormData();
  // formdata.set("companyName", companyname);
  // formdata.set("jobProfile", jobprofile);
  // formdata.set("contact", contact);
  const getJobList = async () => {
    let res = await axios.get("https://univ.mobi/api/user/getAllProfile");
    if (res.status === 200) {
      //console.log(res.data.success);
      setUnvarifiedList(res.data.success);
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 7;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(UnvarifiedList.filter((ele)=>ele?.isDelete===false).length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  const data=[]
  if(UnvarifiedList?.length >0){
    for(let i=0;i<UnvarifiedList?.length;i++){
      if(UnvarifiedList[i]?.isDelete===false){
      data?.push({EmployeesName:UnvarifiedList[i]?.name,Email:UnvarifiedList[i]?.email,PhoneNumber:UnvarifiedList[i]?.mobile,EducationInstitue:UnvarifiedList[i]?.education[0]?.Institue , Course:UnvarifiedList[i]?.education[0]?.Course ,Field: UnvarifiedList[i]?.education[0]?.field 
        ,Location:UnvarifiedList[i]?.city,Experience:UnvarifiedList[i]?.workAndExperience[0]?.Company, 
        YearOfExperience:UnvarifiedList[i]?.workAndExperience[0]?.Experience,Skill:UnvarifiedList[i]?.workAndExperience[0]?.Skill,RegisteredOn:  moment(UnvarifiedList[i]?.updatedAt).fromNow()})
    }}
  }
  const exportType = "xls";
  const [fileName, setfileName] = useState("Employee Details");

  const ExportToExcel = () => {
        exportFromJSON({ data, fileName, exportType });
  };
  const saveFile = (item, name) => {
    saveAs(`https://univ.mobi/user/${item}`, `${name}Resume.pdf`);
  };
 
  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = UnvarifiedList.filter((ele)=>ele?.isDelete===false).filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setUnvarifiedList([...UnvarifiedList]);
    }
  };

  const makeBlock=async (id)=>{
    try{
      const config = {
        url: "/makeBlockUnBlockEmployee",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: {userId:id,isBlock:true},
      };
     let res= await axios(config)
     if(res.status===200){
      window.location.reload();
      getJobList();
     }
    }catch(error){
      //console.log(error)
    }
  }
  const [show1, setShow1] = useState(false);
  const [Delete, setDelete] = useState();


  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setDelete(data);
  };

  const deleteEmployee= async (id)=>{
    try{
      let res = await axios.delete("https://univ.mobi/api/user/deleteProfileParmanet/"+Delete._id);
      if (res.status === 200) {
        getJobList();
        setShow1(false);
        alert("Successfully deleted")
      }
    }catch(error){
      //console.log(error)
    }
  }
  const deleteEmployee1= async (id)=>{
    try{
      let res = await axios.delete("https://univ.mobi/api/user/deleteProfileParmanet/"+id);
      if (res.status === 200) {
        getJobList();
        setShow1(false);
        alert("Successfully deleted")
      }
    }catch(error){
      //console.log(error)
    }
  }
  const makeUnBlock=async (id)=>{
    try{
      const config = {
        url: "/makeBlockUnBlockEmployee",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: {userId:id,isBlock:false},
      };
     let res= await axios(config)
     if(res.status===200){
      window.location.reload();
      getJobList();
     }
    }catch(error){
      //console.log(error)
    }
  }
  //console.log("unvarifiedList===>", UnvarifiedList);
  function Blockbtn() {

    for(let i =0 ;i <singleselected.length ; i++){
    
  makeBlock(singleselected[i])
  
  
    }
  }
  function Unblockbtn() {
  
    for(let i =0 ;i <singleselected.length ; i++){
    
  makeUnBlock(singleselected[i])
    }
  }
  function Deletebtn() {

    for(let i =0 ;i <singleselected.length ; i++){
    
  deleteEmployee1(singleselected[i])
    }
  }
  const handleCheckboxSelect = (event, data) => {
    if (event.target.checked) {
      setsingleselect([...singleselected, data]);
      
    
    // setshowMoreId(singleselected?.includes(data),"true/false")
      console.log(singleselected,"jjjj");
    } else {
      setsingleselect(singleselected?.filter(id => id !== data));
      

    }
  };
 console.log(singleselected,"fdfdfdfdfd");

  const handleSelectAll = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected, UnvarifiedList?.map(item => item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
 
  return (
    <>
      {/* img */}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border: "1px solid rgba(36, 73, 106, 0.21)",
              borderRadius:" 10px",
              marginBottom: "10px",
              boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Employee Details</b>
                </h1>
                {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
              </div>
            </div>
           
            <div className=" my-1 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="searchHeadFlex">
            <div className="searchWrapper">
              <input
                type="text"
                maxlength="230"
                style={{"margin-right": "10px",
                          "border-radius": "5px"}}
                className="textsearch"
                placeholder="Search by Employee"
                onChange={handleFilter}
              />
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                id="BsSearch"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>
            <div className="tble">
            <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll}   type="checkbox"  style={{marginLeft:"20px"}}/>
          
                <Button class="btn btn-outline-primary" onClick={Blockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Block{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Unblockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Unblock{" "}
                 
                </Button>
           
                   
            <Button class="btn btn-outline-primary" onClick={Deletebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Delete All{" "}
                 
                </Button>  
           
           </div>
           <p align="right" style={{marginTop:-50}} >
           
           <Button
             variant="primary"
             type="button"
             value="ShortList"
             onClick={ExportToExcel}
             style={{
               backgroundColor: "rgb(5, 38, 78)",
               color: "white",
               border: "none",
             }}
           >
             <b style={{ color: "white"}}>Export to Excel</b>
           </Button>
        </p>
              <table style={{ width: "100%",zIndex:"auto" }}>
                <tr>
                  <th></th>
                  <th>Logo</th>
                  <th>Employee Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Resume</th>
                  <th>Joined on</th>
                  <th>Last Update</th>
                  <th>Action</th>
                  <th>Remove</th>
                </tr>
                {search.length > 0
                  ? tableFilter.slice(pagesVisited, pagesVisited + usersPerPage).map((item, index) => {    return (
                    <tr>
                     <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>

                      <td>
                        {item?.profile ? (
                          <img
                            src={`https://univ.mobi/user/${item?.profile}`}
                            width="30"
                            height="30"
                            className="rounded-circle"
                            alt="React Bootstrap logo"
                            style={{ marginRight: "10px" }}
                          />
                        ) : (
                          <BiUserCircle
                            style={{
                              margin: "0px 10px",
                              fontSize: "22px",
                              color: "#24496a",
                            }}
                          />
                        )}
                      
                      </td>
                      <td>
                        <Link to={`/sub-adminuserDetailsView/${item?._id}`}>
                          <a href="" name="name" id="name">
                            {item?.name}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sub-adminuserDetailsView/${item?._id}`}>
                          <a href="">{item?.email}</a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sub-adminuserDetailsView/${item?._id}`}>
                          <a href="">{item?.mobile}</a>
                        </Link>
                      </td>
                      <td>
                          {item?.resume ? (<a  onClick={()=>saveFile(item?.resume,item?.name)}>
                            {" "}
                            <img
                              src="./pngwing.com.png"
                              width="30"
                              height="30"
                              className="s"
                              style={{ marginRight: "8px" ,cursor:"pointer"}}
                              alt="React Bootstrap logo"
                            />
                          </a>):(<RxCross2 style={{color:"red",fontSize:"20px"}}></RxCross2>)}
                          
                        </td>
                        <td> {moment(item?.createdAt).fromNow()}</td>
                        <td>  {moment(item?.updatedAt).fromNow()}</td>
                        <td  style={{display:"flex",gap:"3px"}}><Link to={`/sub-adminuserDetailsView/${item?._id}`} class="btn btn-outline-primary">View</Link>{item?.isBlock===false ? (<button type="button"  class="btn btn-danger" onClick={()=>makeBlock(item?._id)}>Block</button>):(<button type="button" class="btn btn-warning" onClick={()=>makeUnBlock(item?._id)}>Unblock</button>)}</td>
                        <td>
                        <MdDelete size={24} onClick={() => handleShow1(item)}
                                style={{ color: "red",marginLeft:'10px' }}/>
                              {/* <Button
                                variant="danger"
                              onClick={()=>handleShow1(item)}
                                style={{ color: "white" }}
                              >
                                Delete
                              </Button>{" "} */}
                            </td> </tr>
                  )}):
                  UnvarifiedList?.filter((ele)=>ele?.isDelete===false)?.slice(
                    pagesVisited,
                    pagesVisited + usersPerPage
                  ).map((item) => (
                    <tr>
                     <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>

                      <td>
                        {item?.profile ? (
                          <img
                            src={`https://univ.mobi/user/${item?.profile}`}
                            width="30"
                            height="30"
                            className="rounded-circle"
                            alt="React Bootstrap logo"
                            style={{ marginRight: "10px" }}
                          />
                        ) : (
                          <BiUserCircle
                            style={{
                              margin: "0px 10px",
                              fontSize: "22px",
                              color: "#24496a",
                            }}
                          />
                        )}
                      
                      </td>
                      <td>
                        <Link to={`/sub-adminuserDetailsView/${item?._id}`}>
                          <a href="" name="name" id="name">
                            {item?.name}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sub-adminuserDetailsView/${item?._id}`}>
                          <a href="">{item?.email}</a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sub-adminuserDetailsView/${item?._id}`}>
                          <a href="">{item?.mobile}</a>
                        </Link>
                      </td>
                      <td>
                          {item?.resume ? (<a  onClick={()=>saveFile(item?.resume,item?.name)}>
                            {" "}
                            <img
                              src="./pngwing.com.png"
                              width="30"
                              height="30"
                              className="s"
                              style={{ marginRight: "8px" ,cursor:"pointer"}}
                              alt="React Bootstrap logo"
                            />
                          </a>):(<RxCross2 style={{color:"red",fontSize:"20px"}}></RxCross2>)}
                          
                        </td>
                        <td> {moment(item?.createdAt).fromNow()}</td>
                        <td>  {moment(item?.updatedAt).fromNow()}</td>
                        <td  style={{display:"flex",gap:"3px"}}><Link to={`/sub-adminuserDetailsView/${item?._id}`} class="btn btn-outline-primary">View</Link>{item?.isBlock===false ? (<button type="button"  class="btn btn-danger" onClick={()=>makeBlock(item?._id)}>Block</button>):(<button type="button" class="btn btn-warning" onClick={()=>makeUnBlock(item?._id)}>Unblock</button>)}</td>
                        <td>
                        <MdDelete size={24} onClick={() => handleShow1(item)}
                                style={{ color: "red",marginLeft:'10px' }}/>
                              {/* <Button
                                variant="danger"
                              onClick={()=>handleShow1(item)}
                                style={{ color: "white" }}
                              >
                                Delete
                              </Button>{" "} */}
                            </td> </tr>
                  ))}
                
              
              </table>
              <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton style={{ backgroundColor: "#165c49" }}>
          <Modal.Title style={{ color: "white", fontWeight: "900" }}>
            Delete Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#165c49" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ color: "white", fontSize: "20px" }}>
              Are you sure?
            </Form.Label>

            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#165c49" }}>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "red" }}
            onClick={deleteEmployee}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
              <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                  
            </div>
       
          
          </div>
        
        </div>
      </div>
    </>
  );
}

export default User1;
