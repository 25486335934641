import axios from "axios";
import React, { useState } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { BsBook } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

function EmployersRegister() {
  let history = useNavigate();

  const [user, setUser] = useState({
    name: "",
    userName: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
  });

  const { cpassword, email, password, mobile, userName, name } = user;

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { password, cpassword } = user;
    if (password !== cpassword) {
      alert("Passwords don't match");
    } else {
      try {
        const config = {
          url: "/registerEmployer",
          method: "post",
          baseURL: "https://univ.mobi/api/user",
          headers: { "content-type": "application/json" },
          data: user,
        };
        let res = await axios(config);
        if (res.status === 200) {
          history("/employers-login");
          // sessionStorage.setItem("user", JSON.stringify(res.data.user));
          alert("successfully added");
          // history.push("/");
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <>
      <div className="container pr">
        <div
          class="login-page-bg-00">
     
        {/* Login Start */}
        <div className="register-0 login-signup-box">
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <h1 class="modal-title" style={{
                  fontSize: "25px",
                  fontWeight: "600",
            }}>Register</h1>
 <h1 class="modal-title"  style={{
                  fontSize: "25px",
                  fontWeight: "600",
            }}>Employer</h1>
          </div>
          <form onSubmit={(e) => onSubmit(e)}>
            <fieldset>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  class="form-control"
                  id="name"
                  onChange={(e) => onInputChange(e)}
                  value={name}
                  required
                />
                <span class="error-txt"></span>
              </div>
              {/* <div className="form-group ">
                <input
                  type="text"
                  name="userName"
                  placeholder="User Name"
                  class="form-control"
                  id="userName"
                  onChange={(e) => onInputChange(e)}
                  value={userName}
                  required
                />
                <span class="error-txt"></span>
              </div> */}
              <div class="form-group ">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="Email"
                  id="id_email"
                  onChange={(e) => onInputChange(e)}
                  value={email}
                  required
                />
                <span class="error-txt"></span>
              </div>

              <div class="form-group ">
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  class="form-control"
                  id="mobile"
                  required
                  onChange={(e) => onInputChange(e)}
                  value={mobile}
                />
                <span class="error-txt"></span>
              </div>
              <div class="form-group ">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  class="form-control"
                  id="id_password"
                  onChange={(e) => onInputChange(e)}
                  value={password}
                  required
                />
                <span class="error-txt"></span>
              </div>
              <div class="form-group ">
                <input
                  type="text"
                  name="cpassword"
                  placeholder="Confirm Password"
                  class="form-control"
                  id="cpassword"
                  required
                  onChange={(e) => onInputChange(e)}
                  value={cpassword}
                />
                <span class="error-txt"></span>
              </div>
            </fieldset>
            <div className="form-check">
              <div class="form-check form-group error">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="term_conditions"
                    required
                  />
                  I agree to{" "}<span onClick={()=>history("")} style={{cursor:"pointer",color:"#0d6efd"}}>
                    Terms &amp; Conditions
                  </span>{" "}
                  and
                  <span onClick={()=>history("")} style={{cursor:"pointer",color:"#0d6efd"}}>
                    {" "}
                    Privacy policy
                  </span>
                  <span class="error-txt"></span>
                </label>
              </div>
              <button type="submit" class="btn btn-primary submit">
                Register
              </button>
              <small class="form-text text-muted text-center" style={{
                paddingLeft:"25px"
              }}>
                Already have an account?<span  onClick={()=>history("/employers-login")} style={{cursor:"pointer",color:"#0d6efd"}} >Login Now</span>
              </small>
            </div>
          </form>
        </div>
        </div>
      </div>
    </>
  );
}

export default EmployersRegister;
