import axios from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import { AiFillCamera, AiOutlineMail } from "react-icons/ai";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import { FiPhoneCall } from "react-icons/fi";
import { CgEditBlackPoint } from "react-icons/cg";
import {TbPoint} from "react-icons/tb"
import {GrLocation} from "react-icons/gr"
import moment from "moment";
import { color } from "@mui/system";

function DummyResume() {
  const handlePrint = () => {
    window.print();
  };
  let check = JSON.parse(sessionStorage.getItem("user"));
  if (!check) {
    alert("Please login");
    window.location.assign("/employers-login");
  }
  const [image, setImgae] = useState();

  const [resume, setResumes] = useState({});
  const [SkillSet, setSkillset] = useState([]);
  const [educate, setEducate] = useState([]);
  const [achivement, setachivement] = useState([]);
  const [Langset, setlangset] = useState([]);

  const [Projects, setProjects] = useState([]);
  const [workandEx, setworkandEx] = useState([]);
  const loadUsers = async () => {
    const config = {
      url: "/user/getResumeByuser/" + check._id,
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setResumes(result.data.success);
        setSkillset(result.data.success?.skillSet);
        setlangset(result.data.success?.langset);

        setEducate(result.data.success?.education);
        setachivement(result.data.success?.ACHIEVEMENTS);
        setProjects(result.data.success?.addProject);
        setworkandEx(result.data.success?.workAndExperience);
        setImgae(
          `https://univ.mobi/resume/${result.data.success?.profile}`
        );
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    loadUsers();
  }, []);

  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    let img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${resume?.name}Resume.pdf`);
  };

  return (
    <div>
      {/* <button onClick={handlePrint}>print</button> */}
      <div className="q1ox_q-sides q1pm_q-box">
        <div
          id="pdf"
          style={{ padding: "53px 20px 0px 20px", height: "auto",maxWidth:"984px" }}
          className="container"
        >
          <div className="row">
            <div className="col-md-4">
              <div
                className="q1zh_q-side-top q1zh_q-side-top-xslot1"
                style={{ paddingLeft: "65px" }}
              >
                <div className="q1zh_q-header">
                  <div className="q1eu_q-photo q1le_q ">
                    <div
                      class="q1xr_q-photo-img-border x-t-c1-border x-t-c1"
                      style={{
                        maxWidth: "210px",
                        maxHeight: "210px",
                        width: "210px",
                        height: "210px",
                      }}
                    >
                      <div class="q1xr_q-photo-img">
                        {resume?.profile ? (
                          <img
                            src={image}
                            // src="/Images/profileIcon.png"
                            alt="rj"
                            style={{
                              width: "100%",
                              borderRadius: " 50%",
                              height: "212px",
                              border: " 1px solid black",
                              // padding:"2px"
                            }}
                          />
                        ) : (
                          <img
                            src="/Images/profileIcon.png"
                            alt="rj"
                            style={{
                              width: "100%",
                              borderRadius: " 50%",
                              height: "212px",
                              border: " 1px solid black",
                              padding: "10px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="create-090">
                      <div className="skills-lbl pb-2">
                        <h3>SKILLS</h3>

                        <div className="q1ro_q-label-0">
                          <ul>
                            {SkillSet.length === 0 ? (
                              <div style={{ display: "none" }}></div>
                            ) : (
                              <>
                                {SkillSet?.map((item) => {
                                  return (
                                    <li>
                                      {item?.skill}
                                  
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="skills-lbl pb-2">
                        <h3>LANGUAGES</h3>
                        <div className="q1ro_q-label-0">
                          <ul>
                            {Langset.length === 0 ? (
                              <div style={{ display: "none" }}></div>
                            ) : (
                              <>
                                {Langset?.map((item) => {
                                  return (
                                    <li>
                                      {item?.LANGUAGES}
                                  
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="skills-lbl pb-2">
                        <h3>STRENGTH</h3>
                        <div className="q1ro_q-label-0">
                          <ul>
                            {resume?.INTERESTS?.split(",")?.map((item) => {
                              return <li>{item}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="skills-lbl pb-2">
                        <h3>HOBBIES</h3>
                        <div className="q1ro_q-label-0">
                          <ul>
                            {resume?.Hobbies?.split(",")?.map((item) => {
                              return <li>{item}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8" style={{ borderLeft: "groove" }}>
              <div className="q1zh_q-name-con">
                <div class="q1ro_q-full-name">
        
                  <h3 style={{marginBottom:"-7px"}}>{resume?.name}</h3>
                </div>
                <div class="q1va_q-title">
                  <div className="hdhe-00 mb-3  "><strong>{resume?.jobTitle}</strong></div>
                
                  <div className="row">
                  <div className="col-md-4">
                      <div
                        className="hdhe-00"
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <strong>
                          D.O.B:{" "}
                        </strong>
                        <span>{moment(resume?.dateOfbirth).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')?"" :moment(resume?.dateOfbirth).format("DD/MM/YYYY") }</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="hdhe-00 "
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <span>
                          <AiOutlineMail />
                        </span>
                        <span>{resume?.email}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="hdhe-00 "
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <span>
                          <FiPhoneCall />
                        </span>
                        <span> {resume?.mobile}</span>
                      </div>
                    </div>
                  </div>
                  <div className="hdhe-00 mb-3 "><GrLocation/>{" "}{resume?.birthofPlace}</div>
                  <div className="hdhe-00 mb-3">
                    <div style={{ display: "flex", gap: "10px" }}>
                      <span style={{ marginTop: "2px", fontSize: "14px" }}>
                        <CgEditBlackPoint />
                      </span>
                      <h3 className="lk-nh-i">CAREER OBJECTIVES</h3>
                    </div>
                    {resume?.ProfessionalSummary}
                  </div>
                </div>
              </div>

              <div className="hdhe-00 mb-3">
                <div className="save-date-08">
                  <div className="educt-09">
                    {/* <img
                      src="/Images/a-1.svg"
                      alt="educate"
                      style={{ width: "20px", height: "20px" }}
                    /> */}
                    <span style={{ marginTop: "2px", fontSize: "14px" }}>
                      <CgEditBlackPoint />
                    </span>
                    <h3 className="lk-nh-i">EDUCATION</h3>
                  </div>
                </div>
                {educate?.map((item) => {
                  return (
                   
                    <div style={{ marginLeft: "10px" }}>
                      <span
                        class="font-sm color-text-mutted mb-10"
                        style={{ fontSize: "18px" }}
                      >
                        <VscDebugBreakpointLog />&nbsp;&nbsp;
                        {item?.Institue}&nbsp;&nbsp;
                      </span>
                      {" - "}
                      <span>
                        {item.Course},&nbsp;&nbsp;{item?.Location}&nbsp;&nbsp; ({item?.starting})
                      </span>
                    </div>
                  );
                })}
              </div>
              {workandEx?.length === 0 ? (
                <></>
              ) : (
                <>
                  <div className="hdhe-00 mb-3">
                    <div className="save-date-08">
                      {workandEx?.length === 0 ? (
                        <></>
                      ) : (
                        <div className="educt-09">
                          <span style={{ marginTop: "2px", fontSize: "14px" }}>
                            <CgEditBlackPoint />
                          </span>
                          <h3 className="lk-nh-i">WORK EXPERIENCE</h3>
                        </div>
                      )}
                    </div>
                    {workandEx?.map((item) => {
                      return (
                        <div style={{ marginLeft: "10px" }}>
                          <span
                            class="font-sm color-text-mutted mb-10"
                            style={{ fontSize: "18px" }}
                          >
                            <VscDebugBreakpointLog />&nbsp;&nbsp;
                            {item?.Company}&nbsp;&nbsp;
                          </span>
                          {" - "}
                          <span>
                            {item.position},&nbsp;&nbsp;{item?.workPlace}&nbsp;&nbsp; ({item?.session})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {resume?.certificate?.length ? (
                <div className="hdhe-00 mb-3">
                  <div className="educt-09">
                    <span style={{ marginTop: "2px", fontSize: "14px" }}>
                      <CgEditBlackPoint />
                    </span>
                    <h3 className="lk-nh-i">CERTIFICATES</h3>
                  </div>
                  {resume?.certificate?.map((data)=>{
                    return (
                      <div style={{ marginLeft: "10px" }}>
                      <span
                        class="font-sm color-text-mutted mb-10"
                        style={{ fontSize: "18px" }}
                      >
                        <VscDebugBreakpointLog />
                        {data?.certificate}
                      </span>
                     
                    </div>
                    )
                  })}
                
                  {/* <span>{resume?.certificate}</span> */}
                </div>
              ) : (
                <></>
              )}

              {Projects?.length === 0 ? (
                <></>
              ) : (
                <>
                  <div className="hdhe-00 mb-3">
                    <div className="save-date-08">
                      <div className="educt-09">
                        <span style={{ marginTop: "2px", fontSize: "14px" }}>
                          <CgEditBlackPoint />
                        </span>
                        <h3 className="lk-nh-i">PERSONAL PROJECTS</h3>
                      </div>
                    </div>
                    {Projects?.map((item) => {
                      return (
                        <div style={{ marginLeft: "10px" }}>
                          <span
                            class="font-sm color-text-mutted mb-10"
                            style={{ fontSize: "18px" }}
                          >
                            <VscDebugBreakpointLog />
                            {item?.projectName}
                          </span>{" "}
                          <span>({item?.DateOftime}) </span>
                          <span class="font-sm color-text-mutted mb-10">
                            : {" "}
                          </span>
                          <span>{item?.discription}</span>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {achivement?.length === 0 ? (
                <></>
              ) : (
                <div className="hdhe-00 mb-3">
                  <div className="save-date-08">
                    <div className="educt-09">
                      <span style={{ marginTop: "2px", fontSize: "14px" }}>
                        <CgEditBlackPoint />
                      </span>
                      <h3 className="lk-nh-i">ACHIEVEMENTS</h3>
                    </div>
                  </div>
                  {achivement?.map((item) => {
                    return (
             
                      <div style={{ marginLeft: "10px" }}>
                      <span
                        class="font-sm color-text-mutted mb-10"
                        style={{ fontSize: "18px" }}
                      >
                        <VscDebugBreakpointLog />
                        {item?.achiveName}
                      </span>{" "}
                      <span>({item?.session}) </span>
                      <span class="font-sm color-text-mutted mb-10">
                        : {" "}
                      </span>
                      <span>{item?.discription}</span>
                    </div>
                    );
                  })}
                </div>
              )}
              {resume?.SkiilSummery?.length ? (
                <div className="hdhe-00 mb-3">
                  <div className="educt-09">
                    <span style={{ marginTop: "2px", fontSize: "14px" }}>
                      <CgEditBlackPoint />
                    </span>
                    <h3 className="lk-nh-i">WORK SUMMARY</h3>
                  </div>
                  {resume?.SkiilSummery?.map((data)=>{
                    return (
                     
                      <ul>
                    <li style={{padding:"0px",marginBottom:"-17px"}}> {data?.summery}</li>
                    </ul>

                   
                    )
                  })}
                
                  {/* <span>{resume?.certificate}</span> */}
                </div>
              ) : (
                <></>
              )}
              
               {resume?.declearation ? (
                <div className="hdhe-00 mb-3" >
                  <br/>
                  <div className="educt-09">
                    <span style={{ marginTop: "2px", fontSize: "14px" }}>
                      <CgEditBlackPoint />
                    </span>
                    <h3 className="lk-nh-i">DECLARATION</h3>
                  </div>
       
                  <span style={{ marginLeft: "10px" }}>{resume?.declearation}</span>
                </div>
              ) : (
                <></>
              )}
              <br></br>
              {resume?.singn ? ( <div style={{float:"right"}}> 
              <h8>Signature of the Candidate</h8>
              <h4 style={{textAlign:"center",margin:"10px", color:"#646464"}}>{resume?.singn}</h4>
              </div>):(<></>)}
             
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <div className="save-date-08">
              <div className="educt-09">
                <div className="save-0">
                  <a href="/resume-create">
                    <button class="btn btn-secondary w-100">Edit</button>
                  </a>
                </div>
              </div>
              <div className="save-0">
                <button class="btn btn-secondary w-100" onClick={createPDF}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DummyResume;
