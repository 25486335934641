import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import exportFromJSON from "export-from-json";
import moment from "moment";
import ReactPaginate from "react-paginate";

function NotInterested1() {
  const [deleteData,setDData]=useState([])
  const getJobList = async () => {
    let res = await axios.get("https://univ.mobi/api/user/getAllProfile");
    if (res.status === 200) {
      //console.log(res.data.success);
      setDData(res.data.success);

    }
  };
  const [employer,setEmployer]=useState([])
  const getJobList1 = async () => {
    let res = await axios.get("https://univ.mobi/api/user/getAllProfileEmployer");
    if (res.status === 200) {
      //console.log(res.data);
      setEmployer(res.data.success);
    }
  };
 
  const deleteEmployer= async (id)=>{
    try{
      let res = await axios.delete("https://univ.mobi/api/user/deleteParmanetEmployer/"+id);
      if (res.status === 200) {
        getJobList1();
        alert("Successfully deleted")
      }
    }catch(error){
      //console.log(error)
    }
  }
const  deleteddata = deleteData?.filter((ele)=>ele?.isDelete===true);
console.log(deleteddata);
  const data = [];
  if (deleteddata?.length !== 0) {
    for (let i = 0; i < deleteddata.length; i++) {
      data.push({
        EmployeeorEmployerName: deleteddata[i]?.name,
        ContactNo: deleteddata[i]?.mobile,
        Reason: deleteddata[i]?.reasion,
       
        // PostedOn: moment(deleteddata[i]?.updatedAt).fromNow(),
      });
    }
  }
  const exportType = "xls";
  const [fileName, setfileName] = useState("Not Intrested Users");

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  const deleteEmployee= async (id)=>{
    try{
      let res = await axios.delete("https://univ.mobi/api/user/deleteProfileParmanet/"+id);
      if (res.status === 200) {
        getJobList();
        alert("Successfully deleted")
      }
    }catch(error){
      //console.log(error)
    }
  }
  useEffect(()=>{
    getJobList();
    getJobList1();
  },[])
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    deleteddata.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}
            <div
              className="container"
              style={{
                border: "1px solid rgba(36, 73, 106, 0.21)",
                borderRadius: " 10px",
                marginBottom: "10px",
                boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                marginBottom: "10px",
              }}
            >
              <div class="container">
                <div class="loginform">
                  <h1
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                      textAlign: "center",
                    }}
                  >
                    <b>Not Interested User</b>
                  </h1>
                  {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
                </div>
              </div>
              {/* <p align="right">
                  <Link to={`/addjob1`}>
                    <Button
                      type="button"
                      style={{
                        backgroundColor: "rgb(5, 38, 78)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Post Job +
                    </Button>
                  </Link>
                </p> */}
              <div className="tble">
                <table style={{ width: "100%",zIndex:"auto" }}>
                  <tr>
                   
                    <th>Employee /Employer Name</th>
                    <th>Contact No</th>
                    <th>Reason</th>
                    <th>Action</th>
                  </tr>
                  {deleteData?.filter((ele)=>ele?.isDelete===true)?.slice(pagesVisited, pagesVisited + usersPerPage).map((item)=>{
                          return (
                            <tr>
                            <td>
                              <a>{item?.name}</a>
                            </td>{" "}
                            <td>
                              <a>{item?.mobile}</a>
                            </td>{" "}
                            <td>
                              <a>{item?.reasion}</a>
                            </td>
                           
                            <td>
                              {" "}
                              <Button
                                variant="danger"
                              onClick={()=>deleteEmployee(item?._id)}
                                style={{ color: "white" }}
                              >
                                Delete
                              </Button>{" "}
                            </td>
                          </tr>
                          )
                        })}
                     
                     {employer?.filter((ele)=>ele?.isDelete===true)?.slice(pagesVisited, pagesVisited + usersPerPage).map((item)=>{
                          return (
                            <tr>
                            <td>
                              <a>{item?.name}</a>
                            </td>{" "}
                            <td>
                              <a>{item?.mobile}</a>
                            </td>{" "}
                            <td>
                              <a>{item?.reasion}</a>
                            </td>
                           
                            <td>
                              {" "}
                              <Button
                                variant="danger"
                              onClick={()=>deleteEmployer(item
                                ?._id)}
                                style={{ color: "white" }}
                              >
                                Delete
                              </Button>{" "}
                            </td>
                          </tr>
                          )
                        })}
                </table>
                <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              <p align="right" style={{ marginBottom: "2em" }}>
            <Button
              variant="primary"
              type="button"
              value="ShortList"
              onClick={ExportToExcel}
              style={{
                backgroundColor: "rgb(5, 38, 78)",
                color: "white",
                border: "none",
              }}
            >
              <b style={{ color: "white" }}>Export to Excel</b>
            </Button>
          </p>
              </div>
       
             
              {/* img */}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default NotInterested1;
