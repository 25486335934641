import axios from 'axios';
import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
function Client() {
  const [alldata,setAlldata]=useState([]);

  const getAllcategory=async ()=>{
    try {
      let res =await axios.get("https://univ.mobi/api/admin/getAllOurClient")
      if(res.status===200){
        setAlldata(res.data?.success)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getAllcategory();
    getAllcategory();
  },[])
  return (
   <>
   <div>
    {/* <div className='text-center'><h3 style={{fontWeight:"800",color: "#05264e"}}>OUR CLIENTS</h3></div> */}
    <OwlCarousel className='owl-theme' items={6} loop margin={8} nav={false} dots={false} autoplay={true} >
{alldata?.map((item)=>{
  return (
    <div class='item'>
    <img src={`https://univ.mobi/ourclient/${item?.imageLogo}`} alt='' style={{width:"100%",height:"100px"}} />
    </div>
  )
})}
 

    {/* <div class='item'>
    <img src='https://s3.amazonaws.com/cdn.designcrowd.com/blog/…e-Most-Valuable-Companies-of-2020/google-logo.png' alt='' style={{width:"80%",height:"115px"}} />
    </div>
    <div class='item'>
    <img src='https://1000logos.net/wp-content/uploads/2021/09/HCL-Logo-500x315.png' alt='' style={{width:"80%",height:"115px"}} />
    </div>
    <div class='item'>
    <img src='https://1000logos.net/wp-content/uploads/2021/09/HCL-Logo-500x315.png' alt='' style={{width:"80%",height:"115px"}} />
    </div>
   */}
</OwlCarousel>
   </div>
   </>
  )
}

export default Client