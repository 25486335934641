import React, { useState } from "react";
import ProSidebar from "./ProSidebarS";
import Button from "react-bootstrap/Button";
import { BiLogOut, BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import './MajorProduct.scoped.scss';
import { FaBusinessTime, FaIndustry, FaPager } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { BsArrowsFullscreen, BsFillBellFill, BsFlagFill } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Wcs from "./WcsS";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Applieddetails from "./ApplieddetailsS";
import { IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { HiQrcode } from "react-icons/hi";

function Adminp(props) {
  const history = useNavigate();
  const admin = JSON.parse(sessionStorage.getItem("subadmin"));
  //console.log("subadmin===>",admin)
  if (!admin) {
    alert("please Login");
    window.location.assign("/sub-admindashboard");
  }
  const logOut = () => {
    alert("Successfully logout");
    window.location.assign("/sub-admindashboard");
    window.sessionStorage.removeItem("subadmin");
  };
  const [pic,setPic]=useState("");
// editAdmin
const formdata = new FormData();

const addjob = async () => {
  formdata.set("profile", pic);
  formdata.set("subadminId",admin?._id)
  try {
    const config = {
      url: "/udateSubAdmin",
      method: "put",
      baseURL: "https://univ.mobi/api/admin",
      headers: { "content-type": "multipart/data" },
      data: formdata,
    };
    await axios(config).then((res) => {
      if (res.status === 200) {
        window.sessionStorage.removeItem("subadmin");
        sessionStorage.setItem("subadmin", JSON.stringify(res.data.success));
        window.location.reload(true)
      }
    });
  } catch (error) {
    //console.log(error);
    alert(error.response.data.error);
  }
};

if(pic){
  addjob();
}


  return (
    <>
      <div className="townhub">
      {!admin ? (
        <div style={{ textAlign: "center" }}>
          <h1>Please Login!</h1>
        </div>
      ) : (
        <div
          style={{
            zIndex: "100",
            top: "0px",
            width: "100%",
            position: "fixed",
          
          }}
        >
          <Navbar
            bg=""
            expand="lg"
            style={{
              backgroundColor: "rgb(43, 183, 146)",
              marginBottom:"30px"
            }}
          
          >
            <Container fluid>
            <div style={{ justifyContent: "flex-start" }}>
                <a href="#">
                  <img
                    src="/admin.logo.png"
                    alt=""
                    width="114px"
                    class="hoverZoomLink"
                    // style={{ height: "50px", borderRadius: "8px" }}
                  />
                </a>
              </div>

              <h6
                className="nav-link "
                data-rr-ui-event-key="/sub-career-guidance"
                style={{ marginLeft: "940px" }}
              >
                {" "}
                {/* <BsFlagFill style={{ margin: "0px 10px", fontSize: "22px" }} /> */}
                {/* <BsArrowsFullscreen
                style={{ margin: "0px 5px", fontSize: "22px" }}
              /> */}
               
                  <BiUserCircle
                    style={{
                      margin: "0px 10px",
                      fontSize: "22px",
                      color: "#24496a",
                    }}
                  />
           
                {admin?.name}
                {/* <AiOutlineSetting
                  style={{ margin: "0px 10px", fontSize: "22px" }}
                /> */}
              </h6>

              <Button
                variant="outline-info"
                onClick={() => logOut()}
                style={{
                  color: "rgb(5, 38, 78)",
                  border: "1px solid rgb(5, 38, 78)",
                }}
              >
                {" "}
                <BiLogOutCircle />
                Logout
              </Button>
            </Container>
          </Navbar>{" "}
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-3 fixed"
                style={{
                  border: "1px solid #f8faff",
                  // height: "700px",
                  backgroundColor: "#f8faff",
                  color: "#e0e6f7",
                  // width: "20%",
                  height: "551px",
                     overflow:"hidden",overflowY:"auto",

                  position: "fixed",
                }}
              >
                {/* <div className="container"> */}
                 
             
                {/* <item></item> */}
                <div
                  className="container"
                  style={{
                    textAlign: "left",
                    lineHeight: "2.1rem",
                    color: "#e0e6f7",
                  }}
                >
                  <ul style={{fontSize:"22px",listStyle:"none",padding:"0px"}}>
              <Link to="/sub-adminhome" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j">
                        <FaPager style={{ marginRight: "8px" }} />
                        Home
                      </li>
                    </Link>
                    {admin?.subadmin==="true" ? ( <Link to="/sub-add-sub-admin" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j">
                        <FaPager style={{ marginRight: "8px" }} />
                        Sub-Admin
                      </li>
                    </Link>):(<></>)}
                  {admin?.graphics==="true" ? (<Link to="/sub-admingraph" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j">
                        <FaPager style={{ marginRight: "8px" }} />
                        Graph
                      </li>
                    </Link>):(<></>)}
                    {admin?.industries==="true" ?(  <Link to="/sub-industries" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j">
                        <FaIndustry style={{ marginRight: "8px" }} />
                        Industries
                      </li >
                    </Link>):(<></>)}

                    {admin?.category==="true" ?  (<Link to="/sub-admincategory" style={{ color: "rgb(36, 73, 106)" }}>
                    {" "}
                    <li className="hero_link_j">
                      <FaPager style={{ marginRight: "8px" }} />
                      Category
                    </li>
                  </Link>):(<></>)}
                   
                  {admin?.employer==="true" ?  (
                    <Link to="/sub-adminemployee" style={{ color: "rgb(36, 73, 106)" }}>
                    {" "}
                    <li className="hero_link_j">
                      <FaPager style={{ marginRight: "8px" }} />
                      Employer
                    </li>
                  </Link>
                  ):(<></>)}
                  
                   {admin?.employee==="true" ?  (
                    <Link to="/sub-adminuser" style={{ color: "rgb(36, 73, 106)" }}>
                    {" "}
                    <li className="hero_link_j">
                      <FaPager style={{ marginRight: "8px" }} />
                      Employee
                    </li>
                  </Link>
                 
                  ):(<></>)}
                    
                    {admin?.ourClient==="true" ?  (
                   <Link to="/sub-admin-our-client" style={{ color: "rgb(36, 73, 106)" }}>
                   {" "}
                   <li className="hero_link_j">
                     <FaPager style={{ marginRight: "8px" }} />
                     Our Clients
                   </li>
                 </Link>
    
                  ):(<></>)}
                   {admin?.PostedJob==="true" ?  (
                    <Link
                    to="/sub-adminunverified"
                    style={{ color: "rgb(36, 73, 106)" }}
                  >
                    {" "}
                    <li className="hero_link_j">
                      {" "}
                      <FaPager style={{ marginRight: "8px" }} />
                      Posted Jobs
                    </li>
                  </Link>
                  ):(<></>)}
               
                  {admin?.verifiedJob==="true" ?  (
                    <Link to="/sub-adminverified" style={{ color: "rgb(36, 73, 106)" }}>
                    {" "}
                    <li className="hero_link_j">
                      <FaPager style={{ marginRight: "8px" }} />
                      Approved Jobs
                    </li>
                  </Link>
                  ):(<></>)}

                  {admin?.appliedDetails==="true" ?  (
                    <Link
                    to="/sub-adminapplieddetails"
                    style={{ color: "rgb(36, 73, 106)" }}
                  >
                    <li className="hero_link_j">
                      <FaPager style={{ marginRight: "8px" }} />
                      Applied Details
                    </li>
                  </Link>
                  ):(<></>)}
                   {admin?.notification==="true" ?  (
                  <Link
                      to="/sub-notification"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <BsFillBellFill style={{ marginRight: "8px" }} />
                        Notification
                      </li>
                    </Link>
                    ):(<></>)}
                     {admin?.notification==="true" ?  (
                  <Link
                      to="/sub-addbusiness"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <FaBusinessTime style={{ marginRight: "8px" }} />
                        Business Content
                      </li>
                    </Link>
                    ):(<></>)}
                   {admin?.intrested==="true" ?  (   <Link
                      to="/sub-not-interested-job"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <MdDeleteForever style={{ marginRight: "8px" }} />
                        Not Interested User's
                      </li>
                    </Link>
                     ):(<></>)}
                      <Link
                      to="/qrcodeS"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <HiQrcode style={{ marginRight: "8px" }} />
                        QR Code
                      </li>
                    </Link>
                    <Link to="gallery1" style={{ color: "#e0e6f7" }}>
                      {" "}
                    </Link>
                    <Link to="adminwcs" style={{ color: "#e0e6f7" }}>
                      {" "}
                    </Link>
                    
                    <a href="/sub-admincompanyDetails" style={{ color: "#e0e6f7" }}>
                      {" "}
                    </a>
                    <Link to="/sub-admincompanyDetailsEdit"> </Link>
                    <Link
                      to="/sub-adminemployeeDetails/:id"
                      style={{ color: "#e0e6f7" }}
                    >
                      {" "}
                    </Link>
                    <Link to="/sub-adminemployeeDetailsEdit"> </Link>
                    <Link to="/sub-admincompanyd"> </Link>
                    <Link to="/sub-adminemployeeDetailsView"> </Link>
                  </ul>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-17">
                {props.children}

             
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
}

export default Adminp;
