import React, { useState } from "react";
import ProSidebar from "./ProSidebar";
import Button from "react-bootstrap/Button";
import { BiCategory, BiLogOut, BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import './MajorProduct.scoped.scss';
import { FaBusinessTime, FaIndustry, FaPager } from "react-icons/fa";
import { GiCardExchange } from "react-icons/gi";
import {  BsFillBellFill, BsFlagFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Wcs from "./Wcs1";
import {
  MdOutlineAdminPanelSettings,MdAutoGraph, MdOutlineWorkOutline, MdSupervisedUserCircle, MdOutlinePostAdd, MdOutlineVerifiedUser, MdOutlineSettingsApplications, MdDeleteForever
} from "react-icons/md";
import Applieddetails from "./Applieddetails1";
import { IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import axios from "axios";
import {AiOutlineHome} from 'react-icons/ai'
import {SiWorkplace} from "react-icons/si"
import { HiQrcode } from "react-icons/hi";

function Adminp(props) {
  const history = useNavigate();
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  if (!admin) {
    alert("please Login");
    window.location.assign("/admindashboard");
  }
  const logOut = () => {
    alert("Successfully logout");
    window.location.assign("/admindashboard");
    window.sessionStorage.removeItem("admin");
  };
  const [pic,setPic]=useState("");
// editAdmin
const formdata = new FormData();

const addjob = async () => {
  formdata.set("profile", pic);
  formdata.set("adminId",admin?._id)
  try {
    const config = {
      url: "/editAdmin",
      method: "put",
      baseURL: "https://univ.mobi/api/admin",
      headers: { "content-type": "multipart/data" },
      data: formdata,
    };
    await axios(config).then((res) => {
      if (res.status === 200) {
        window.sessionStorage.removeItem("admin");
        sessionStorage.setItem("admin", JSON.stringify(res.data.success));
        window.location.reload(true)
      }
    });
  } catch (error) {
    //console.log(error);
    alert(error.response.data.error);
  }
};

if(pic){
  addjob();
}
  return (
    <>
      <div className="townhub">
      {!admin ? (
        <div style={{ textAlign: "center" }}>
          <h1>Please Login!</h1>
        </div>
      ) : (
        <div
          style={{
            zIndex: "100",
            top: "0px",
            width: "100%",
            position: "fixed",
           
          }}
        >
          <Navbar
            bg=""
            expand="lg"
            style={{
              backgroundColor: "rgb(43, 183, 146)",
              marginBottom:"30px"
            }}
          >
            <Container fluid>
              <div style={{ justifyContent: "flex-start" }}>
                <a href="/adminhome">
                  <img
                    src="/admin.logo.png"
                    alt=""
                    width="114px"
                    class="hoverZoomLink"
                    // style={{ height: "50px", borderRadius: "8px" }}
                  />
                </a>
              </div>

              <h6
                className="nav-link "
                data-rr-ui-event-key="/career-guidance"
                style={{ marginLeft: "940px" }}
              >
                {" "}
                {/* <BsFlagFill style={{ margin: "0px 10px", fontSize: "22px" }} /> */}
                {/* <BsArrowsFullscreen
                style={{ margin: "0px 5px", fontSize: "22px" }}
              /> */}
                {admin?.profile ? (
                  <img
                    src={`https://univ.mobi/admin/${admin?.profile}`}
                    width="30"
                    height="30"
                    className="rounded-circle"
                    alt="React Bootstrap logo"
                    style={{ marginRight: "10px"}}
                  />
                ) : (
                  <BiUserCircle
                    style={{
                      margin: "0px 10px",
                      fontSize: "22px",
                      color: "#24496a",
                    }}
                  />
                )}
                {admin?.name}
                {/* <AiOutlineSetting
                  style={{ margin: "0px 10px", fontSize: "22px" }}
                /> */}
              </h6>

              <Button
                variant="outline-info"
                onClick={() => logOut()}
                style={{
                  color: "rgb(5, 38, 78)",
                  border: "1px solid rgb(5, 38, 78)",
                }}
              >
                {" "}
                <BiLogOutCircle />
                Logout
              </Button>
            </Container>
          </Navbar>{" "}
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-3 fixed"
                style={{
                  border: "1px solid #f8faff",
                  // height: "700px",
                  backgroundColor: "#f8faff",
                  color: "#e0e6f7",
                  // width: "20%",
                  height: "77%",
                  position: "fixed",
                  overflow:"hidden",
                  overflowY:"scroll"
                }}
              >
               

               
               <div
                className="bg-white"
                style={{
                  padding: "30px 10px",
                  marginTop: "-50px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
               

                <div
                  className="row"
                  style={{
                    marginLeft: "-148px",
                    marginTop: "-4px",
                    color: "black",
                  }}
                >                 

              {/* <Link to={`/employerprofile`}>
                <Button
                  style={{
                    marginLeft: "134px",
                    padding: "6px 20px",
                    backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none",
                  }}
                >
                  View Profile
                </Button></Link> */}
                </div>
              </div>
              
              
                {/* <img
                className="xx"
                src="./un .jpg"
                style={{ height: "35%", width: "35%", borderRadius: "50%" }}
              /> */}
                {/* <h4>
                <b style={{ color: "#05264e", textTransform: "uppercase" }}>
                  {" "}
                  Univi India
                </b>
              </h4> */}
             
                
                {/* <h6 style={{ color: "#66789c" }}>DASHBOARDS</h6> */}


                {/* <item></item> */}
                <div
                  className="container"
                  style={{
                    textAlign: "left",
                    lineHeight: "2.1rem",
                    color: "#e0e6f7",
                  }}
                >
                  <ul style={{fontSize:"22px",listStyle:"none",padding:"0px"}}>
                
               <Link to="/adminhome" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j">
                        <AiOutlineHome style={{ marginRight: "8px" }} />
                        Home
                      </li>
                    </Link>
                    <Link to="/add-sub-admin" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j" >
                        <MdOutlineAdminPanelSettings style={{ marginRight: "8px" }} />
                       Sub-Admin
                      </li>
                    </Link>
                  
                    <Link to="/admingraph" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j">
                        <MdAutoGraph style={{ marginRight: "8px" }} />
                        Graph
                      </li >
                    </Link>
                    <Link to="/industries" style={{ color: "rgb(36, 73, 106)" }}>
                      <li className="hero_link_j">
                        <FaIndustry style={{ marginRight: "8px" }} />
                        Industries
                      </li >
                    </Link>
                    <Link to="/admincategory" style={{ color: "rgb(36, 73, 106)" }}>
                    {" "}
                    <li className="hero_link_j">
                      <BiCategory style={{ marginRight: "8px" }} />
                      Category
                    </li>
                  </Link>
                    <Link to="/adminemployee" style={{ color: "rgb(36, 73, 106)" }}>
                      {" "}
                      <li className="hero_link_j">
                        <MdOutlineWorkOutline style={{ marginRight: "8px" }} />
                        Employer
                      </li>

                    </Link>
                    <Link to="/adminuser" style={{ color: "rgb(36, 73, 106)" }}>
                      {" "}
                      <li className="hero_link_j">
                        <SiWorkplace style={{ marginRight: "8px" }} />
                        Employee
                      </li>
                    </Link>
                    {/* <Link to="/addjob1" style={{ color: "rgb(36, 73, 106)" }}>
                      {" "}
                      <li>
                        <FaPager style={{ marginRight: "8px" }} />
                        Add Job
                      </li>
                    </Link> */}
               
                  <Link to="/admin-our-client" style={{ color: "rgb(36, 73, 106)" }}>
                    {" "}
                    <li className="hero_link_j">
                      <MdSupervisedUserCircle style={{ marginRight: "8px" }} />
                      Our Clients
                    </li>
                  </Link>
                
                  <Link
                      to="/adminunverified"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      {" "}
                      <li className="hero_link_j">
                        {" "}
                        <MdOutlinePostAdd style={{ marginRight: "8px" }} />
                        Posted Jobs
                      </li>
                    </Link>
                    <Link to="/adminverified" style={{ color: "rgb(36, 73, 106)" }}>
                      {" "}
                      <li className="hero_link_j">
                        <MdOutlineVerifiedUser style={{ marginRight: "8px" }} />
                        Approved Jobs
                      </li>
                    </Link>
                   
                    <Link
                      to="/adminapplieddetails"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <MdOutlineSettingsApplications style={{ marginRight: "8px" }} />
                        Applied Details
                      </li>
                    </Link>
                    <Link
                      to="/notification"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <BsFillBellFill style={{ marginRight: "8px" }} />
                        Notification
                      </li>
                    </Link>
                    <Link
                      to="/addbusiness"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <FaBusinessTime style={{ marginRight: "8px" }} />
                        Business Content
                      </li>
                    </Link>
                    <Link
                      to="/not-interested-job"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <MdDeleteForever style={{ marginRight: "8px" }} />
                        Not Interested User's
                      </li>
                    </Link>
                    <Link to="/password" style={{ color: "rgb(36, 73, 106)" }}>
                    <li className="hero_link_j">
                        <GiCardExchange style={{ marginRight: "8px" }} />
                        Change Password
                      </li>
                    </Link>
                    <Link
                      to="/qrcode"
                      style={{ color: "rgb(36, 73, 106)" }}
                    >
                      <li className="hero_link_j">
                        <HiQrcode style={{ marginRight: "8px" }} />
                        QR Code
                      </li>
                    </Link>
                    <Link to="adminwcs" style={{ color: "#e0e6f7" }}>
                      {" "}
                    </Link>
                    {/* <Link to="view" style={{ color: "#e0e6f7" }}>
                      {" "}
                    </Link> */}
                    <a href="/admincompanyDetails" style={{ color: "#e0e6f7" }}>
                      {" "}
                    </a>
                    <Link to="/admincompanyDetailsEdit"> </Link>
                    <Link
                      to="/adminemployeeDetails/:id"
                      style={{ color: "#e0e6f7" }}
                    >
                      {" "}
                    </Link>
                    <Link to="/adminemployeeDetailsEdit"> </Link>
                    <Link to="/admincompanyd"> </Link>
                    <Link to="/adminemployeeDetailsView"> </Link>
                  </ul>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-17">
                {props.children}

             
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
}

export default Adminp;
