import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  FaBuilding,
  FaPhoneAlt,
  FaMailBulk,
  FaBookReader,
  FaCity,
  FaLocationArrow,
  FaMoneyBillAlt,
  FaMoneyCheckAlt,
  FaMoneyBillWaveAlt,
  FaGlobeAsia,
  FaSuitcase,
  FaLanguage,
  FaUserClock,
} from "react-icons/fa";
import {
  BsFillPersonCheckFill,
  BsFillPersonPlusFill,
  BsLink45Deg,
  BsPinMapFill,
  BsCalendarDate,
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsLinkedin,
  BsFillChatSquareTextFill,
  BsFillTelephoneInboundFill,
} from "react-icons/bs";
import { FcBusinessman } from "react-icons/fc";
import { TbBrandJavascript } from "react-icons/tb";
import {
  GiSkills,
  GiMatterStates,
  GiStreetLight,
  GiOpeningShell,
  GiTakeMyMoney,
  GiLevelTwoAdvanced,
} from "react-icons/gi";
import { BiCategoryAlt } from "react-icons/bi";
import { MdDone, MdNightlight } from "react-icons/md";
import { IoLocationSharp, IoLogoWhatsapp } from "react-icons/io5";
import { RiLuggageDepositLine } from "react-icons/ri";
import { GrUserExpert } from "react-icons/gr";
import { Modal } from "antd";

function CompanyDetails3() {
  const [item, setCompanyDetails] = useState({});
  const { id } = useParams();
  let history = useNavigate();

  const getVerifiedList = async () => {
    let res = await axios.get("https://univ.mobi/api/user/getJobById/" + id);
    if (res.status === 200) {
      //console.log(res.data);
      setCompanyDetails(res.data.success);
    }
  };
  const makeVerified = async () => {
    let res = await axios.put("https://univ.mobi/api/user/makeVerify/" + id);
    if (res.status === 200) {
      alert(res.data.success);
      history(-1);
    }
  };
  const blockJob = async () => {
    let res = await axios.put("https://univ.mobi/api/user/makeUnVerify/" + id);
    if (res.status === 200) {
      alert(res.data.success);
      history(-1);
    }
  };

  useEffect(() => {
    getVerifiedList();
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const [reasion, setreasion] = useState("");

  const holdEmployer = async () => {
   
    const config = {
      url: "/user/makeVerifyUnverifyAdmin",
      method: "post",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
      data: { status: "Hold", reasion: reasion, userId: id },
    };
    if(!reasion )
    {alert("Please enter the reason")} else {
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setIsModalOpen(false);
        alert("Successfully hold");
        getVerifiedList();
      } else {
        alert("Something went worng");
      }
     
    } catch (err) {
      //console.log(err.message);
    }
    }
  };
  const handleOk = () => {
    holdEmployer();
  };
  const ApprovedEmployee = async () => {
    const config = {
      url: "/user/makeVerifyUnverifyAdmin",
      method: "post",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
      data: { status: "Approved", reasion: "", userId: id },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert("Successfully approved");
        getVerifiedList();
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border: "1px solid rgba(36, 73, 106, 0.21)",
              borderRadius: " 10px",
              marginBottom: "10px",
              boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
              overflow: "scroll",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Job Details</b>
                </h1>
              </div>
              <div
                className="d-flex "
                style={{ justifyContent: "space-between" }}
              >
                <p align="left">
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                    onClick={() => history(-1)}
                  >
                    Back
                  </Button>
                </p>

                <p align="right">
                  {item?.adminId ? (
                    <Link to={`/admincompanyDetailsEdit/${id}`}>
                      <Button
                        type="button"
                        style={{
                          backgroundColor: "rgb(5, 38, 78)",
                          color: "white",
                          border: "none",
                        }}
                      >
                        +Edit
                      </Button>
                    </Link>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
              {/* <p align="right">
                {item?.adminId ? (<Link to={`/admincompanyDetailsEdit/${id}`}>
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                  >
                    +Edit
                  </Button>
                </Link>):( <></>)}
              </p> */}
            </div>
            <div className="t">
              <Table striped bordered hover style={{ width: "100%",zIndex:"auto" }}>
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Logo</th>
                    <th>Name</th>
                    <th></th>
                    <th>Details</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {/* <td>1</td> */}

                    <td>
                      <FaBuilding />
                    </td>
                    <td>Company Name</td>
                    <td>:</td>
                    <td>{item?.companyName}</td>
                  </tr>
                  <tr>
                    {/* <td>2</td> */}
                    <td>
                      <TbBrandJavascript />
                    </td>
                    <td>Job Title </td>
                    <td>:</td>
                    <td>{item?.jobProfile}</td>
                  </tr>

                  <tr>
                    {/* <td>2</td> */}
                    <td>
                      <FaSuitcase />
                    </td>
                    <td>Category</td>
                    <td>:</td>
                    <td>{item?.category}</td>
                  </tr>
                  <tr>
                    {/* <td>3</td> */}
                    <td>
                      <FaSuitcase />
                    </td>
                    <td>Type Of Job</td>
                    <td>:</td>
                    <td>{item?.typeofjob}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiOpeningShell />
                    </td>
                    <td>Openings</td>
                    <td>:</td>
                    <td>{item?.openings}</td>
                  </tr>
                  <tr>
                    <td>
                      <MdNightlight />
                    </td>
                    <td>Shift Type</td>
                    <td>:</td>
                    <td>{item?.night}</td>
                  </tr>

                  <tr>
                    <td>
                      <GiSkills />
                    </td>
                    <td>Skill</td>
                    <td>:</td>
                    <td>{item?.skill}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Work Type</td>
                    <td>:</td>
                    <td>{item?.typeofwork}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Experience</td>
                    <td>:</td>
                    {item.experiencerequired === "Fresher" || item.experiencerequired === "Any" ? (
                      <td>{item.experiencerequired}</td>
                    ) : (
                      <td>
                        {item?.experience
                          ? item?.experience
                          : item.experiencerequired}
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Education</td>
                    <td>:</td>
                    <td>{item?.typeofeducation}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Qualification</td>
                    <td>:</td>
                    <td>{item?.typeofqualification}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaUserClock />
                    </td>
                    <td>Who can apply</td>
                    <td>:</td>
                    <td>{item?.gendertype} </td>
                  </tr>
                  <tr>
                    <td>
                      <FaLocationArrow />
                    </td>
                    <td>Location</td>
                    <td>:</td>
                    <td>{item?.location}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiTakeMyMoney />
                    </td>
                    <td>Salary Type</td>
                    <td>:</td>
                    <td>{item?.salarytype}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiTakeMyMoney />
                    </td>
                    <td>Min Salary-Max Salary</td>
                    <td>:</td>
                   <td> {item.minSalary}-{item.maxSalary}{item.minSalary ? "/" :""}{ item.period}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiTakeMyMoney />
                    </td>
                    <td>Incentive</td>
                    <td>:</td>
                    <td>
                      {item?.averageIncentive ? item?.averageIncentive : 0}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <GiLevelTwoAdvanced />
                    </td>
                    <td>Benefit</td>
                    <td>:</td>
                    <td>{item?.benefits}</td>
                  </tr>
                  <tr>
                    <td>
                      <RiLuggageDepositLine />
                    </td>
                    <td>Deposit</td>
                    <td>:</td>
                    <td>
                      {item?.fee ? item?.fee : "No Deposit"}
                      {item?.fee ? (
                        <div
                          style={{
                            color: "#05264e",
                            fontSize: "16px",
                            fontWeight: "900",
                          }}
                        >
                          Reason
                        </div>
                      ) : (
                        ""
                      )}
                      :{item.reason}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <GrUserExpert />
                    </td>
                    <td>Experience Required</td>
                    <td>:</td>
                    <td>
                     {item.experiencerequired}
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <FaLanguage />
                    </td>
                    <td>English Level Preference</td>
                    <td>:</td>
                    <td>{item?.english}</td>
                  </tr>

                  <tr>
                    <td>
                      <FaUserClock />
                    </td>
                    <td>Interviewer Name</td>
                    <td>:</td>
                    <td>{item?.interviewername} </td>
                  </tr>
                  <tr>
                    <td>
                      <IoLogoWhatsapp />
                    </td>
                    <td>Whatsapp</td>
                    <td>:</td>
                    <td>{item?.whatsapp}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaMailBulk />
                    </td>
                    <td>Email</td>
                    <td>:</td>
                    <td>{item?.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsFillTelephoneInboundFill />
                    </td>
                    <td>Call Us</td>
                    <td>:</td>
                    <td>{item.time}</td>
                  </tr>

                  <tr>
                    <td>
                      <FaLocationArrow />
                    </td>
                    <td>Address</td>
                    <td>:</td>
                    <td>
                      {item.address ? item.address : "Telephonic Interview"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BsFillChatSquareTextFill />
                    </td>
                    <td>Description</td>
                    <td>:</td>
                    <td>{item?.description ? parse(item?.description) : "" }</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* table */}
         

            {/* img */}
          </div>
          <Modal
            title="Reason"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={reasion}
                onChange={(e) => setreasion(e.target.value)}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails3;
