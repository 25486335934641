import React, { useEffect, useState } from "react";
import { MdModeEditOutline, MdOutlineDeleteForever } from "react-icons/md";
import { GiCrossMark } from "react-icons/gi";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Col,  Row } from "react-bootstrap";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import { BsArrowDownCircle, BsStopwatch } from "react-icons/bs";
import { DatePicker } from 'antd';
import { saveAs } from "file-saver";
import moment from "moment";
import { Modal } from 'antd';

function UserDetailsView1() {
  const saveFile = (item, name) => {
    saveAs(`https://univ.mobi/user/${item}`, `${name}Resume.pdf`);
  };
  const [endingDate, setEnding] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  // //console.log("amit date =======>",endingDate?.$y,startDate?.$y
  const [profile, setprofile] = useState(true);
  const [job, setjob] = useState(false);
  const [savejob, setsavejob] = useState(false);
  let users = JSON.parse(sessionStorage.getItem("user"));
  // //console.log(users);
  const formdata = new FormData();
  const [backgroud, setBackground] = useState("");
  const [resume, setResumes] = useState("");
  const [profileImage, setProimage] = useState("");
  const [user, setUser] = useState({});
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [mobile, setmobile] = useState("");
  const [bio, setbio] = useState("");
  const [Country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [City, setCity] = useState("");
  const [pincode, setpincode] = useState("");
  const [int, setint] = useState("");
  const [int1, setint1] = useState("");
  const [int2, setint2] = useState("");
  const [int3, setint3] = useState("");
  const [educa, setEduca] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [skillset, setSkillset] = useState([]);
  const [editBtn, setEditbtn] = useState(false);
  const [show, setShow] = useState(false);
  const [joblist, setJobList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [iner1, setIner1] = useState("");
  const [iner2, setIner2] = useState("");
  useEffect(() => {
    loadUsers();
  }, []);

  const history = useNavigate();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Are you sure delete?
    </Tooltip>
  );

  const {id}=useParams()
  const loadUsers = async () => {
    const config = {
      url: "/user/getUserById/" + id,
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setUser(result.data.success);
        setEduca(result.data.success.education);
        setCompanyData(result.data.success.workAndExperience);
        setSkillset(result.data.success.skillSet);
        setIner1(result.data.success.interest.int);
        setIner2(result.data.success.interest.int1);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

 

  const deleteUser = async () => {
    const config = {
      url: "/user/deleteProfile/" + id,
      method: "delete",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert("Successfully deleted");
        history("/adminuser");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
 

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  
      deleteUser();
    
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              // marginTop: "30px",
              borderRadius: "10px",
              // height: "500px",
              // overflow: "scroll",
              marginBottom: "10px",
            }}
          ></div>
    {/*  */}
      <div className="profile">
        <section class="section-box-2">
          <div class="container">
          
            <div class="box-company-profile">
              <div class="image-compay">
                {user.profile ? (
                  <>
                    {" "}
                    <img
                      src={`https://univ.mobi/user/${user?.profile}`}
                      alt="jobbox"
                      style={{
                        width: "89%",
                        borderRadius: "50%",
                        height: "81px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                     src="/Images/profileIcon.png"
                      alt="jobbox"
                      style={{
                        width: "89%",
                        borderRadius: "50%",
                        height: "81px",
                      }}
                    />
                  </>
                )}

            
              </div>
              <div class="row mt-10">
                <div class="col-lg-8 col-md-12">
                  <h5 class="f-18">
                    {user?.name}{" "}
                    <span class="card-location font-regular ml-20">
                      {user?.city}{" "}{user?.country}
                    </span>
                  </h5>
                  <p >
                  
                  {user?.interest?.int ? (  <span
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "2px ",
                  }}
                >{user?.interest?.int}</span>) : <></>}{" "}
               
               {user?.interest?.int1 ? (  <span
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "2px ",
                  }}
                >{user?.interest.int1}</span>) : <></>}{" "}
              {user?.interest?.int2 ? (  <span
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "2px ",
                  }}
                >{user?.interest.int2}</span>) : <></>}{" "}
               {user?.interest?.int3 ? (  <span
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "2px ",
                  }}
                >{user?.interest.int3}</span>) : <></>}{" "}
              
              </p>
                </div>
               
              </div>
            </div>
            <div
                    class=""
                    style={{  textAlign: "right" }}
                  >
                   
                    {/* <Button variant="primary"  onClick={showModal}>
                      Delete Account
                    </Button> */}

                    <Modal show={show} onHide={handleClose} className="wrapo">
                      <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure delete your account ?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          cencel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            deleteUser();
                          }}
                        >
                          ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
        
          </div>
        </section>

        {/* /////////////////// */}

        <div className="section-box mt-50" style={{marginTop:"-51px"}}>
          <div className="container">
            <div className="">
              <div className="">
                <div class="box-nav-tabs nav-tavs-profile mb-5">
                  
                  
                  
                </div>
              </div>
              <div className="col-sm-12">
              
                
                
                        <div className="content-single">
                          <div className="tab-content">
                            <div className="tab-pane fade show active">
                              <h3 class="mt-0 mb-15 color-brand-1">
                                 Account
                              </h3>
                              <a
                                class="font-md color-text-paragraph-2"
                                href="#"
                              >
                                Employee profile
                              </a>
                              {/* <div class="mt-35 mb-40 box-info-profie">
                            <div class="image-profile">
                              <img
                                src="/Images/candidate-profile.png"
                                alt="jobbox"
                              />
                            </div>
                            <a>
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                              >
                                <input hidden accept="image/*" type="file" />
                                <PhotoCamera />
                              </IconButton>
                            </a>
                            <a class="btn btn-link">Delete</a>
                          </div> */}

                              <div className="row form-contact">
                                <div className="col-lg-6 col-md-12">
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Full Name *
                                    </label>
                                    <br />
                                    <span>{user.name}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Email *
                                    </label>
                                    <br />
                                    <span>{user.email}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Contact Number *
                                    </label>
                                    <br />
                                    <span>{user.mobile}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Category *
                                    </label>
                                    <br />
                                    <span>{user.industry}</span>
                                  </div>
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                    Career Objective *
                                    </label>
                                    <br />
                                    <span>{user.bio}</span>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Country
                                        </label>
                                        <br />
                                        <span>{user.country}</span>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          State
                                        </label>
                                        <br />
                                        <span>{user.state}</span>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          City
                                        </label>
                                        <br />
                                        <span>{user.city}</span>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Zip code
                                        </label>
                                        <br />
                                        <span>{user.pincode}</span>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Resume *
                                      </label>
                                      {user?.resume ? (
                                    <spam
                                      style={{
                                        fontSize: "20px",
                                        color: "rgb(5, 38, 78)",
                                      }}
                                    >
                                      {" "}
                                      <BsArrowDownCircle
                                        onClick={() =>
                                          saveFile(user?.resume, user?.name)
                                        }
                                      >
                                        download
                                      </BsArrowDownCircle>
                                    </spam>
                                  ) : (
                                    <></>
                                  )}
                                  <br></br>
                                  <div className="d-flex" style={{ gap:"20px"}}>
                                  <button
                                  type="button"
                                  // style={{ width: "100px" }}
                                  onClick={() =>
                                    history(-1)
                                  }
                                  class="btn btn-dark"
                                >
                                  Back
                                </button>
                               
                                  </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div
                                    class="border-bottom  mb-30"
                                    style={{
                                      borderBottom: "none",
                                      marginTop: "0px",
                                    }}
                                  >
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Educations *
                                      </label>
                                      <br />
                                      {educa.map((data) => {
                                        return (
                                          <div className="row">
                                            <p>
                                              <span className="amit-0">
                                                Institue*&nbsp;:{" "}
                                              </span>
                                              <span>{data.Institue}</span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                                Course*:{" "}
                                              </span>
                                              <span>
                                                {data.Course} ({data.field})
                                              </span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                                Duration*:{" "}
                                              </span>
                                              <span>
                                                {data.starting}-{data.passOut}
                                              </span>
                        
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div class="form-group">
                                      <label class="font-sm color-text-mutted mb-10">
                                        Experience *
                                      </label>
                                      {companyData.map((data) => {
                                        return (
                                          <div className="row">
                                            <p>
                                              <span className="amit-0">
                                                Company Name*&nbsp;:{" "}
                                              </span>
                                              <span>{data.Company}</span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                              Designation*:{" "}
                                              </span>
                                              <span>{data.Skill}</span>
                                            </p>
                                            <p>
                                              <span className="amit-0">
                                                Duration*:{" "}
                                              </span>
                                              <span>{data.Period}</span>
                                         
                                              <span
                                                style={{
                                                  float: "right",
                                                  marginRight: "17px ",
                                                }}
                                              >
                                                {data.Experience} Year
                                              </span>
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <div class="box-skills">
                                      <h5 class="mt-0 color-brand-1">Skills</h5>
                                      <div class="form-contact">
                                        <div class="form-group"></div>
                                      </div>
                                      <div class="box-tags mt-30">
                                   
                                   <div
                                     style={{
                                   
                                       justifyContent: "space-between",
                                     }}
                                   >
                                     
                                   </div>

                                   <Row>
                                   {skillset.map((data) => {
                                 return (
                                    
                                       <Col md="3" style={{padding:'4px'}}>
<a class="btn btn-grey-small mr-10" > {data.skill}</a>
                                       </Col>
                                     );
                                   })}
                                   
                                   </Row>
                              
                             </div>
                                      <div class="mt-40">
                                        {" "}
                                     
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <h6 class="color-orange mb-20">
                              Change your password
                            </h6>
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <label class="font-sm color-text-mutted mb-10">
                                    Password
                                  </label>
                                  <input
                                    class="form-control"
                                    type="password"
                                    value={users.password}
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <label class="font-sm color-text-mutted mb-10">
                                    Confirm Password *
                                  </label>
                                  <input
                                    class="form-control"
                                    type="confirm password"
                                    value={users.password}
                                  />
                                </div>
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                 
             
             
              
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      </div>
      </div>

      {/* Modal */}
  
      <Modal title="  Are you sure delete your account ?" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      
      </Modal>
    </>
  );
}

export default UserDetailsView1;
