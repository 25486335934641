import React, { useEffect } from "react";


// import "../Adminpannel/MajorProduct.scoped.scss";
import "./MajorProduct.scoped.scss"
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TimePicker } from "antd";

import { Input } from "antd";

// back-e

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Country, State, City } from "country-state-city";

import "../Employer/MajorProducts.css";
import Button from "react-bootstrap/Button";
import { TiDelete } from "react-icons/ti";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { WhatsApp } from "@mui/icons-material";
import { MdOutlineDeleteForever } from "react-icons/md";
import { OverlayTrigger } from "react-bootstrap";

const steps = ["Basic Details", "Candidate Details", "Interviewer Details"];

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const customWeekStartEndFormat = (value) =>
  `${dayjs(value)
    .startOf("week")
    .format(weekFormat)} ~ ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {
  //console.log(time, timeString);
};


function CompanyDetailsEdit1() {
  let check = JSON.parse(sessionStorage.getItem("admin"));
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [item, setCompanyDetails] = useState({});
  const [companyname, setCompanyname] = useState("");
  const [openings, setopenings] = useState("");
  const [address, setaddress] = useState("");
  const [jobprofile, setJobprofile] = useState("");
  const [email, setEmail] = useState("");
  const [skill, setSkill] = useState("");
  const [fee, setfee] = useState("");
  const [minsalary, setMinsalary] = useState();
  const [maxsalary, setMaxsalary] = useState();
  const [location, setLocation] = useState("");
  const [english, setenglish] = useState("");
  const [category, setcategory] = useState("");

  const [typeofjob, settypeofjob] = React.useState("");
  const [typeofeducation, settypeofeducation] = React.useState("");
  const [night, setnight] = React.useState("");

  const [typeofwork, settypeofwork] = React.useState("");
  const [interview, setinterview] = useState("");
  const [salarytype, setSalarytype] = useState("");
  const [typeofqualification, settypeofqualification] = useState("");

  const [reason, setreason] = useState("");
  const [experience, setExperience] = useState("");
  const [experiencerequired, setexperiencerequired] = useState("");
  const [gendertype, setgendertype] = useState("");
  const [description, setdescription] = useState("");
  const [benefits, setbenefits] = useState("");
  const [interviewername, setinterviewername] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [period, setperiod] = useState("");


  const [time, settime] = useState("");
  const [averageIncentive, setaverageIncentive] = useState("");
  // const [submit, setSubmit] = useState("");
  const { id } = useParams();
  const formdata = new FormData();

  //console.log("formdata===>", formdata);
  

 
 //console.log(experiencerequired,"sadasd");

  const addjob = async () => {
  formdata.set("jobId", id);
    formdata.set("companyName", companyname);
  formdata.set("openings", openings);
  formdata.set("address", address);
  formdata.set("night", night);

  formdata.set("fee", fee);
  formdata.set("english", english);
  formdata.set("period", period);
  formdata.set("category", category);


  formdata.set("interview", interview);
  formdata.set("typeofqualification", typeofqualification);

  formdata.set("salarytype", salarytype);
  formdata.set("typeofjob", typeofjob);
  formdata.set("typeofwork", typeofwork);
  formdata.set("typeofeducation", typeofeducation);
  formdata.set("reason", reason);
  formdata.set("experiencerequired", experiencerequired);
  formdata.set("gendertype", gendertype);
  formdata.set("jobProfile", jobprofile);
  formdata.set("email", email);
  formdata.set("skill", skill);
  formdata.set("benefits", benefits);



  formdata.set("minSalary", minsalary ? minsalary : "");
  formdata.set("maxSalary", maxsalary ? maxsalary : "");
  formdata.set("location", location);

  formdata.set("experience", experience);
  formdata.set("description", description);

  formdata.set("interviewername", interviewername);
  formdata.set("whatsapp", whatsapp);
  formdata.set("time", time);
  formdata.set("averageIncentive", averageIncentive);
  formdata.set("isVerify",false)

    try {
      const config = {
        url: "/editJob",
        method: "put",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "multipart/data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          let newSkipped = skipped;
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
          }
      
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
        }
          
        
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  let history = useNavigate();

  // conuntry
 
 
  const [alldata,setAlldata]=useState([]);

  const getAllcategory=async ()=>{
    try {
      let res =await axios.get("https://univ.mobi/api/admin/getAllCategory")
      if(res.status===200){
        setAlldata(res.data.success)
      }
    } catch (error) {
      //console.log(error);
    }
  }
  useEffect(() => {
   
    getVerifiedList();
    getAllcategory();
  }, []);
  
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleChange = (event) => {
    settypeofjob(event.target.value);
  };
  const handleChange10 = (e, editor) => {
    const data = editor.getData();
    setdescription(data);
  };
  const handleChange2 = (event) => {
    settypeofwork(event.target.value);
  };
  const handleChange3 = (event) => {
    setSalarytype(event.target.value);
  };
  const handleChange4 = (event) => {
    settypeofeducation(event.target.value);
  };
  const handleChange5 = (event) => {
    setgendertype(event.target.value);
  };
  const handleChange6 = (event) => {
    setexperiencerequired(event.target.value);
  };
  const handleChange7 = (event) => {
    setenglish(event.target.value);
  };
  const handleChange8 = (event) => {
    setinterview(event.target.value);
  };
  const handleChange9 = (event) => {
    setnight(event.target.value);
  };
  // lsfp
  const [fixed, setfixed] = useState(false);
  const [fixedIncentive, setfixedIncentive] = useState("");
  const [incentive, setincentive] = useState("");
  const [yesdepo, setyesdepo] = useState(false);
  const [nodepo, setnodepo] = useState(false);
  const [experienced, setexperienced] = useState(false);
  const [offline, setoffline] = useState("");
  const [online, setonline] = useState("true");
 
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    // setActiveStep(0);
    window.location.assign(`/adminunverified`);
  };
  const handleSkip = () => {
    // setActiveStep(0);
    window.location.assign(`/adminunverified`);
  };
  const handleNext1 = () => {
   
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  
  };
  const loadUsers = async () => {
    const config = {
      url: "/user/getEmployerById/" + check?._id,
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        sessionStorage.removeItem("employer");
        sessionStorage.setItem("employer", JSON.stringify(result.data.success));
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const [skillset, setSkillSet] = useState([]);
  const [job, setjob] = useState([]);

  const [benefitsSet, setBenefitsSet] = useState([]);
 
  useEffect(() => {
    loadUsers();
    getVerifiedList();
  }, []);
 
  const getVerifiedList = async () => {
    let res = await axios.get(
      "https://univ.mobi/api/user/getJobById/" + id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setCompanyDetails(res.data.success);
   
    }
  };
   return (
    
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9 "
          style={{ overflow: "scroll", height: "550px" }}
        >
          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              borderRadius: "8px",
              marginBottom: "10px",
              boxShadow: " rgb(0 0 0 / 16%) 0px 1px 4px",
              border: " 1px solid #00000047",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Edit Job</b>
                </h1>
              </div>
            </div>
            <br />

            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    Job Successfully Updated
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset} className="mt-1 mb-2">
                      Ok
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep === 0 ? (
                    <div className="stp_hero">
                      <div className="tex_heo mt-2">
                        <h4>Job Details</h4>
                        <div className="ing_jk">
                          <Box
                            component="form"
                            // sx={{
                            //   '& > :not(style)': { m: 1, width: '25ch' },
                            // }}
                            // noValidate
                            autoComplete="off"
                          >
                            <div className="row">
                              <div className="col-md-6">
                              <h6>Company Name</h6>
                                <div className="form-group mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    // label="Company Name"
                                    placeholder={item.companyName}
                                    variant="outlined"
                                    value={companyname}
                                    onChange={(e) =>
                                      setCompanyname(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                              <h6>Job-title*</h6>
                                <div className="form-group mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    // label="Job-title*"
                                    placeholder={item.jobProfile}
                                    variant="outlined"
                                    value={jobprofile}
                                    onChange={(e) =>
                                      setJobprofile(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                              <h6>Type of jobs</h6>
                                <div className="form-group mt-2">
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                    {item.typeofjob}
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                    
                                      value={typeofjob}
                                      // label="Type of jobsge"
                                      onChange={handleChange}
                                    >
                                       
                                      <MenuItem value="Part time">
                                        Part time
                                      </MenuItem>
                                      <MenuItem value="Full time">
                                        Full time
                                      </MenuItem>
                                      <MenuItem value="Both">Both</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-md-6">
                              <h6>No of openings*</h6>
                                <div className="form-group mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    // label="No of openings*"
                                    variant="outlined"
                                    placeholder={item.openings}
                                    value={openings}
                                    onChange={(e) =>
                                      setopenings(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                <h6>Category</h6>
                                  <div className="form-group mt-2">
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                      {item.category}
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={category}
                                    placeholder={item.category}

                                        label="Type of jobsge"
                                        onChange={(e) => {
                                          setcategory(e.target.value);
                                        }}
                                      >
                                      {alldata?.map((items) => {
                                        return (  <MenuItem value={items?.category}>
                                          {items?.category}
                                        </MenuItem>
                                         );
                                        })}
                                       
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-group mt-2">
                                    <input
                                      type="checkbox"
                                      value={night}
                                      onChange={(e) => setnight(e.target.value)}
                                    />
                                    <label
                                      style={{
                                        paddingLeft: "10px",
                                        fontSize: "17px",
                                      }}
                                    >
                                      This is a night shift job
                                    </label>
                                  </div>
                                  
                                </div> */}
                                            <div className="loction_job">
                          <h4>Shift Type :</h4>
                          <div className="radio-fileds-iocn">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={night}
                              onChange={handleChange9}
                            >
                              <FormControlLabel
                                value="Night Shift"
                                control={<Radio />}
                                label="Night Shift"
                              />
                              <FormControlLabel
                                value="Rotational Shift"
                                control={<Radio />}
                                label="Rotational Shift"
                              />
                              <FormControlLabel
                                value="General Shift"
                                control={<Radio />}
                                label="General Shift"
                              />
                              
                            </RadioGroup>
                          </div>
                        </div>
                                </div>
                            </div>
                          </Box>
                        </div>
                        {/* Work type */}
                        <div className="loction_job">
                          <h4>Work Type :</h4>
                          <div className="radio-fileds-iocn">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={typeofwork}
                              onChange={handleChange2}
                            >
                              <FormControlLabel
                                value="Work from home"
                                control={<Radio/>}
                               checked={typeofwork ? typeofwork === "Work from home": item.typeofwork==="Work from home"}
                                label="Work from home"
                              /> :
                            
                           <FormControlLabel
                                value="Work from office"
                                control={<Radio />}
                                checked={typeofwork ? typeofwork === "Work from office": item.typeofwork==="Work from office"}
                                label="Work from office"
                              /> 
                            <FormControlLabel
                                value="Field jobs"
                                control={<Radio />}
                                checked={typeofwork ? typeofwork === "Field jobs": item.typeofwork==="Field jobs"}
                                label="Field jobs"
                              /> 
                             <FormControlLabel
                                value="Freelancer"
                                control={<Radio />}
                                checked={typeofwork ? typeofwork === "Freelancer": item.typeofwork==="Freelancer"}
                                label="Freelancer"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        {/* Work Location */}
                        <div className="work_type pt-2">
                          <h4>Work location :</h4>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <TextField
                                id="outlined-basic"
                                // label="Enter full address*"
                                placeholder={item.location}
                                variant="outlined"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Salary type */}
                        <div className="salary_heo pt-2">
                          <h4>Salary Type :</h4>
                          <div className="salar_hero">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={salarytype}
                              onChange={handleChange3}
                            >
                              <FormControlLabel
                                value="Fixed only"
                                control={<Radio  />}
                                label="Fixed only"
                                checked={salarytype ? salarytype === "Fixed only": item.salarytype==="Fixed only"}
                                onClick={() => {
                                  setfixed(true);
                                  setfixedIncentive(false);
                                  setincentive(false);
                                }}
                              />
                              <FormControlLabel
                                value="Incentive only"
                                control={<Radio />}
                                label="Incentive only"
                                checked={salarytype ? salarytype === "Incentive only": item.salarytype==="Incentive only"}
                                onClick={() => {
                                  setfixed(false);
                                  setfixedIncentive(true);
                                  setincentive(false);
                                }}
                              />
                              <FormControlLabel
                                value="Fixed + Incentive"
                                control={<Radio />}
                                label="Fixed + Incentive "
                                checked={salarytype ? salarytype === "Fixed + Incentive": item.salarytype==="Fixed + Incentive"}
                                onClick={() => {
                                  setfixed(false);
                                  setfixedIncentive(false);
                                  setincentive(true);
                                }}
                              />
                            </RadioGroup>
                          </div>
                          {/* fkwmmsdddddddddddddddddddddrw */}
                          <div className="">
                            {fixed ? (
                              <>
                                <div className="fi_he">
                                  <h5>Fixed Salary / Month</h5>
                                  <div
                                    className="min_max"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <h6>Minimum salary</h6>
                                      <TextField
                                      type="number"
                                        id="outlined-basic"
                                        // label="minimum*"
                                        placeholder={item.minSalary}
                                        variant="outlined"
                                        value={minsalary}
                                        onChange={(e) =>
                                          setMinsalary(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      to
                                    </div>
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <h6>Maximum salary</h6>
                                      <TextField
                                      type="number"
                                        id="outlined-basic"
                                        // label="maximum*"
                                        placeholder={item.maxSalary}
                                        variant="outlined"
                                        value={maxsalary}
                                        onChange={(e) =>
                                          setMaxsalary(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div
                                                className="form-group mt-2"
                                                style={{ margin: "0px 10px" }}
                                              >
                                                 <h6>Salary Type*</h6>
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Salary Type*"
                                                  variant="outlined"
                                                  placeholder={item.period}
                                                  value={period}
                                               
                                                  onChange={(e) =>
                                                    setperiod(e.target.value)
                                                  }
                                                />
                                              </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {fixedIncentive ? (
                                  <>
                                    <div className="fi_he">
                                      <h5>Average Incentive / Month</h5>
                                      <div
                                        className="form-group mt-2 joker-0"
                                        style={{ margin: "0px 10px" }}
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          // label="Averge Incentive / Month*"
                                          variant="outlined"
                                          placeholder={item.averageIncentive}
                                          value={averageIncentive}
                                          onChange={(e) =>
                                            setaverageIncentive(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {incentive ? (
                                      <>
                                        <div className="fi_he">
                                          <h5>
                                            Fixed Salary + Average Incentive /
                                            Month
                                          </h5>
                                          <div
                                            className="min_max"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              <h6>Minimum salary</h6>
                                              <TextField
                                                id="outlined-basic"
                                                // label="minimum*"
                                                placeholder={item.minSalary}
                                                variant="outlined"
                                                value={minsalary}
                                                onChange={(e) =>
                                                  setMinsalary(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              to
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                               <h6>Maximum salary</h6>
                                              <TextField
                                                id="outlined-basic"
                                                // label="maximum*"
                                                variant="outlined"
                                                placeholder={item.maxSalary}
                                                value={maxsalary}
                                                onChange={(e) =>
                                                  setMaxsalary(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              +
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                                <h6>Average Incentive*</h6>
                                              <TextField
                                                id="outlined-basic"
                                                // label="Average Incentive*"
                                                placeholder={item.averageIncentive}
                                                variant="outlined"
                                                value={averageIncentive}
                                                onChange={(e) =>
                                                  setaverageIncentive(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div
                                                className="form-group mt-2"
                                                style={{ margin: "0px 10px" }}
                                              >
                                                <h6>Salary Type*</h6>
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Salary Type*"
                                                  placeholder={item.period}
                                                  variant="outlined"
                                                  value={period}
                                                //  placeholder="Eg: LPA / Month"
                                                  onChange={(e) =>
                                                    setperiod(e.target.value)
                                                  }
                                                />
                                              </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>

                          {/* [owjfkpeeegeiprfdkm;,cv .] */}
                        </div>

                        {/* Add Others Benifits */}
                        <div className="add_j">
                          <div className="add-jkl mt-2">
                            <h4>Add Other Benefit</h4>
                            <div className="d-flex align-items-center">
                              <div
                                className="form-group mt-2"
                                style={{ margin: "0px 10px" }}
                              >
                                <h6>Add benefit*</h6>
                                <TextField
                                  id="outlined-basic"
                                  // label="Add benefit*"
                                  variant="outlined"
                                  placeholder={item.benefits}
                                  value={benefits}
                                  onChange={(e) =>
                                    setbenefits(e.target.value)
                                  }
                                />
                              </div>
                              {/* <div
                                className="form-group mt-2"
                                style={{ margin: "0px 10px" }}
                              >
                                <Button onClick={addbenefits}>Add+</Button>
                              </div> */}
                            </div>
                          </div>
                        </div>

                        {/* ++++++++++++++++++++ */}
                        <div className="deposite pt-2">
                          <div>
                            <h6>
                              If there any joining fee or deposit required
                              from the candidate ?
                            </h6>
                          </div>
                          <div className="deposite_hero ">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                checked={reason != "" ? true : false}
                                label="Yes"
                                onClick={() => {
                                  setyesdepo(true);
                                  setnodepo(false);
                                }}
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                checked={reason == "" ? true : false}
                                label="No"
                                onClick={() => {
                                  setyesdepo(false);
                                  setnodepo(true);
                                }}
                              />
                            </RadioGroup>

                            <div className="yes_hero">
                              {yesdepo ? (
                                <>
                                  <div className="d-flex aling-items-center">
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <h6>Fee Amount*</h6>
                                      <TextField
                                        id="outlined-basic"
                                        // label="Fee Amount*"
                                        placeholder={item.fee}
                                        variant="outlined"
                                        value={fee}
                                        onChange={(e) =>
                                          setfee(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <h6>Enter the reason*</h6>
                                      <TextField
                                        id="outlined-basic"
                                        // label="Enter the reason*"
                                        variant="outlined"
                                        placeholder={item.reason}
                                        value={reason}
                                        onChange={(e) =>
                                          setreason(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    

                    
                    <Button
                      color="inherit"
                      onClick={handleSkip}
                      sx={{ mr: 1 }}
                    >
                      Skip
                    </Button>
                 
                    <Box sx={{ flex: "1 1 auto" }} />
                    
                      <Button onClick={handleNext} className="mb-2">
                        Continue
                      </Button>
                      </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                   
                    </div>
                    
                  ) : // step IInd step
                  activeStep === 1 ? (
                    <div className="candidate-Hero">
                      <div className="candidate-details pt-2">
                        <h4>Candidate Requirements</h4>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                            <h6>Education</h6>
                              <FormControl fullWidth>
                               
                                <InputLabel id="demo-simple-select-label">
                                {item.typeofeducation}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={typeofeducation}
                                  // label="Type of jobs"
                                 
                                  onChange={handleChange4}
                                >
                                  <MenuItem value="10th or Below 10th">
                                    10th or Below 10th
                                  </MenuItem>
                                  <MenuItem value="12th pass">12th pass</MenuItem>
                                  <MenuItem value="Degree/Diploma">Degree/Diploma</MenuItem>
                                  <MenuItem value="Graduate">Graduate</MenuItem>
                                  <MenuItem value="Post Graduate">
                                    Post Graduate
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <FormControl fullWidth>
                               <h6>Qualification</h6>
                                <TextField
                                    id="outlined-basic"
                                    // label="Qualification"
                                    variant="outlined"
                                    placeholder={item.typeofqualification}
                                    value={typeofqualification}
                                    onChange={(e) => settypeofqualification(e.target.value)}
                                  />
                              </FormControl>
                            </div>
                          </div>
                        </div> 
                        {/* Gender */}

                        <div className="gendr_hero">
                          <div className="hero_hj">
                            <FormControl>
                              <h4>Gender</h4>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={gendertype}
                                onChange={handleChange5}
                              >
                                <FormControlLabel
                                  value="Female"
                                  control={<Radio />}
                                  checked={gendertype ? gendertype === "Female": item.gendertype==="Female"}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="Male"
                                  checked={gendertype ? gendertype === "Male": item.gendertype==="Male"}
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="Both"
                                  checked={gendertype ? gendertype === "Both": item.gendertype==="Both"}
                                  control={<Radio />}
                                  label="Both"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>

                        {/* Experience */}
                        <div className="gendr_hero">
                          <div className="hero_hj">
                            <FormControl>
                              <h4>Experience Required</h4>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={experiencerequired}
                                onChange={handleChange6}
                              >
                                <FormControlLabel
                                  value="Fresher"
                                  control={<Radio />}
                                  label="Fresher Only"
                                  checked={experiencerequired ? experiencerequired === "Fresher": item.experiencerequired==="Fresher"}
                                 onClick={()=>setexperienced(false)}
                                />
                                <FormControlLabel
                                  value="Experience"
                                  control={<Radio />}
                                  label="Experience"
                                  checked={experiencerequired ? experiencerequired === "Experience": item.experiencerequired==="Experience"}
                                  onClick={() => {
                                    setexperienced(!experienced);
                                  }}
                                />
                                <FormControlLabel
                                  value="Any"
                                  control={<Radio />}
                                  label="Any"
                                  checked={experiencerequired ? experiencerequired === "Any": item.experiencerequired==="Any"}
                                  onClick={()=>setexperienced(false)}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {experienced ? (
                                <>
                                  <div className="form-group mt-2">
                                    <h6>Experience</h6>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        {item.experience}
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={experience}
                                        label="Experience"
                                        onChange={(e) =>
                                          setExperience(e.target.value)
                                        }
                                      >
                                        <MenuItem value="0-1 year">
                                          0-1 year
                                          </MenuItem>
                                          <MenuItem value="1-3 year">
                                            1-3 year
                                          </MenuItem>
                                          <MenuItem value="3-7 year">
                                            3-7 year
                                          </MenuItem>
                                          <MenuItem value="7 year Above">
                                            7 year Above
                                          </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* English Level  */}
                        <div className="gendr_hero">
                          <div className="hero_hj">
                            <FormControl>
                              <h4>English Level Preference</h4>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={english}
                                onChange={handleChange7}
                              >
                                <FormControlLabel
                                  value="NO English"
                                  control={<Radio />}
                                  checked={english ? english === "NO English": item.english==="NO English"}
                                  label="No English"
                                />
                                <FormControlLabel
                                  value="Basic English"
                                  control={<Radio />}
                                  checked={english ? english === "Basic English": item.english==="Basic English"}
                                  label="Basic English "
                                />
                                <FormControlLabel
                                  value="Intermediate English"
                                  control={<Radio />}
                                  checked={english ? english === "Intermediate English": item.english==="Intermediate English"}
                                  label="Intermediate English"
                                />
                                <FormControlLabel
                                  value="Advance English"
                                  control={<Radio />}
                                  checked={english ? english === "Advance English": item.english==="Advance English"}
                                  label="Advance English"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>

                        {/* Skill required */}
                        <div className="add_j">
                          <div className="add-jkl mt-2">
                            <h4>Skill required for this job</h4>
                            <div className="d-flex align-items-center">
                              <div
                                className="form-group mt-2"
                                style={{ margin: "0px 10px" }}
                              >
                                <h6>Add skill *</h6>
                                <TextField
                                  id="outlined-basic"
                                  // label="Add skill *"
                                  variant="outlined"
                                  placeholder={item.skill}
                                  value={skill}
                                  onChange={(e) => setSkill(e.target.value)}
                                />
                              </div>
                              {/* <div
                                className="form-group mt-2"
                                style={{ margin: "0px 10px" }}
                              >
                                <Button onClick={addskill}>Add+</Button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* jobdis */}
                        <div className="jo_dis pt-2">
                          <div className="">
                            <h4>Job Description</h4>
                            <div className="mb-2">
                              Describe the responsbilities of this job and
                              Other specific requirements here
                            </div>
                          </div>
                          <div className="mult-additional">
                            {/* <TextField
                              id="outlined-multiline-static"
                              label="Job Description "
                              multiline
                              value={description}
                              onChange={(e) => setdescription(e.target.value)}
                              rows={4}
                              defaultValue="Job Description"
                            /> */}
                              <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={handleChange10}
            />
                          </div>
                          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      className="mb-2"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {/* {isStepOptional(activeStep) && (
                    <Button
                      color="inherit"
                      onClick={handleSkip}
                      sx={{ mr: 1 }}
                    >
                      Skip
                    </Button>
                  )} */}

                    
                      <Button onClick={handleNext1} className="mb-2">
                        Continue
                      </Button>
                      </Box>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // 3rd step
                    <div className="init_0">
                      <div className="interViewer_0 pt-2">
                        <h4>Interviewer Details</h4>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <h6>Interviewer Name</h6>
                              <TextField
                                id="outlined-basic"
                                // label="Interviewer Name"
                                placeholder={item.interviewername}
                                variant="outlined"
                                value={interviewername}
                                onChange={(e) =>
                                  setinterviewername(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <h6>Whatsapp No</h6>
                              <TextField
                                id="outlined-basic"
                                // label="Whatsapp No"
                                placeholder={item.whatsapp}
                                variant="outlined"
                                value={whatsapp}
                                onChange={(e) => setwhatsapp(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                            <h6>Email Id</h6>
                              <TextField
                                id="outlined-basic"
                                // label="Email Id"
                                placeholder={item.email}
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                            <h6>Schedule</h6>
                              <TextField
                                id="outlined-textarea"
                                // label="Call us Eg : 10am to 6pm"
                                variant="outlined"
                                placeholder={item.time}
                                multiline
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="interViewer_0 pt-2">
                          <h4>Type of Interview</h4>
                          <div className="hero_hj">
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={interview}
                                onChange={handleChange8}
                              >
                                <FormControlLabel
                                  value="offline"
                                  control={<Radio />}
                                  label="Offline Interview"
                                  checked={interview ? interview === "offline": item.interview==="offline"}
                                  onClick={() => {
                                    setoffline(true);
                                    setonline(false);
                                  }}
                                />
                                <FormControlLabel
                                  value="online"
                                  control={<Radio />}
                                  checked={interview ? interview === "online": item.interview==="online"}
                                  label="Telephonic / Online Interview"
                                  onClick={() => {
                                    setoffline(false);
                                    setonline(true);
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {offline ? (
                            <>
                              <div className="mult-additional_0">
                                <h6>Interviewer Address </h6>
                                <TextField
                                  id="outlined-multiline-static"
                                  // label="Interviewer Address "
                                  multiline
                                  rows={4}
                                  placeholder={item.address}
                                  value={address}
                                  onChange={(e) => setaddress(e.target.value)}
                                  defaultValue="Enter Complete Address"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                                 <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      className="mb-2"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {/* {isStepOptional(activeStep) && (
                    <Button
                      color="inherit"
                      onClick={handleSkip}
                      sx={{ mr: 1 }}
                    >
                      Skip
                    </Button>
                  )} */}

                    
<Button onClick={addjob} className="mb-2">
                        Finish
                      </Button>
                      </Box>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* // ) : (
                //   <input placeholder="test4"></input>
                // ) */}
                  {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      className="mb-2"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                  

                    {activeStep === 0 && activeStep === 1 ? (
                      <Button onClick={handleNext} className="mb-2">
                        "Continue"
                      </Button>
                    ) : (
                      ""
                    )}
                    {!activeStep === 0 && !activeStep === 1 ? (
                      <Button onClick={addjob} className="mb-2">
                        Finish
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box> */}
                </React.Fragment>
              )}
            </Box>
          </div>
        </div>
      </div>
   
  
);
}

export default CompanyDetailsEdit1;
