import React, { useEffect, useState } from "react";
import Wcs from "./Wcs";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import exportFromJSON from "export-from-json";
import ReactPaginate from "react-paginate";
function Myjob() {
  const [unVerify, setUnVerify] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  

  const [singleselected, setsingleselect] = useState([]);
  let check = JSON.parse(sessionStorage.getItem("employer"));

  const getVerifiedList = async () => {
    let res = await axios.get(
      "https://univ.mobi/api/user/getJobByEmployerId/" + check?._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
    }
  };
  const removeJob = async (id) => {
    let res = await axios.delete("https://univ.mobi/api/user/deleteJob/" + id);
    if (res.status === 200) {
      alert(res.data.success);
      getVerifiedList();
    }
  };
  const makeBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockCompany",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: true },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("Successfully blocked");
        window.location.reload();
        getVerifiedList();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const makeUnBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockCompany",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: false },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("Successfully unblocked");
        window.location.reload();
        getVerifiedList();
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const loadUsers = async () => {
    const config = {
      url: "/user/getEmployerById/" + check?._id,
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        sessionStorage.removeItem("employer");
        sessionStorage.setItem("employer", JSON.stringify(result.data.success));
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  useEffect(() => {
    getVerifiedList();
    loadUsers();
  }, []);

  const data = [];
  if (unVerify?.length !== 0) {
    for (let i = 0; i < unVerify.length; i++) {
      if (unVerify[i]?.isVerify === true) {
        data.push({
          JobID: unVerify[i]?._id.slice(18,24),
          CompanyName: unVerify[i]?.companyName,
          ContactPersonName: unVerify[i]?.interviewername,
          Email: unVerify[i]?.email,
          Contact: unVerify[i]?.whatsapp,
          Category: unVerify[i]?.category,
          JobProfile: unVerify[i]?.jobProfile,
          PostedOn: moment(unVerify[i]?.updatedAt).fromNow(),
        });
      }
    }
  }

  const exportType = "xls";
  const [fileName, setfileName] = useState("Approved Jobs");

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  //console.log("hdsjfkjdsh", unVerify);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    unVerify.filter((ele) => ele.isVerify === true).length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  function Blockbtn() {

    for(let i =0 ;i <singleselected.length ; i++){
    
  makeBlock(singleselected[i])
  
  
    }
  }
  function Unblockbtn() {
  
    for(let i =0 ;i <singleselected.length ; i++){
    
  makeUnBlock(singleselected[i])
    }
  }
  const handleCheckboxSelect = (event, data) => {
    if (event.target.checked) {
      setsingleselect([...singleselected, data]);
      
    
    // setshowMoreId(singleselected?.includes(data),"true/false")
      console.log(singleselected,"jjjj");
    } else {
      setsingleselect(singleselected?.filter(id => id !== data));
      

    }
  };
 console.log(singleselected,"fdfdfdfdfd");

  const handleSelectAll = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected, unVerify.filter((ele) => ele.isVerify === true).map(item => item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
 
  return (
    <>
      {check?.status !== "Approved" ? (
        <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          <br />
          <h3>Admin Not approved your profile please update.</h3>{" "}
          {check?.reasion ? <p>{check?.reasion}</p> : <></>}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            <div
              className="container img"
              style={{
                border: "1px solid #24496a",
                borderRadius: "10px",
                marginBottom: "10px",
                boxShadow: " rgb(0 0 0 / 16%) 0px 1px 4px",
                border: " 1px solid rgb(36 73 106 / 21%)",
                width: "100%",
              }}
            >
              <div class="container">
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>My Approved Jobs</b>
                  </h1>
                  {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
                </div>
                <p align="right">
                  {/* <Button
                  variant="primary"
                  type="button"
                  value="ShortList"
                  href="/addjob"
                  style={{
                    backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none",
                  }}
                >
                  <b style={{ color: "white"}}>+Add</b>
                </Button> */}
                </p>

                <div className="tble">
                <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll}   type="checkbox"  style={{marginLeft:"20px"}}/>
          
                <Button class="btn btn-outline-primary" onClick={Blockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Block{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Unblockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Unblock{" "}
                 
                </Button>
           
                
           </div> 
                  <table style={{ width: "100%", marginBottom: "20px" ,zIndex:'auto'}}>
                    <tr>
                      <th>Job ID</th>
                      <th>Company Name</th>
                      <th>Job Profile</th>
                      {/* <th>Contact</th> */}
                      <th>Posted On</th>
                      {/* <th>View</th> */}
                      <th>Action</th>
                    </tr>
                    {unVerify
                      ?.filter((ele) => ele.isVerify === true).slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((item) => (
                        <tr>
                     <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>

                          <td> {item._id.slice(18, 24)}</td>
                          <td>
                            <a name="name" id="name">
                              {item?.companyName}
                            </a>
                          </td>
                          <td>
                            <a>{item?.jobProfile}</a>
                          </td>
                          {/* <td>
                    <a >{item?.contact}</a>
                  </td> */}
                          <td> {moment(item?.updatedAt).fromNow()}</td>
                          {/* <td>
                    {" "}
                    <Link to={`/employeecompanydetails/${item?._id}`}>
                    <Button variant="success" style={{ color: "white" }}>
                      View
                    </Button>{" "}</Link>
                  </td> */}
                          {/* <td>
                    {" "}
                    <Button variant="danger" style={{ color: "white" }} onClick={()=>removeJob(item?._id)}>
                      Delete
                    </Button>{" "}
                  </td> */}
                          <td>
                            <Link to={`/employeecompanydetails/${item?._id}`}>
                              <Button variant="outline-primary">View</Button>{" "}
                            </Link>
                            {item?.isBlock === false ? (
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={() => makeBlock(item?._id)}
                              >
                                Block
                              </button>
                            ) : (
                              <button
                                type="button"
                                class="btn btn-warning"
                                onClick={() => makeUnBlock(item?._id)}
                              >
                                Unblock
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </table>
                  <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
                </div>
              </div>
            </div>
            <p align="right" style={{ marginBottom: "-1em" }}>
              <Button
                variant="primary"
                type="button"
                value="ShortList"
                onClick={ExportToExcel}
                style={{
                  backgroundColor: "rgb(5, 38, 78)",
                  color: "white",
                  border: "none",
                }}
              >
                <b style={{ color: "white" }}>Export to Excel</b>
              </Button>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default Myjob;
