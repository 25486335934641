import React from "react";
import "./Mp.css";
// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

function Wcs1() {
  const [unVerify, setUnVerify] = useState([]);

  const getVerifiedList = async () => {
    let res = await axios.get("https://univ.mobi/api/user/getAllJobs");
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
    }
  };
  const [UnvarifiedList, setUnvarifiedList] = useState([]);

  const getJobList = async () => {
    let res = await axios.get("https://univ.mobi/api/user/getUnvarifiedList");
    if (res.status === 200) {
      //console.log(res.data);
      setUnvarifiedList(res.data.success);
    }
  };

  const [AppliedDetails, setAppliedDetails] = useState([]);

  const getAppliedDetails = async (id) => {
    let res = await axios.get("https://univ.mobi/api/user/AllAplliedDetals");
    if (res.status === 200) {
      //console.log(res.data);
      setAppliedDetails(res.data.success);
    }
  };
  const [allUser, setAllUser] = useState([]);
  const userData = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/user/getAllProfile");
      if (res.status === 200) {
        //console.log(res.data);
        setAllUser(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [allEmp, setAllEmp] = useState([]);
  const empData = async () => {
    try {
      let res = await axios.get(
        "https://univ.mobi/api/user/getJobByEmployerId/" + employer?._id
      );
      if (res.status === 200) {
        setAllEmp(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  // getAllProfileEmployer
  useEffect(() => {
    getVerifiedList();
    getJobList();
    getAppliedDetails();
    userData();
    empData();
  }, []);
  const employer = JSON.parse(sessionStorage.getItem("employer"));

  return (
    <>
      <div className="container">
        <div className="row" style={{ marginTop: "-17px" }}>
          <div className="col-md-2">
            {/* <Link to="/adminuser"> */}
            <MDBCard style={{ maxWidth: "540px", height: "70%" }}>
              <MDBRow className="g-0">
                {/* <MDBCol md="4">
                    <MDBCardImage src="user.png" alt="..." fluid />
                  </MDBCol> */}
<Link to="/unverifed-employer-job">
                <MDBCol md="8" >
                  {/* <MDBCardBody> */}
                  <MDBCardTitle>Posted Jobs</MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ marginLeft: "48px" }}> {allEmp?.length}</h4>
                  </MDBCardText>
                  {/* </MDBCardBody> */}
                </MDBCol>
                </Link>
                {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
              </MDBRow>
            </MDBCard>
            {/* </Link> */}
          </div>
          
          <div className="col-md-2">
          <Link to="/myjob">
            {/* <Link to="/adminemployee"> */}
            <MDBCard style={{ maxWidth: "540px", height: "70%" }}>
              <MDBRow className="g-0">
                {/* <MDBCol md="4">
                    <MDBCardImage src="employer.png" alt="..." fluid />
                  </MDBCol> */}
                <MDBCol md="8">
                  {" "}
                  {/* <MDBCardBody> */}
                  <MDBCardTitle>Approved Jobs</MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ marginLeft: "48px" }}>
                      {" "}
                      {
                        unVerify.filter(
                          (ele) => ele?.employerId?._id === employer?._id
                        )?.length
                      }
                    </h4>
                  </MDBCardText>
                  {/* </MDBCardBody> */}
                </MDBCol>

                {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
              </MDBRow>
            </MDBCard>
            </Link>  
            
          </div>
        
          <div className="col-md-2">
          <Link to="/applieddetails">
            {" "}
            {/* <Link > */}
            <MDBCard style={{ maxWidth: "540px", height: "70%" }}>
              <MDBRow className="g-0">
                {/* <MDBCol md="4">
                    <MDBCardImage src="e1.png" alt="..." fluid />
                  </MDBCol> */}
                <MDBCol md="8">
                  {" "}
                  {/* <MDBCardBody> */}
                  <MDBCardTitle>Applied Jobs</MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ marginLeft: "48px" }}>
                      {
                        AppliedDetails?.filter(
                          (ele) =>
                            ele?.companyId?.employerId ===
                            employer?._id?.toString()
                        )?.filter((el) => el?.status === "Applied")?.length
                      }
                    </h4>
                  </MDBCardText>
                  {/* </MDBCardBody> */}
                </MDBCol>

                {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
              </MDBRow>
            </MDBCard>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/shortlisted">
            <MDBCard style={{ maxWidth: "540px", height: "70%" }}>
              <MDBRow className="g-0">
                {/* <MDBCol md="4">
                    <MDBCardImage src="e3.png" alt="..." fluid />
                  </MDBCol> */}
                <MDBCol md="12">
                  {/* <MDBCardBody> */}
                  <MDBCardTitle>Shortlisted Employees</MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ marginLeft: "48px" }}>
                      {" "}
                      {
                        AppliedDetails?.filter(
                          (ele) =>
                            ele?.companyId?.employerId ===
                            employer?._id?.toString()
                        )?.filter((el) => el?.status === "Shortlisted")?.length
                      }
                    </h4>
                  </MDBCardText>
                  {/* </MDBCardBody> */}
                </MDBCol>

                {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
              </MDBRow>
            </MDBCard>
            </Link>
          </div>

          <div className="col-md-2">
            <Link to="/shortlisted">
            <MDBCard style={{ maxWidth: "540px", height: "70%" }}>
              <MDBRow className="g-0">
                {/* <MDBCol md="4">
                    <MDBCardImage src="e4.png" alt="..." fluid />
                  </MDBCol> */}
                <MDBCol md="12">
                  {" "}
                  {/* <MDBCardBody> */}
                  <MDBCardTitle>Selected Employees</MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ marginLeft: "48px" }}>
                      {" "}
                      {
                        AppliedDetails?.filter(
                          (ele) =>
                            ele?.companyId?.employerId ===
                            employer?._id?.toString()
                        )?.filter((el) => el?.status === "Selected")?.length
                      }
                    </h4>
                  </MDBCardText>
                  {/* </MDBCardBody> */}
                </MDBCol>

                {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
              </MDBRow>
            </MDBCard>
            </Link>
          </div>
        </div>
      </div>
      {/* card */}
      {/* </div> */}
    </>
  );
}

export default Wcs1;
