import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// 
import "./MajorProduct.scoped.scss";
// import { Modal } from 'antd';


// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";

function OurClient() {
  const formdata = new FormData();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [category, setCategory] = useState("");
  const [alldata,setAlldata]=useState([]);

  const getAllcategory=async ()=>{
    try {
      let res =await axios.get("https://univ.mobi/api/admin/getAllOurClient")
      if(res.status===200){
        setAlldata(res.data.success)
      }
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(()=>{
    getAllcategory();
  },[])
  // //console.log("swagat to karo hmara",alldata)
  const addCategory = async (e) => {
    e.preventDefault();
    if (category) {
      formdata.append("imageLogo",category)
      try {
        const config = {
          url: "/addOurClient",
          method: "post",
          baseURL: "https://univ.mobi/api/admin",
          headers: { "content-type": "multipart/form-data" },
          data: formdata,
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert(res.data.success);
            handleClose();
            setIsModalOpen(false);
            setCategory("");
            getAllcategory();

          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
    } else {
      alert("Please enter compony logo!");
    }
  };
  const deleteCategory = async (id) => {
      try {
        const config = {
          url: "/deleteOurClients/"+id,
          method: "delete",
          baseURL: "https://univ.mobi/api/admin",
          headers: { "content-type": "application/json" }
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert(res.data.success);
            getAllcategory();

          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
  };
  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    addCategory();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    alldata.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      {" "}
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            <div
              className="container img"
              style={{
                border: "1px solid rgba(36, 73, 106, 0.21)",
                borderRadius:" 10px",
                marginBottom: "10px",
                boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                width: "100%",
              }}
            >
              <div class="container">
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Our Clients</b>
                  </h1>
                </div>
              </div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <Button variant="primary" onClick={showModal}>
                  Add +
                </Button>

                {/* <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Our Client Logo</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label htmlFor="upload1">Company Logo</Form.Label>
                      <Form.Control
                        type="file"
                        id="upload1"
                        name="file"
                      accept="image/*"
                        onChange={(e) => setCategory(e.target.files[0])}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={addCategory}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal> */}
              </div>
              <br />
              <Table striped>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Company logo</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.slice(pagesVisited, pagesVisited + usersPerPage).map((item,index)=>{
                    return (
                      <tr>
                      <td>{index+1}</td>
                      {/* <td>{item?.imageLogo}</td> */}
                      <td> <img src={`https://univ.mobi/ourclient/${item?.imageLogo}`} alt="inage"  style={{width:"50%",height:"100px"}} /></td>
                      <td><AiOutlineDelete   style={{color:"red",cursor:"pointer"}} onClick={()=>deleteCategory(item?._id)}/></td>
                    </tr>
                    )
                  })}
                 
                </tbody>
              </Table>
              <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            </div>
          </div>
        </div>
        <div style={{marginBottom:90}}></div>
      </div>

      {/* Modal */}
      
      <Modal show={isModalOpen} onHide={handleCancel} >
                  <Modal.Header closeButton style={{backgroundColor:"#165c49"}}>
                    <Modal.Title style={{color:"white"}}>Our Client</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{backgroundColor:"#165c49"}}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label htmlFor="upload1" style={{color:"white"}}>Company Logo</Form.Label>
                      <Form.Control
                        type="file"
                        id="upload1"
                        name="file"
                      accept="image/*"
                        onChange={(e) => setCategory(e.target.files[0])}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    </Modal.Body>
                    <Modal.Footer style={{backgroundColor:"#165c49"}}>
                    <Button variant="secondary" onClick={handleCancel}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={addCategory}>
                      Submit
                    </Button>
                  </Modal.Footer>
      </Modal>
    </>
  )
}

export default OurClient;