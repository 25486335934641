import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { saveAs } from "file-saver";
// import { Modal } from "antd";
import exportFromJSON from "export-from-json";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { BiUserCircle } from "react-icons/bi";
import moment from "moment";
import ReactPaginate from "react-paginate";

function Applieddetails1() {
 const check = JSON.parse(sessionStorage.getItem("subadmin"));
 const [Selectall, setSelectall] = useState(false);
  

  const [singleselected, setsingleselect] = useState([]);
 
  const [AppliedDetails, setAppliedDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const saveFile = (item, name) => {
    saveAs(`https://univ.mobi/user/${item}`, `${name}Resume.pdf`);
  };
  const getAppliedDetails = async (id) => {
    let res = await axios.get("https://univ.mobi/api/user/AllAplliedDetals");
    if (res.status === 200) {
      //console.log(res.data);
      setAppliedDetails(res.data.success);
    }
  };
  const [user, setuser] = useState("");
  const [com, setcom] = useState("");
  const handleCheckboxSelect = (event, data) => {
    if (event.target.checked) {
      setsingleselect([...singleselected, data]);
      
    
    // setshowMoreId(singleselected?.includes(data),"true/false")
      console.log(singleselected,"jjjj");
    } else {
      setsingleselect(singleselected?.filter(id => id !== data));
      

    }
  };
 console.log(singleselected,"fdfdfdfdfd");

  const handleSelectAll = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected, AppliedDetails?.map(item => item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
 
  const showModal = (userId, companyId) => {
    setuser(userId);
    setcom(companyId);
    setIsModalOpen(true);
  };
  const [reasion, setreasion] = useState("");
  const holdEmployer = async () => {
   
    const config = {
      url: "/user/callinterview",
      method: "post",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
      data: {
        schedule: reasion,
        userId: user._id,
        name: user.name,
        email: user.email,
        employerId: check._id,
        companyId: com,
      },
    };
    if(!reasion) {
      alert("Please Enter the schedule")
    } else {
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setIsModalOpen(false);
        alert(result.data.success);
        getAppliedDetails();
        getJobList();
        setreasion("");
      } else {
        alert("Something went worng");
      }
    
    } catch (err) {
      //console.log(err.message);
    }
  }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    holdEmployer();
  };
  const addShortList = async (userId, companyId) => {
    try {
      const config = {
        url: "/addShortList",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: {
          userId: userId,
          companyId: companyId,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAppliedDetails();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  const addselect = async (userId, companyId) => {
    try {
      const config = {
        url: "/addSelect",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: {
          userId: userId,
          companyId: companyId,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAppliedDetails();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };

  const rejectApply = async (userId, companyId) => {
    try {
      const config = {
        url: "/rejectApply",
        method: "post",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
        data: {
          userId: userId,
          companyId: companyId,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAppliedDetails();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  const deleteApply = async (Id) => {
    try {
      const config = {
        url: "/deleteApply/" + Id,
        method: "delete",
        baseURL: "https://univ.mobi/api/user",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          // alert(res.data.success);
          window.location.reload();
          getAppliedDetails();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAppliedDetails();
    getJobList();
  }, []);
  const data = [];
  if (AppliedDetails?.length !== 0) {
    for (let i = 0; i < AppliedDetails.length; i++) {
      data.push({
         CompanyName: AppliedDetails[i]?.companyId?._id.slice(18,24),
        CompanyName: AppliedDetails[i]?.companyId?.companyName,
        CompanyName: AppliedDetails[i]?.companyId?.interviewername,

        CompanyNumber: AppliedDetails[i]?.companyId?.whatsapp,
        EmployeeName: AppliedDetails[i]?.userId?.name,
        EmployeeNumber: AppliedDetails[i]?.userId?.mobile,
        Status: AppliedDetails[i]?.status,
        AppliedOn: moment(AppliedDetails[i]?.updatedAt).fromNow(),
      });
    }
  }

  const exportType = "xls";
  const [fileName, setfileName] = useState("AppliedDetails");
  const [users, setusers] = useState([]);
  const getJobList = async () => {
    let res = await axios.get(
      "https://univ.mobi/api/user/getcallinterview/" + check._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setusers(res.data.success);
    }
  };
  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  const sched = users.filter(
    (data) =>
      data.userId === user._id &&
      data.employerId === check._id &&
      data.companyId === com
  );
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    AppliedDetails.length /usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  function Deletebtn() {

    for(let i =0 ;i <singleselected.length ; i++){
    
      deleteApply(singleselected[i])
    }
  }
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}
          <div
            className="container"
            style={{
              border: "1px solid rgba(36, 73, 106, 0.21)",
              borderRadius: " 10px",
              marginBottom: "10px",
              boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Applied Details</b>
                </h1>
                {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
              </div>
            </div>
           
              <p align="right" >
              <Button
                variant="primary"
                type="button"
                value="ShortList"
                onClick={ExportToExcel}
                style={{
                  backgroundColor: "rgb(5, 38, 78)",
                  color: "white",
                  border: "none",
                }}
              >
                <b style={{ color: "white" }}>Export to Excel</b>
              </Button>
            </p>
            
            <div className="tble">
              <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll}   type="checkbox"  style={{marginLeft:"20px"}}/>
          
                
           
                   
            <Button class="btn btn-outline-primary" onClick={Deletebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Delete All{" "}
                 
                </Button>  
           </div>
              <table style={{ width: "100%", marginBottom: "20px",zIndex:"auto" }}>
                <tr>
                  <th></th>
                  <th>Job ID</th>
                  <th>Company Name</th>
                  {/* <th>Company Number</th> */}
                  <th>Employees Name</th>
                  <th>Employees Number</th>
                  <th>Resume</th>
                  <th>Status</th>
                  <th>Action</th>

                  <th>Applied on</th>
                  <th>Delete</th>
                </tr>

                {AppliedDetails.slice(pagesVisited, pagesVisited + usersPerPage).map((item) => (
                  <tr>
                     <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>

                    <td>
                      {/* <a href={`/show/${item?.companyId?._id}`}>
                        {item?.companyId?.companyName}
                      </a> */}
                      <Link
                        to={`/admincompanyDetails3/${item?.companyId?._id}`}
                      >
                        {item?.companyId?._id.slice(18, 24)}
                      </Link>
                    </td>
                    <td>
                      {/* <a href={`/show/${item?.companyId?._id}`}>
                        {item?.companyId?.companyName}
                      </a> */}
                      <Link
                        to={`/admincompanyDetails3/${item?.companyId?._id}`}
                      >
                        {item?.companyId?.companyName}
                      </Link>
                    </td>
                    {/* <td>  <Link to={`/admincompanyDetails/${item?.companyId?._id}`}>
                        {item?.companyId?.contact}
                      </Link></td> */}
                    <td>
                      {item?.userId?.profile ? (
                        <img
                          src={`https://univ.mobi/user/${item?.userId?.profile}`}
                          width="30"
                          height="30"
                          className="rounded-circle"
                          alt="React Bootstrap logo"
                          style={{ marginRight: "10px" }}
                        />
                      ) : (
                        <BiUserCircle
                          style={{
                            margin: "0px 10px",
                            fontSize: "22px",
                            color: "#24496a",
                          }}
                        />
                      )}

                      <Link to={`/adminuserDetailsView/${item?.userId?._id}`}>
                        {item?.userId?.name}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      <Link to={`/adminuserDetailsView/${item?.userId?._id}`}>
                        {item?.userId?.mobile}
                      </Link>
                    </td>
                    <td>
                      {item?.userId?.resume ? (
                        <a
                          onClick={() =>
                            saveFile(item?.userId?.resume, item?.userId?.name)
                          }
                        >
                          {" "}
                          <img
                            src="./pngwing.com.png"
                            width="30"
                            height="30"
                            className="s"
                            style={{ marginRight: "8px", cursor: "pointer" }}
                            alt="React Bootstrap logo"
                          />
                        </a>
                      ) : (
                        <RxCross2
                          style={{ color: "red", fontSize: "20px" }}
                        ></RxCross2>
                      )}
                    </td>
                    {item?.companyId?.adminId ? (
                      <td>
                        {item?.status === "Applied" ? (
                          <div>
                            {" "}
                            <button
                              variant="primary"
                              style={{
                                color: "white",
                                backgroundColor: "black",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                addShortList(
                                  item?.userId?._id,
                                  item?.companyId?._id
                                )
                              }
                            >
                              ShortList
                            </button>
                            <button
                              variant="danger"
                              style={{
                                color: "white",
                                marginLeft: "20px",
                                backgroundColor: "red",
                                borderRadius: "5px",
                                borderColor:"red",
                              }}
                              onClick={() =>
                                rejectApply(
                                  item?.userId?._id,
                                  item?.companyId?._id
                                )
                              }
                            >
                              Reject
                            </button>
                          </div>
                        ) : (
                          item?.status
                        )}
                      </td>
                    ) : (
                      <td>{item?.status}</td>
                    )}
                    <td>
                      {item?.companyId?.adminId &&
                      item?.status !== "Rejected" &&
                      item?.status === "Shortlisted" ? (
                        <div style={{ flexDirection: "column" }}>
                          {" "}
                          <Link
                            onClick={() =>
                              showModal(item?.userId, item?.companyId?._id)
                            }
                            class="btn btn-outline-primary"
                          >
                            Call For an Interview
                          </Link>
                          <div style={{ marginTop: "20px" }}>
                            {" "}
                            <button
                              onClick={() =>
                                addselect(
                                  item?.userId?._id,
                                  item?.companyId?._id
                                )
                              }
                              style={{
                                color: "white",
                                backgroundColor: "black",
                                borderRadius: "5px",
                              }}
                            >
                              Select
                            </button>
                            <button
                              onClick={() =>
                                rejectApply(
                                  item?.userId?._id,
                                  item?.companyId?._id
                                )
                              }
                              variant="danger"
                              style={{
                                color: "white",
                                marginLeft: "20px",
                                backgroundColor: "red",
                                borderRadius: "5px",
                                borderColor:"red",
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td> {moment(item?.createdAt).fromNow()}</td>
                    <td>
                      {" "}
                      <Button
                        onClick={() => deleteApply(item?._id)}
                        variant="danger"
                        style={{ marginLeft: "10px" }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              {/* <p align="right" style={{ marginBottom: "2em" }}>
              <Button
                variant="primary"
                type="button"
                value="ShortList"
                onClick={ExportToExcel}
                style={{
                  backgroundColor: "rgb(5, 38, 78)",
                  color: "white",
                  border: "none",
                }}
              >
                <b style={{ color: "white" }}>Export to Excel</b>
              </Button>
            </p> */}
            </div>
          </div>
        </div>
        {/* <Modal
          title="Schedule"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            {!sched[0]?.schedule ? (
              <Form.Control
                as="textarea"
                type="text"
                rows={3}
                placeholder="Enter Interview date ,time and address "
                value={reasion}
                onChange={(e) => setreasion(e.target.value)}
              />
            ) : (
              sched[0]?.schedule
            )}
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Modal> */}
        <Modal show={isModalOpen} onHide={handleCancel} >
                  <Modal.Header closeButton style={{backgroundColor:"#165c49"}}>
                    <Modal.Title style={{color:"white"}}>Schedule</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{backgroundColor:"#165c49"}}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                     
                    {!sched[0]?.schedule ? (  <Form.Control
                        type="text"
                        placeholder="Enter Interview date ,time and address "
                        value={reasion}
                onChange={(e) => setreasion(e.target.value)}
                      /> ) : (
                       <span style={{color:"white",fontSize:"20px"}}> {sched[0]?.schedule}</span>
                      )}
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer style={{backgroundColor:"#165c49"}}>
                    <Button variant="secondary" onClick={handleCancel}>
                      Close
                    </Button>
                    {!sched[0]?.schedule ?  <Button variant="primary" onClick={holdEmployer}>
                      Submit
                    </Button>:""}
                  </Modal.Footer>
                </Modal>
      </div>
    </>
  );
}

export default Applieddetails1;

