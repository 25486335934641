import React, { useEffect, useState } from "react";
import Header from "./Header";
import OwlCarousel from "react-owl-carousel";
import Whatsapp from "./Whatsapp";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import { BsStopwatch } from "react-icons/bs";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Client from "./Client";
import Carousel from "react-bootstrap/Carousel";
import { RxDoubleArrowRight } from "react-icons/rx";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ReactPaginate from "react-paginate";
function Home1() {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  let check = JSON.parse(sessionStorage.getItem("user"));
  const [user, setUser] = useState([]);
  let history = useNavigate();
  let check1 = JSON.parse(sessionStorage.getItem("employer"));
  const apply = () => {
    if (!check1) {
      alert("Please login.");
      history("/employers-login");
    }
  };
  const apply1 = (id) => {
    if (!check) {
      alert("Please login.");
      history("/employee-login");
    } else {
      window.location.assign(`/job-profile-details/${id}`);
    }
  };
  const [alldata, setAlldata] = useState([]);

  const getAllcategory = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/admin/getAllCategory");
      if (res.status === 200) {
        setAlldata(res?.data?.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [alldata1, setAlldata1] = useState([]);

  const getAllcategory1 = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/admin/getbusiness");
      if (res.status === 200) {
        setAlldata1(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [homeData, sethomeData] = useState([]);

  const getAllhome = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/admin/getGraph");
      if (res.status === 200) {
        sethomeData(res?.data?.success);
        getAllcategory();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [Employyes, setEmployyes] = useState([]);

  const getEMployees = async () => {
    try {
      let res = await axios.get(
        "https://univ.mobi/api/user/getAllProfileEmployer"
      );
      if (res.status === 200) {
        setEmployyes(res?.data?.success);
        getAllcategory();
        getAllhome();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllUser, setAllUser] = useState([]);

  const getAllUse = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/user/getAllProfile");
      if (res.status === 200) {
        setAllUser(res?.data?.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getAllProfile
  console.log("an=,mmmmm===>", homeData, Employyes);

  useEffect(() => {
    loadUsers();
    byCategory();
    getAllcategory();
    getAllhome();
    getEMployees();
    getAllUse();
    getAllcategory1();
  }, []);

  const loadUsers = async () => {
    const config = {
      url: "/user/getAllJobs",
      method: "get",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);

      if (result.status === 200) {
        setUser(result?.data?.success);
        getAllcategory();
        byCategory();
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const responsive1 = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  };
  const responsive2 = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  };
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };

  const [job, setJob] = useState([]);

  const byCategory = async (category) => {
    const config = {
      url: "/user/getJobOfTheDay",
      method: "post",
      baseURL: "https://univ.mobi/api",
      headers: { "content-type": "application/json" },
      data: { category: category },
    };
    try {
      const result = await axios(config);

      if (result.status === 200) {
        setJob(result?.data?.success);
        getAllcategory();
      } else {
        alert("Something went worng");
        // console.log("errrr==========>-");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(job.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const applyJob = async (id) => {
    try {
      if (!check) {
        alert("Please login");
        history("/employers-login");
      } else  if (!check?.resume) {
        alert("Please upload your resume")
      }  else if (check.industry === "") {
        alert("Please update your profile to apply")
      }else if (check?.skillSet.length === 0 || check?.education.length === 0) {
        alert("Please update your profile to apply");
      } else {
        const config = {
          url: "/user/applyForJob",
          method: "post",
          baseURL: "https://univ.mobi/api",
          headers: { "content-type": "application/json" },
          data: { companyId: id, userId: check._id },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert(result.data.success);
          
        } else {
          alert("Something went worng");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
        
      }
    }
  };

  const unique = [...new Set(user?.map((item) => item?.category))];

  return (
    <>
      <Header />
      {/* <Whatsapp /> */}
      <div className="banner">
        <Carousel fade>
          {homeData?.map((item) => {
            return (
              <Carousel.Item>
                <a href={item?.link}>
                  <img
                    className="d-block w-100"
                    src={`https://univ.mobi/graph/${item?.image1}`}
                    alt="First slide"
                    style={{ height: "400px" }}
                  />{" "}
                </a>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      {/* ================ */}
      {!check ? (
        <div class="section-box mb-30">
          <div class="container-fuild">
            <div className="row">
              <div className="col-md-6">
                <div class="box-we-hiring" style={{ marginRight: "0px" }}>
                  <div className="text-0">
                    <img
                      src="/hr-removebg-preview.png"
                      alt=""
                      style={{ height: "144px", width: "100%" }}
                    />
                  </div>
                  <div class="text-1" style={{marginLeft:"20px"}}>
                    <span class="text-we-are">We are</span>
                    <span class="text-hiring">Hiring</span>
                    <div class="text-2" style={{marginLeft:"-20px"}}>
                      Let’s <span class="color-brand-1">Work</span> Together
                     &amp;   <span class="color-brand-1">
                        Explore Opportunities
                      </span>
                     
                    </div>
                   
                  </div>
                  <div class="text-3" style={{ marginTop: "100px",marginLeft:"20px" }}>
                    <div
                      class="btn btn-apply btn-apply-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#ModalApplyJobForm"
                      onClick={() => {
                        apply();
                      }}
                    >
                      Post job
                    </div>
                  </div>
                 
                  <div className="text-0" style={{ marginTop: "-45px" }}>
                    <img src="/Images/bg-right-hiring.9bd.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div class="box-we-hiring" style={{ marginLeft: "10px" }}>
                  <div className="text-0">
                    <img
                      src="/579-57-removebg-preview.png"
                      alt=""
                      style={{ height: "144px", width: "100%" }}
                    />
                  </div>
                  <div class="text-1" style={{marginLeft:"20px"}}>
                    <span class="text-we-are">We are</span>
                    <span class="text-hiring">Looking </span>
                    <div class="text-2" style={{marginLeft:"-20px"}}>
                      Find the <span class="color-brand-1">One</span> That’s
                      <span class="color-brand-1"> Right for
                      you</span>
                    </div>
                  
                  </div>

                  <div class="text-3" style={{ marginTop: "100px",marginLeft:"0px" }}>
                    <a href="/career-guidance">
                      <div
                        class="btn btn-apply btn-apply-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#ModalApplyJobForm"
                      >
                        Search job
                      </div>
                    </a>
                  </div>
                  <div className="text-0" style={{ marginTop: "-45px" }}>
                    <img src="/Images/bg-right-hiring.9bd.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* csm */}
      <div
        className="banner-hero hero-1"
        style={{
          backgroundImage: "url('/Images/bg-banner.04bbd282.svg')",
          height: "201px",
        }}
      >
        <div className="banner-inner">
          <div className="row">
            <div className="col-xl-8 col-lg-12">
              <div className="block-banner">
                <h1 class="heading-banner wow animate__animated animate__fadeInUp">
                  Univi India HR Team
                </h1>

                <div
                  class="banner-description mt-20 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                ></div>
                <div
                  class="banner-description mt-20 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  To be the best and most effective Service Provider of
                  Banks,Retailer market,Production,Hospitality BPO, ITES, Health
                  care, Trading and Sales & Marketing companies enabled services
                  in India at large.
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12 d-none d-xl-block col-md-6">
              <div className="banner-imgs">
                <div class="block-1 shape-1">
                  <a href="/about">
                    <button>
                      To Know More <RxDoubleArrowRight />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* pjforig */}

      <div
        className="banner-hero hero-1"
        style={{
          backgroundImage: "url('/Images/bg-banner.04bbd282.svg')",
          height: "211px",
        }}
      >
        <div className="banner-inner">
          <div className="row">
            <div className="col-xl-4 col-lg-12 d-none d-xl-block col-md-6">
              <div className="banner-imgs">
                <div class="block-1 shape-1">
                  <a href="/resume-writing">
                    <button>
                      Write Now
                      <RxDoubleArrowRight />
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-12">
              <div className="block-banner">
                <h1 class="heading-banner wow animate__animated animate__fadeInUp">
                  Boost your Career with our expert Resume writing
                </h1>

                <div
                  class="banner-description mt-20 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                ></div>
                <div className="jcd">
                  <div className="fkkd">
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: " 700",
                        color: "#2bb792",
                      }}
                    >
                      5000 + Resume Prepared
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============== */}
      <div className="section-box mt-80">
        <div className="container-fluid">
          <div className="text-center">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
              Browse by category
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Find the job that’s perfect for you. about 800+ new jobs everyday
            </p>
          </div>
          <div className="job-carousal mt-50">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              items={5}
              nav={false}
              autoplay={true}
              responsive={responsive}
            >
              {alldata?.map((item) => {
                return (
                  <div class="item">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // window.location.assign("/find-job/" + item?.category, { state: item });
                        history("/find-job1/" + item?.category, { state: item });
                        // window.location.assign("/find-job/" + item?.category, { state: item });
                      }}
                    >
                      <div class="item-logo">
                        {/* <div class="image-left">
                          <img
                            alt="jobBox"
                            src="/Images/content.svg"
                            style={{ width: "100%" }}
                          />
                        </div> */}
                        <div class="text-info-right">
                          <h4 style={{ "text-transform": "capitalize" }}>
                            {item?.category}
                          </h4>
                          <p class="font-xs" style={{ color: "white" }}>
                            {
                              user?.filter(
                                (data) => data?.category === item?.category
                              )?.length
                            }
                            <span> Jobs Available</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>

      {/* Be an early */}
      {/* <div className="section-box mb-30">
        <div className="container">
          <div className="job-type">
            <div className="text-center">
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                Be An Early Applicant
              </h2>
              <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Search and connect with the right candidates faster.
              </p>
            </div>
            <div className="job-type--30">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav
                autoplay={true}
                dots={false}
                responsive={responsive}
              >
                {user?.map((item) => {
                  return (
                    <div class="item">
                      <div className="wrap-type">
                        <a href={`/job-profile-details/${item?._id}`}>
                          <div class="jobCard_jobCard_features__wJid6">
                            <span class="">Premium</span>
                            <span>
                              {" "}
                              {moment(item?.updatedAt)
                                .startOf("hour")
                                .fromNow()}
                            </span>
                          </div>
                          <h2 className="name">
                            We are Hiring - {item?.jobProfile}
                          </h2>
                          <div class="jobCard_jobCard_cName__mYnow">
                            <h3 style={{ fontSize: "20px", fontWeigt: "700" }}>
                              {item?.companyName}
                            </h3>
                          </div>
                          <div class="jobCard_jobCardht_lists__fdnsc">
                            <div class=" jobCard_jobCard_lists_item__YxRkV jobCard_locationIcon__zrWt2">
                              {item?.city}
                              <div class="more_info ml-10">
                                <div
                                  class="more_info_text arrow-box down_arrow"
                                  style={{ bottom: "25px", left: "-5px" }}
                                ></div>
                              </div>
                            </div>
                            <div class=" jobCard_jobCard_lists_item__YxRkV jobCard_jobIcon__3FB1t">
                              {item?.minSalary}-{item?.maxSalary}{" "}
                              {item?.salaryType}
                            </div>
                          </div>
                          <ul class="jobCard_jobCard_jobDetail__jD82J">
                            <li>Job Type : &nbsp; </li>
                            <li>{item?.jobStatus}</li>
                          </ul>
                          <ul class="jobCard_jobCard_jobDetail__jD82J">
                            <li>Skills : &nbsp; </li>
                            <li>{item?.skill?.slice(0, 18)}...</li>
                          </ul>
                        </a>
                        <div class="jobCard_jobCard_ctas__8hE2e jobCard_ctas">
                          <div class="w-100 d-flex align-items-center justify-content-end">
                            <button
                              type="button"
                              class="btn-link apply-now pr-0"
                              onClick={() => {
                                applyJob(item?._id);
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div> */}

      {/* job by day */}
      <div className="section-box mb-30">
        <div className="container-fluid">
          <div className="job-day">
            <div className="text-center">
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                Jobs of the day
              </h2>
              <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Search and connect with the right candidates faster.
              </p>
            </div>
          </div>
          <div className="job-esestinal">
            <div className="wrappwr-00">
              <ul className="contact-place-90" style={{ overflow: "auto" }}>
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={10}
                  nav
                  items={5}
                  autoplay={true}
                  dots={false}
                  responsive={responsive1}
                >
                  {unique?.map((item) => {
                    return (
                      <li
                        className="nav-tabs-00"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          byCategory(item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </OwlCarousel>
              </ul>

              <div className="">
                <div>
                  <div className="mangament">
                    <div className="row">
                      {job
                        ?.filter((data) => data.isBlock === false)
                        .slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((items, index) => {
                          return (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                              <div class="card-grid-2 hover-up">
                                <div class="card-grid-2-image-left">
                                  <div
                                    class="image-box"
                                    style={{ width: "33%" }}
                                  >
                                    {/* <a href={`/job-profile-details/${items?._id}`}>
                                    <img
                                      src={`https://univ.mobi/company/${items?.profileLogo}`}
                                      alt="jobBox"
                                      style={{ width: "100%", height: "20px" }}
                                    />
                                  </a> */}
                                  </div>
                                </div>

                                <div
                                  class="right-info"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <a
                                    class="name-job"
                                    onClick={() => apply1(items?._id)}
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "800",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {items?.companyName.slice(0, 15)}
                                  </a>
                                </div>
                                <span
                                  class="location-small"
                                  style={{ marginLeft: "10px" }}
                                >
                                  {" "}
                                  <span style={{ paddingRight: "4px" }}>
                                    <CiLocationOn />
                                  </span>
                                  {items?.location}
                                </span>
                                <div class="card-block-info">
                                  <a
                                    onClick={() => apply1(items?._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h6>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => apply1(items?._id)}
                                      >
                                        {items?.jobProfile}
                                      </a>
                                    </h6>
                                    <div class="mt-3">
                                      <span class="card-briefcase">
                                        <span style={{ paddingRight: "4px" }}>
                                          <HiOutlineBriefcase />
                                        </span>
                                        {items?.typeofwork}
                                      </span>
                                      <span class="card-time">
                                        {" "}
                                        <span style={{ paddingRight: "8px" }}>
                                          <BsStopwatch />
                                        </span>
                                        {moment(items?.updatedAt).fromNow()}
                                        {/* <span> minutes ago</span> */}
                                      </span>
                                      <p>
                                        <HiOutlineBriefcase />{" "}
                                        <span>
                                        {items.experiencerequired === "Fresher" || items.experiencerequired === "Any" ? (
                      <span>{items.experiencerequired}</span>
                    ) : (
                      <span>
                        {items?.experience
                          ? items?.experience
                          : items.experiencerequired}</span>)}
                                        </span>
                                      </p>
                                    </div>
                                    {/* <p class="font-sm color-text-paragraph mt-3 mb-3">
                                    {items?.description.slice(0, 90)}...
                                  </p> */}
                                    <div class="mt-30 mb-3">
                                      {/* {items?.skill.split(',')} */}
                                      {/* <a class="btn btn-grey-small mr-5" href="#">
                                      {items?.skill.slice(0, 40)}
                                    </a> */}
                                    </div>
                                  </a>
                                  <div class="card-2-bottom mt-30">
                                    <div class="row">
                                      <div class="col-lg-9 col-9">
                                        <span class="card-text-price">
                                          {"₹ "}
                                          {items?.minSalary
                                            ? items?.minSalary
                                            : 0}
                                          -
                                          {items?.maxSalary
                                            ? items?.maxSalary
                                            : 0}{" "}
                                          {/* {items?.averageIncentive ? "+" : ""}{" "}
                                          {items?.averageIncentive}{" "} */}
                                          {items.period ? (
                                            <span class="text-muted">
                                              /{items.period}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {/*
                     <span class="text-muted">{items.salarytype}</span>) */}
                                        </span>
                                        <div class="col-lg-5 col-5 text-end">
                                        
                                            <div
                                              class="btn btn-apply-now"
                                              data-bs-toggle="modal"
                                              data-bs-target="#ModalApplyJobForm"
                                              onClick={() =>
                                                applyJob(items?._id)
                                              }
                                            >
                                              Apply now
                                            </div>
                                         
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Milliaons of jobs */}

      {/* <section class="section-box overflow-visible mt-100 mb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="box-image-job">
              

                <Card
                  style={{
                    width: "12rem",
                    position: "absolute",
                    left: "70%",
                    top: "49%",
                  }}
                >
                  <a href="https://play.google.com/">
                    {" "}
                    <Card.Img variant="top" src="/Images/playstores.png" />
                  </a>
                </Card>

                <Card
                  style={{
                    width: "12rem",
                    position: "absolute",
                    left: "70%",
                    top: "74%",
                  }}
                >
                  <a href="https://www.apple.com">
                    {" "}
                    <Card.Img variant="top" src="/Images/appstore.png" />
                  </a>
                </Card>

                <figure class="wow animate__animated animate__fadeIn">
                  {homeData?.image2 ? (
                    <img
                      alt="jobBox"
                      src={`https://univ.mobi/graph/${homeData?.image2}`}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <></>
                   
                  )}
                </figure>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="content-job-inner">
                <span class="color-text-mutted text-32">
                  Millions Of Jobs.{" "}
                </span>
                {homeData?.heading2 ? (
                  <h2 class="text-52 wow animate__animated animate__fadeInUp">
                    {homeData?.heading2}
                  </h2>
                ) : (
                  <h2 class="text-52 wow animate__animated animate__fadeInUp">
                    Find The One That’s <span class="color-brand-2">Right</span>{" "}
                    For You
                  </h2>
                )}
                {homeData?.text2 ? (
                  <div class="mt-40 pr-50 text-md-lh28 wow animate__animated animate__fadeInUp">
                    {homeData?.text2}.
                  </div>
                ) : (
                  <div class="mt-40 pr-50 text-md-lh28 wow animate__animated animate__fadeInUp">
                    Search all the open positions on the web. Get your own
                    personalized salary estimate. Read reviews on over 600,000
                    companies worldwide. The right job is out there.
                  </div>
                )}

                <div class="mt-40">
                  <div class="wow animate__animated animate__fadeInUp">
                    <a class="btn btn-default" href="/find-job/:jobs">
                      Search Jobs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* OUR CLIENT */}
      <div className="row">
              <div className="col-md-12">
              <OwlCarousel
                  className="owl-theme"
                  
                  margin={10}
                  nav
                  items={1}
                  autoplay={true}
                  dots={false}
                  responsive={responsive2}
                >
              {alldata1?.map((data, index) => {
                    return (  <div class="box-we-hiring" style={{ marginRight: "0px" }}>
                 
                 
                  
                    
                    <div class="text-2" style={{marginLeft:"-20px"}}>
                     <span class="color-brand-3">{data?.text.slice(0,200)}</span> 
                     
                  
                   
                  </div>
                  <div class="text-3" style={{ marginTop: "100px",marginLeft:"20px" }}>
                  <Link to="/readmore" state={{data:data}}><div
                      class="btn btn-apply btn-apply-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#ModalApplyJobForm"
                    
                    >
                      Read More
                    </div></Link>
                  </div>
                 
                  <div className="text-0" style={{ marginTop: "-45px" }}>
                    <img src="/Images/bg-right-hiring.9bd.svg" alt="" />
                  </div>
                </div>);
            })}
            </OwlCarousel>
              </div> 
             
            </div>
      <section class="section-box overflow-visible mt-50 mb-50">
        <div class="container-fluid">
          <div>
            <Client />
          </div>
        </div>
      </section>

      {/* uyiyy */}
      {/* kkkkkkkkkkkk */}
      {/* ABCDE*/}
      {/* <section
        class="section-box overflow-visible mt-50 mb-50"
        style={{
          backgroundColor: "#0a2a69",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="text-center">
                <h1 class="color-brand-2 job-000" style={{ color: "white" }}>
                  <span class="count">{Employyes?.length}</span>
                  <span style={{ color: "white" }}>+</span>
                </h1>
                <h5 style={{ fontWeight: "800", color: "white" }}>Clients</h5>
                <p
                  class="font-sm color-text-paragraph mt-10"
                  style={{ color: "white" }}
                >
                  We help our valued clients
                  <br class="d-none d-lg-block" />
                  to pick up right talent
                  <br class="d-none d-lg-block" />
                  to any business house.
                </p>
              </div>
            </div>
           
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="text-center">
                <h1 class="color-brand-2 job-000" style={{ color: "white" }}>
                  <span class="count">{AllUser?.length}</span>
                  <span>+</span>
                </h1>
                <h5 style={{ fontWeight: "800", color: "white" }}>Employees</h5>
                <p
                  class="font-sm color-text-paragraph mt-10"
                  style={{ color: "white" }}
                >
                  We always strive to make best
                  <br class="d-none d-lg-block" />
                  jobs available.
                  
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="text-center">
                <h1 class="color-brand-2 job-000">
                  <span class="count" style={{ color: "white" }}>
                    {user?.length}
                  </span>
                  <span style={{ color: "white" }}>+</span>
                </h1>
                <h5 style={{ fontWeight: "800", color: "white" }}>
                  Jobs Online
                </h5>
                <p
                  class="font-sm color-text-paragraph mt-10"
                  style={{ color: "white" }}
                >
                  Through our platform we enable candidates and
                  <br class="d-none d-lg-block" />
                  employers to share a common platform to
                  <br class="d-none d-lg-block" />
                  help each other.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Home1;
