import React, { useState, useEffect } from "react";
// import "../MajorProducts.css";
import Button from "react-bootstrap/Button";
import { TiDelete } from "react-icons/ti";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import axios from "axios";

const steps = ["Basic Details", "Candidate Details", "Interviewer Details"];

function Addjob1() {
  let check = JSON.parse(sessionStorage.getItem("admin"));
  const [companyname, setCompanyname] = useState("");
  const [openings, setopenings] = useState("");
  const [address, setaddress] = useState("");
  const [jobprofile, setJobprofile] = useState("");
  const [email, setEmail] = useState("");
  const [skill, setSkill] = useState("");
  const [period, setperiod] = useState("");

  const [fee, setfee] = useState("");
  const [minsalary, setMinsalary] = useState();
  const [maxsalary, setMaxsalary] = useState();
  const [location, setLocation] = useState("");
  const [category, setcategory] = useState("");
  const [english, setenglish] = useState("");
  const [typeofjob, settypeofjob] = React.useState("");
  const [typeofeducation, settypeofeducation] = React.useState("");
  const [night, setnight] = React.useState("");
  const [typeofqualification, settypeofqualification] = useState("");
  const [typeofwork, settypeofwork] = React.useState("");
  const [interview, setinterview] = useState("");
  const [salarytype, setSalarytype] = useState("");
  const [reason, setreason] = useState("");
  const [experience, setExperience] = useState("");
  const [experiencerequired, setexperiencerequired] = useState("");
  const [gendertype, setgendertype] = useState("");
  const [description, setdescription] = useState("");
  const [benefits, setbenefits] = useState("");
  const [interviewername, setinterviewername] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [time, settime] = useState("");
  const [averageIncentive, setaverageIncentive] = useState("");

  const formdata = new FormData();
console.log(night,"night");
  formdata.set("companyName", companyname);
  formdata.set("openings", openings);
  formdata.set("address", address);
  formdata.set("night", night);
  formdata.set("adminId", check._id);

  formdata.set("fee", fee);
  formdata.set("english", english);
  formdata.set("interview", interview);
  formdata.set("salarytype", salarytype);
  formdata.set("typeofjob", typeofjob);
  formdata.set("typeofwork", typeofwork);
  formdata.set("typeofeducation", typeofeducation);
  formdata.set("reason", reason);
  formdata.set("experiencerequired", experiencerequired);
  formdata.set("gendertype", gendertype);
  formdata.set("jobProfile", jobprofile);
  formdata.set("period", period);
  formdata.set("typeofqualification", typeofqualification);
  formdata.set("email", email);
  formdata.set("skill", skill);
  formdata.set("benefits", benefits);
  formdata.set("category", category);

  formdata.set("minSalary", minsalary ? minsalary : "");
  formdata.set("maxSalary", maxsalary ? maxsalary : "");
  formdata.set("location", location);

  formdata.set("experience", experience);
  formdata.set("description", description);

  formdata.set("interviewername", interviewername);
  formdata.set("whatsapp", whatsapp);
  formdata.set("time", time);
  formdata.set("averageIncentive", averageIncentive);

  const addjob = async () => {
    if (!interviewername) {
      alert("Enter the Interviewer Name");
    } else if (!whatsapp) {
      alert("Please Enter the Whatsapp Number");
    } else if (!email) {
      alert("Please Enter the email address");
    } else if (!time) {
      alert("Please Enter the available timings");
    } else if (!interview) {
      alert("Please Enter the Type of Interview");
    } else if (interview === "Offline Interview" && !address) {
      alert("Please Enter the interview address");
    } else {
      try {
        const config = {
          url: "/registerCompany",
          method: "post",
          baseURL: "https://univ.mobi/api/user",
          headers: { "content-type": "multipart/data" },
          data: formdata,
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert(res.data.success);
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
              newSkipped = new Set(newSkipped.values());
              newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
    }
  };

  const [alldata, setAlldata] = useState([]);
  useEffect(() => {
    getAllcategory();
  }, []);
  const getAllcategory = async () => {
    try {
      let res = await axios.get("https://univ.mobi/api/admin/getAllCategory");
      if (res.status === 200) {
        setAlldata(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const handleChange10 = (e, editor) => {
    const data = editor.getData();
    setdescription(data);
  };
  const handleChange2 = (event) => {
    settypeofwork(event.target.value);
  };
  const handleChange3 = (event) => {
    setSalarytype(event.target.value);
  };
  const handleChange4 = (event) => {
    settypeofeducation(event.target.value);
  };
  const handleChange5 = (event) => {
    setgendertype(event.target.value);
  };
  const handleChange6 = (event) => {
    setexperiencerequired(event.target.value);
  };
  const handleChange7 = (event) => {
    setenglish(event.target.value);
  };
  const handleChange8 = (event) => {
    setinterview(event.target.value);
  };
  const handleChange9 = (event) => {
    setnight(event.target.value);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleChange = (event) => {
    settypeofjob(event.target.value);
  };
  const [experiencedjob, setexperiencedjob] = React.useState("");

  const handleChange1 = (event) => {
    setexperiencedjob(event.target.value);
  };

  // lsfp
  const [fixed, setfixed] = useState(false);
  const [fixedIncentive, setfixedIncentive] = useState("");
  const [incentive, setincentive] = useState("");
  const [yesdepo, setyesdepo] = useState(false);
  const [nodepo, setnodepo] = useState(false);
  const [experienced, setexperienced] = useState("");
  const [offline, setoffline] = useState("");
  const [online, setonline] = useState("true");

  const handleNext = () => {
    if (!companyname) {
      alert("Please Enter Company Name ");
    } else if (!jobprofile) {
      alert("Please Enter Job Title ");
    } else if (!typeofjob) {
      alert("Please Select Type of Job ");
    } else if (!openings) {
      alert("Please Enter No of Openings ");
    } else if (!typeofwork) {
      alert("Please Enter Type of Work ");
    } else if (!location) {
      alert("Please Enter location ");
    } else if (!salarytype) {
      alert("Please Select Salary Type ");
    } else if (
      salarytype === "Fixed only" && (
      !minsalary ||
      !maxsalary ||
      !period) 
    ) {
      alert("Please Enter the salary and salary type");
    } else if (salarytype === "Incentive only" && !averageIncentive) {
      alert("Please Enter the Incentive");
    } else if (
      salarytype === "Fixed + Incentive" &&(
      !minsalary ||
      !maxsalary ||
      !period ||
      !averageIncentive)
    ) {
      alert("Please Enter the salary and salary type");
    } else if (!benefits) {
      alert("Please Enter Benefit ");
    } else if (yesdepo === "false" || nodepo === "false") {
      alert("Please Select Fee or Deposit ");
    } else if (yesdepo === true && (!fee || !reason) ) {
      alert("Please Enter Fee & Deposit ");
    } else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleNext1 = () => {
    if (!typeofeducation) {
      alert("Enter the type of education");
    } else if (!typeofqualification) {
      alert("Please enter qualification  ");
    } else if (!gendertype) {
      alert("Please Select the gender  ");
    } else if (!experiencerequired) {
      alert("Please Select the Experience");
    } else if (experiencerequired === "Experience" && !experience) {
      alert("Please Enter the experience");
    } else if (!english) {
      alert("Please Select the level of English");
    } else if (!skill) {
      alert("Please Enter Skills");
    } else if (!description) {
      alert("Please Enter the Description ");
    } else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.location.assign("/adminunverified");
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9 "
          style={{ overflow: "scroll", height: "550px" }}
        >
          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              borderRadius: "8px",
              marginBottom: "10px",
              boxShadow: " rgb(0 0 0 / 16%) 0px 1px 4px",
              border: " 1px solid #00000047",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Post Job</b>
                </h1>
              </div>
            </div>
            <br />

            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    Job Successfully Posted
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset} className="mt-1 mb-2">
                      Ok
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep === 0 ? (
                    <div className="stp_hero">
                      <div className="tex_heo mt-2">
                        <h4>Job Details</h4>
                        <div className="ing_jk">
                          <Box
                            component="form"
                            // sx={{
                            //   '& > :not(style)': { m: 1, width: '25ch' },
                            // }}
                            // noValidate
                            autoComplete="off"
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    label="Company Name"
                                    variant="outlined"
                                    value={companyname}
                                    onChange={(e) =>
                                      setCompanyname(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    label="Job-title*"
                                    variant="outlined"
                                    value={jobprofile}
                                    onChange={(e) =>
                                      setJobprofile(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mt-2">
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Type of jobs
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={typeofjob}
                                      label="Type of jobsge"
                                      onChange={handleChange}
                                    >
                                      <MenuItem value="Part time">
                                        Part time
                                      </MenuItem>
                                      <MenuItem value="Full time">
                                        Full time
                                      </MenuItem>
                                      <MenuItem value="Both">Both</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    label="No of openings*"
                                    variant="outlined"
                                    value={openings}
                                    onChange={(e) =>
                                      setopenings(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group mt-2">
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Category
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={category}
                                        label="Type of jobsge"
                                        onChange={(e) => {
                                          setcategory(e.target.value);
                                        }}
                                      >
                                        {alldata?.map((items) => {
                                          return (
                                            <MenuItem value={items?.category}>
                                              {items?.category}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-group mt-2">
                                    <input
                                      type="checkbox"
                                      value={night}
                                      onChange={(e) => setnight(e.target.value)}
                                    />
                                    <label
                                      style={{
                                        paddingLeft: "10px",
                                        fontSize: "17px",
                                      }}
                                    >
                                      This is a night shift job
                                    </label>
                                  </div>
                                </div> */}
                                  <div className="loction_job">
                          <h4>Shift Type :</h4>
                          <div className="radio-fileds-iocn">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={night}
                              onChange={handleChange9}
                            >
                              <FormControlLabel
                                value="Night Shift"
                                control={<Radio />}
                                label="Night Shift"
                              />
                              <FormControlLabel
                                value="Rotational Shift"
                                control={<Radio />}
                                label="Rotational Shift"
                              />
                              <FormControlLabel
                                value="General Shift"
                                control={<Radio />}
                                label="General Shift"
                              />
                              
                            </RadioGroup>
                          </div>
                        </div>
                              </div>
                            </div>
                          </Box>
                        </div>
                        {/* Work type */}
                        <div className="loction_job">
                          <h4>Work Type :</h4>
                          <div className="radio-fileds-iocn">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={typeofwork}
                              onChange={handleChange2}
                            >
                              <FormControlLabel
                                value="Work from home"
                                control={<Radio />}
                                label="Work from home"
                              />
                              <FormControlLabel
                                value="Work from office"
                                control={<Radio />}
                                label="Work from office"
                              />
                              <FormControlLabel
                                value="Field jobs"
                                control={<Radio />}
                                label="Field jobs"
                              />
                              <FormControlLabel
                                value="Freelancer"
                                control={<Radio />}
                                label="Freelancer"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        {/* Work Location */}
                        <div className="work_type pt-2">
                          <h4>Work location :</h4>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <TextField
                                id="outlined-basic"
                                label="Enter full address*"
                                variant="outlined"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Salary type */}
                        <div className="salary_heo pt-2">
                          <h4>Salary Type :</h4>
                          <div className="salar_hero">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={salarytype}
                              onChange={handleChange3}
                            >
                              <FormControlLabel
                                value="Fixed only"
                                control={<Radio />}
                                label="Fixed only"
                                onClick={() => {
                                  setfixed(true);
                                  setfixedIncentive(false);
                                  setincentive(false);
                                }}
                              />
                              <FormControlLabel
                                value="Incentive only"
                                control={<Radio />}
                                label="Incentive only"
                                onClick={() => {
                                  setfixed(false);
                                  setfixedIncentive(true);
                                  setincentive(false);
                                }}
                              />
                              <FormControlLabel
                                value="Fixed + Incentive"
                                control={<Radio />}
                                label="Fixed + Incentive "
                                onClick={() => {
                                  setfixed(false);
                                  setfixedIncentive(false);
                                  setincentive(true);
                                }}
                              />
                            </RadioGroup>
                          </div>
                          {/* fkwmmsdddddddddddddddddddddrw */}
                          <div className="">
                            {fixed ? (
                              <>
                                <div className="fi_he">
                                  <h5>Fixed Salary / Month</h5>
                                  <div
                                    className="min_max"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <TextField
                                        type="number"
                                        id="outlined-basic"
                                        label="minimum*"
                                        variant="outlined"
                                        value={minsalary}
                                        onChange={(e) =>
                                          setMinsalary(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      to
                                    </div>
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <TextField
                                        type="number"
                                        id="outlined-basic"
                                        label="maximum*"
                                        variant="outlined"
                                        value={maxsalary}
                                        onChange={(e) =>
                                          setMaxsalary(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <TextField
                                        id="outlined-basic"
                                        label="Salary Type*"
                                        variant="outlined"
                                        value={period}
                                        placeholder="Eg: LPA / Month"
                                        onChange={(e) =>
                                          setperiod(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {fixedIncentive ? (
                                  <>
                                    <div className="fi_he">
                                      <h5>Average Incentive / Month</h5>
                                      <div
                                        className="form-group mt-2 joker-0"
                                        style={{ margin: "0px 10px" }}
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          label="Averge Incentive / Month*"
                                          variant="outlined"
                                          value={averageIncentive}
                                          onChange={(e) =>
                                            setaverageIncentive(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {incentive ? (
                                      <>
                                        <div className="fi_he">
                                          <h5>
                                            Fixed Salary + Average Incentive /
                                            Month
                                          </h5>
                                          <div
                                            className="min_max"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              <TextField
                                                id="outlined-basic"
                                                label="minimum*"
                                                variant="outlined"
                                                value={minsalary}
                                                onChange={(e) =>
                                                  setMinsalary(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              to
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              <TextField
                                                id="outlined-basic"
                                                label="maximum*"
                                                variant="outlined"
                                                value={maxsalary}
                                                onChange={(e) =>
                                                  setMaxsalary(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              +
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              <TextField
                                                id="outlined-basic"
                                                label="Average Incentive*"
                                                variant="outlined"
                                                value={averageIncentive}
                                                onChange={(e) =>
                                                  setaverageIncentive(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div
                                              className="form-group mt-2"
                                              style={{ margin: "0px 10px" }}
                                            >
                                              <TextField
                                                id="outlined-basic"
                                                label="Salary Type*"
                                                variant="outlined"
                                                value={period}
                                                placeholder="Eg: LPA / Month"
                                                onChange={(e) =>
                                                  setperiod(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>

                          {/* [owjfkpeeegeiprfdkm;,cv .] */}
                        </div>

                        {/* Add Others Benifits */}
                        <div className="add_j">
                          <div className="add-jkl mt-2">
                            <h4>Add Other Benefit</h4>
                            <div className="d-flex align-items-center">
                              <div
                                className="form-group mt-2"
                                style={{ margin: "0px 10px" }}
                              >
                                <TextField
                                  id="outlined-basic"
                                  label="Add benefit*"
                                  variant="outlined"
                                  value={benefits}
                                  onChange={(e) => setbenefits(e.target.value)}
                                />
                              </div>
                              {/* <div
                                  className="form-group mt-2"
                                  style={{ margin: "0px 10px" }}
                                >
                                  <Button onClick={addbenefits}>Add+</Button>
                                </div> */}
                            </div>
                          </div>
                        </div>

                        {/* ++++++++++++++++++++ */}
                        <div className="deposite pt-2">
                          <div>
                            <h6>
                              If there any joining fee or deposit required from
                              the candidate ?
                            </h6>
                          </div>
                          <div className="deposite_hero ">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                                onClick={() => {
                                  setyesdepo(true);
                                  setnodepo(false);
                                }}
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                                onClick={() => {
                                  setyesdepo(false);
                                  setnodepo(true);
                                }}
                              />
                            </RadioGroup>

                            <div className="yes_hero">
                              {yesdepo ? (
                                <>
                                  <div className="d-flex aling-items-center">
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <TextField
                                        id="outlined-basic"
                                        label="Fee Amount*"
                                        variant="outlined"
                                        value={fee}
                                        onChange={(e) => setfee(e.target.value)}
                                      />
                                    </div>
                                    <div
                                      className="form-group mt-2"
                                      style={{ margin: "0px 10px" }}
                                    >
                                      <TextField
                                        id="outlined-basic"
                                        label="Enter the reason*"
                                        variant="outlined"
                                        value={reason}
                                        onChange={(e) =>
                                          setreason(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <Box sx={{ flex: "1 1 auto" }} />
                                {/* {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )} */}

                                <Button onClick={handleNext} className="mb-2">
                                  Continue
                                </Button>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : // step IInd step
                  activeStep === 1 ? (
                    <div className="candidate-Hero">
                      <div className="candidate-details pt-2">
                        <h4>Candidate Requirements</h4>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Education
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={typeofeducation}
                                  label="Type of jobs"
                                  onChange={handleChange4}
                                >
                                  <MenuItem value="10th or Below 10th">
                                    10th or Below 10th
                                  </MenuItem>
                                  <MenuItem value="12th pass">
                                    12th pass
                                  </MenuItem>
                                  <MenuItem value="Degree/Diploma">Degree/Diploma</MenuItem>
                                  <MenuItem value="Graduate">Graduate</MenuItem>
                                  <MenuItem value="Post Graduate">
                                    Post Graduate
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        {/* Gender */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <FormControl fullWidth>
                                <TextField
                                  id="outlined-basic"
                                  label="Qualification"
                                  variant="outlined"
                                  value={typeofqualification}
                                  onChange={(e) =>
                                    settypeofqualification(e.target.value)
                                  }
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="gendr_hero">
                          <div className="hero_hj">
                            <FormControl>
                              <h4>Gender</h4>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={gendertype}
                                onChange={handleChange5}
                              >
                                <FormControlLabel
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="Both"
                                  control={<Radio />}
                                  label="Both"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>

                        {/* Experience */}
                        <div className="gendr_hero">
                          <div className="hero_hj">
                            <FormControl>
                              <h4>Experience Required</h4>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={experiencerequired}
                                onChange={handleChange6}
                              >
                                <FormControlLabel
                                  value="Fresher"
                                  control={<Radio />}
                                  label="Fresher Only"
                                  onClick={()=>setexperienced(false)}
                                />
                                <FormControlLabel
                                  value="Experience"
                                  control={<Radio />}
                                  label="Experience"
                                  onClick={() => {
                                    setexperienced(!experienced);
                                  }}
                                />
                                <FormControlLabel
                                  value="Any"
                                  control={<Radio />}
                                  label="Any"
                                  onClick={()=>setexperienced(false)}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {experienced ? (
                                <>
                                  <div className="form-group mt-2">
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Experience
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={experience}
                                        label="Type of jobs"
                                        onChange={(e) =>
                                          setExperience(e.target.value)
                                        }
                                      >
                                        <MenuItem value="0-1 year">
                                          0-1 year
                                        </MenuItem>
                                        <MenuItem value="1-3 year">
                                          1-3 year
                                        </MenuItem>
                                        <MenuItem value="3-7 year">
                                          3-7 year
                                        </MenuItem>
                                        <MenuItem value="7 year Above">
                                          7 year Above
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* English Level  */}
                        <div className="gendr_hero">
                          <div className="hero_hj">
                            <FormControl>
                              <h4>English Level Preference</h4>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={english}
                                onChange={handleChange7}
                              >
                                <FormControlLabel
                                  value="NO English"
                                  control={<Radio />}
                                  label="No English"
                                />
                                <FormControlLabel
                                  value="Basic English"
                                  control={<Radio />}
                                  label="Basic English "
                                />
                                <FormControlLabel
                                  value="Intermediate English"
                                  control={<Radio />}
                                  label="Intermediate English"
                                />
                                <FormControlLabel
                                  value="Advance English"
                                  control={<Radio />}
                                  label="Advance English"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>

                        {/* Skill required */}
                        <div className="add_j">
                          <div className="add-jkl mt-2">
                            <h4>Skill required for this job</h4>
                            <div className="d-flex align-items-center">
                              <div
                                className="form-group mt-2"
                                style={{ margin: "0px 10px" }}
                              >
                                <TextField
                                  id="outlined-basic"
                                  label="Add skill *"
                                  variant="outlined"
                                  value={skill}
                                  onChange={(e) => setSkill(e.target.value)}
                                />
                              </div>
                              {/* <div
                                  className="form-group mt-2"
                                  style={{ margin: "0px 10px" }}
                                >
                                  <Button onClick={addskill}>Add+</Button>
                                </div> */}
                            </div>
                          </div>
                        </div>
                        {/* jobdis */}
                        <div className="jo_dis pt-2">
                          <div className="">
                            <h4>Job Description</h4>
                            <div className="mb-2">
                              Describe the responsbilities of this job and Other
                              specific requirements here
                            </div>
                          </div>
                          <div className="mult-additional">
                            {/* <TextField
                                id="outlined-multiline-static"
                                label="Job Description "
                                multiline
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                                rows={4}
                                defaultValue="Job Description"
                              /> */}
                            <CKEditor
                              editor={ClassicEditor}
                              data={description}
                              onChange={handleChange10}
                            />
                          </div>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Button
                              className="mb-2"
                              color="inherit"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            {/* {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )} */}

                            <Button onClick={handleNext1} className="mb-2">
                              Continue
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // 3rd step
                    <div className="init_0">
                      <div className="interViewer_0 pt-2">
                        <h4>Interviewer Details</h4>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <TextField
                                id="outlined-basic"
                                label="Interviewer Name"
                                variant="outlined"
                                value={interviewername}
                                onChange={(e) =>
                                  setinterviewername(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <TextField
                                id="outlined-basic"
                                label="Whatsapp No"
                                variant="outlined"
                                value={whatsapp}
                                onChange={(e) => setwhatsapp(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <TextField
                                id="outlined-basic"
                                label="Email Id"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mt-2">
                              <TextField
                                id="outlined-textarea"
                                label="Call us Eg : 10am to 6pm"
                                variant="outlined"
                                placeholder="Eg : 10am to 6pm"
                                multiline
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="interViewer_0 pt-2">
                          <h4>Type of Interview</h4>
                          <div className="hero_hj">
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={interview}
                                onChange={handleChange8}
                              >
                                <FormControlLabel
                                  value="offline"
                                  control={<Radio />}
                                  label="Offline Interview"
                                  onClick={() => {
                                    setoffline(true);
                                    setonline(false);
                                  }}
                                />
                                <FormControlLabel
                                  value="online"
                                  control={<Radio />}
                                  label="Telephonic / Online Interview"
                                  onClick={() => {
                                    setoffline(false);
                                    setonline(true);
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {offline ? (
                            <>
                              <div className="mult-additional_0">
                                {/* <h4>Interviewer Address </h4> */}
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Interviewer Address "
                                  multiline
                                  rows={4}
                                  value={address}
                                  onChange={(e) => setaddress(e.target.value)}
                                  defaultValue="Enter Complete Address"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Button
                              className="mb-2"
                              color="inherit"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            {/* {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )} */}

                            <Button onClick={addjob} className="mb-2">
                              Finish
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* // ) : (
                  //   <input placeholder="test4"></input>
                  // ) */}
                  {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        className="mb-2"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                    

                      {activeStep === 0 && activeStep === 1 ? (
                        <Button onClick={handleNext} className="mb-2">
                          "Continue"
                        </Button>
                      ) : (
                        ""
                      )}
                      {!activeStep === 0 && !activeStep === 1 ? (
                        <Button onClick={addjob} className="mb-2">
                          Finish
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box> */}
                </React.Fragment>
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default Addjob1;
